import {createWebHistory, createRouter} from "vue-router";
import FirstPage from "../components/Auth/FirstPage";
import CSVLoader from "../components/NeoLoader/CSVLoader";
import Login from '../components/Auth/Login.vue';
import Register from '../components/Auth/Register.vue';

const routes = [{
    path: '/',
    name: 'FirstPage',
    component: FirstPage
},
    {
        path: "/game",
        name: "StartGame",
        component: CSVLoader,
    },
    {
        path: "/tribune",
        name: "Tribune",
        component: () => import('../components/Tribuna/Tribune.vue')
    },
    {
        path: "/session",
        name: "Session",
        component: () => import('../components/MainComponent.vue')
    },
    {
        path: "/newCase",
        name: "newCase",
        component: () => import('../components/Cases/CaseConstructor.vue')
    },
    {
        path: "/editCase/:caseId",
        name: "editCase",
        component: () => import('../components/Cases/CaseConstructor.vue')
    },
    {
        path: "/duplicateCase/:caseId",
        name: "duplicateCase",
        component: () => import('../components/Cases/CaseConstructor.vue')
    },
    {
        path: "/cases",
        name: "Cases",
        component: () => import('../components/Cases/Cases.vue')
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register
    },
    {
        path: '/profile',
        name: 'profile',
        // lazy-loaded
        component: () => import('../components/Profile/Profile.vue')
    },
    {
        path: '/how-to-use',
        name: 'how-to-use',
        // lazy-loaded
        component: () => import('../components/Help/HowToUse.vue')
    },
    {
        path: '/task',
        name: 'task',
        // lazy-loaded
        component: () => import('../components/Help/Instruction.vue')
    },
    {
        path: '/sessions/:id',
        name: 'sessions',
        component: () => import('../components/Profile/Sessions.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;