import {createI18n} from "vue-i18n";
import { ru } from './langs/ru'
import { en } from './langs/en'
import {store} from "@/store";
import libLocaleRu from 'element-plus/lib/locale/lang/ru'
import libLocaleEn from 'element-plus/lib/locale/lang/en'

let defaultLocale = (navigator.language === 'ru-RU' || navigator.language === 'ru') ? 'ru' : 'en';
let locale = store.state.auth.user?.lang || defaultLocale;

export const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: locale,
  messages: {
    "ru": ru,
    "en": en
  },
});


export const availableLanguages = [
  {
    label: "RU",
    value: "ru",
  },
  {
    label: "ENG",
    value: "en",
  }
];

export const ElementUILocale = (locale === 'ru') ? libLocaleRu : libLocaleEn;


