import CsvService from '../services/csv.service'
import UserService from '../services/user.service'
import {store} from "../store";

export default class ProfileController {
    constructor() {
    }

    getCountries(data) {
        return CsvService.getCountriesISO(data)
    }

    getUserStore() {
        return store.getters['auth/getUser']
    }

    getAdminStore() {
        return store.getters['auth/getAdmin']
    }

    setAdminStore() {
        store.commit('auth/setAdmin')
    }

    setUserStore(user) {
        store.commit('auth/updateInfo', user)
    }

    updateInfo(tag, data, userId) {
        return UserService.updateUserInfo(tag, data, userId)
    }

    editUserInfo(userId, data) {
        return UserService.editUserInfo(userId, data)
    }
}