import config from "../../config";
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = config.API + '/cash';

class CashService {

    putCashToStore(session) {
        return axios.post(API_URL + '/getCash', { sessionId: session._id, ontologyId: session.ontologyId }, { headers: authHeader() })
    }

    updateCash(cash) {
        return axios.post(API_URL + '/updateCash', cash, { headers: authHeader() })
    }

}

export default new CashService();