<template>
    <el-table
            @expand-change="expandChange"
            :data="files"
            style="width: 100%">
        <el-table-column type="expand">
            <template #default="props">
                <previewFile v-if="props.row.expand" :file="props.row.data"/>
            </template>
        </el-table-column>
        <el-table-column
                prop="filename"
                :label="$t('Имя файла')">
        </el-table-column>
        <el-table-column
                prop="type"
                label="Тип"
                width="200">
            <template #default="props">
                <el-radio-group v-model="props.row.type">
                    <el-radio-button label="node">{{ $t('Узел') }}</el-radio-button>
                    <el-radio-button label="relation">{{ $t('Связь') }}</el-radio-button>
                </el-radio-group>
            </template>
        </el-table-column>
        <el-table-column
                prop="label"
                :label="$t('Название элемента')">
            <template #default="props">
                <el-input v-model="props.row.label"/>
            </template>
        </el-table-column>
        <el-table-column
                prop="neoLabel"
                :label="$t('Название элемента в базе')">
            <template #default="props">
                <el-input v-model="props.row.neoLabel"/>
            </template>
        </el-table-column>
        <el-table-column
                prop="color"
                :label="$t('Цвет')">
            <template #default="props">
                <el-color-picker v-if="props.row.type === 'node'" v-model="props.row.color"></el-color-picker>
            </template>
        </el-table-column>
        <el-table-column
                fixed="right"
                label=""
                width="80">
            <template #default="props">
                <el-button @click="removeFile(props.$index)" type="danger" size="small">
                    <i class="fas fa-trash fa-lg"></i>
                </el-button>
            </template>
        </el-table-column>
        <el-table-column>
            <template #default="props">
                <vue3-icon-picker v-if="showicon && props.row.type === 'node'" v-model="props.row.icon" @change="changeIcon()"/>
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
    // import VueIconPicker from 'vue-icon-picker';
    // import VueFontAwesomePicker from "vfa-picker";
    import previewFile from "./previewFile";
    import Vue3IconPicker from "./Vue3IconPicker";

    export default {
        from: "FileList",
        props: ['files'],
        components: {
            previewFile,
            Vue3IconPicker
        },
        data() {
            return {
                show: true,
                showicon: true
            }
        },
        methods: {
            getName(){



            },
            expandChange(row) {
                row.expand = !row.expand
            },
            removeFile(index) {
                this.$emit('remove-file', index)
            },
            changeIcon() {
                const vm = this
                this.showicon = false
                setTimeout(function () {
                    vm.showicon = true
                }, 50)
            }

        }
    }
</script>

<style scoped>
    li {
        /*display: table-cell;*/
        text-align: center;
        border: 1px solid #ccc;
        display: flex;
        /*  display:inline-block;*/
        justify-content: space-around;
        padding: .5rem 2rem;
        margin-bottom: 1rem;
        /*float: contour;*/
    }

    span.file_name {
        width: 40%;
        text-align: left;
    }

    span {
        width: 15%;
    }


</style>