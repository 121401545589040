import axios from 'axios';
import config from "../../config";
import authHeader from "./auth-header";

const API_URL = config.API+'/user/';

class UserService {
    getAllUsers(params) {
        return axios.get(API_URL + 'getAllUsers', { params, headers: authHeader() });
    }
    deleteAllFilesFromUserNameFolder(name){
        return axios.post(API_URL + 'UserFileDelete', {'name':name}, { headers: authHeader() });
    }
    updateUserInfo(tag, data, userId) {
        return axios.post(API_URL + 'update/' + userId, {'tag': tag, 'data':data}, { headers: authHeader() })
    }

    editUserInfo(userId, data) {
        return axios.put(`${API_URL}${userId}`, data, { headers: authHeader() })
    }
}

export default new UserService();