import axios from 'axios';
import config from "../../config";
import authHeader from "./auth-header";

const API_URL = config.API+'/csv';

class CsvService {
    getCountriesISO() {
        return axios.get(API_URL + '/countriesISO',
        { headers: authHeader() });
    }
}

export default new CsvService();