<template>
    <div>
        <div class="col-md-12">
        <div class="card card-container">
            <img
                    id="profile-img"
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    class="profile-img-card"
            />
              <el-form :model="user" :rules="rules" ref="form" @submit.prevent="handleLogin">
                <el-form-item :label="$t('Имя')" prop="username">
                    <el-input v-model="user.username"
                              id="username"
                              type="text"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Пароль')" prop="password">
                    <el-input v-model="user.password"
                              id="password"
                              type="password"></el-input>
                </el-form-item>
                    <button class="btn btn-primary btn-block"
                            :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>{{ $t('Вход') }}</span>
                    </button>
            </el-form>
        </div>
    </div>
        </div>
</template>
<script>
    import User from '../../models/user';

    export default {
        name: 'Login',

        data() {
            return {
                user: new User('', ''),
                loading: false,
                message: '',
                rules: {
                    username: [
                        {required: true, message: this.$t('Введите имя'), trigger: 'blur'},
                        {min: 3, max: 5000, message: this.$t('Минимально 3 символа.'), trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: this.$t('Введите пароль'), trigger: 'change'},
                        {min: 6, max: 5000, message: this.$t('Минимально 6 символов.'), trigger: 'blur'}
                    ]
                }
            };
        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        created() {
            if (this.loggedIn) {
                this.$router.push('/profile');
            }
        },
        methods: {
            handleLogin() {
                this.loading = true;
                const isValid = true
                if (!isValid) {
                    this.loading = false;
                    return;
                }
                if (this.user.username && this.user.password) {

                    this.$store.dispatch('auth/login', this.user).then(
                        () => {
                          this.$emit('login')
                          window.location.href = '/profile';
                        },
                        error => {
                            this.loading = false;
                            this.message =
                                (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                    this.$emit('update:adminStatus', this.user.role)
                }
            },
            handleLogin_new() {
                this.loading = true;
                if (this.user.username && this.user.password) {
                    this.$store.dispatch('auth/login', this.user).then(
                        () => {
                            this.$emit('login')
                            window.location.href = '/profile';
                        },
                        error => {
                            this.loading = false;
                            this.message =
                                (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            }
        }
    };
</script>

<style scoped>
    label {
        display: block;
        margin-top: 10px;
    }

    .card-container.card {
        max-width: 350px !important;
        padding: 40px 40px;
    }

    .card {
        background-color: #f7f7f7;
        padding: 20px 25px 30px;
        margin: 0 auto 25px;
        margin-top: 50px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }

    .profile-img-card {
        width: 96px;
        height: 96px;
        margin: 0 auto 10px;
        display: block;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
    }
</style>
