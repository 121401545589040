export  default class Ontology {
    nodes;
    links;
    ruleList;
    id;
    name;
    username;

    constructor(nodes, links, id_, name_, username_) {
        this.nodes = this.createNodes(nodes);
        this.links = this.createLink(links);
        this.ruleList = this.createRules(links);
        this.id = id_;
        this.name = name_;
        this.username = username_
    }



    createNodes(nodes){
        console.log('NODES', nodes)
        let nodesNew = {};
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i]
            const neoLabel = node.neoLabel;
            console.log("neoLabel", neoLabel)
            let short = neoLabel.split()[0].toLowerCase()[0];
            let k = 1;

            while (k < neoLabel.length) {
                for (let key in nodesNew) {
                    if (nodesNew[key].short === short || short === 'r') {
                        short += neoLabel[k].toLowerCase();
                    }
                }
                k++;
            }
            if (short === 'r') short = 'rr'
            let properties = {}
            for (let j = 0; j < nodes[i].attributes.length; j++) {
                const attr = nodes[i].attributes[j]
                let name = attr.name
                if (name.length > 0) {
                    let type = 'String'
                    if (attr.name.split(':').length > 1) {
                        name = attr.name.split(':')[0]
                        if (attr.name.split(':')[1] === 'int') type = 'Number'
                        if (attr.name.split(':')[1] === 'double') type = 'Number'
                        if (attr.name.split(':')[1] === 'boolean') type = 'Boolean'
                        if (attr.name.split(':')[1] === 'date') type = 'Date'
                    }
                    properties[name] = {
                        type: type,
                        isList: false
                    }
                }
            }
            // if (node.label == '')
            console.log('ICON', node.icon)
            const value = {
                element: 'ontology',
                label: node.label,
                icon: node.icon || '',
                color: node.color,
                short: short,
                properties: properties
            }
            // const newNode = {neoLabel: value}
            // nodesNew.append(newNode);
            nodesNew[neoLabel] = value;
        }
        return nodesNew
    }
    createLink(links){
        console.log('LINKS', links)
        let linksNew = {};
        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            const neoLabel = link.neoLabel;
            let stLine = link.start.toLowerCase() + ":" + link.end.toLowerCase()
            let newOn = true;
            console.log(linksNew)
            if (neoLabel in linksNew) {
                linksNew[neoLabel].ST.push(stLine);
                console.log('same', neoLabel, linksNew[neoLabel].ST)
                newOn = false;
            }
            console.log('l.ST', stLine)

            if (newOn) {
                let ST = [stLine];
                let properties = {}

                for (let j = 0; j < link.attributes.length; j++) {
                    const attr = link.attributes[j]
                    let name = attr.name
                    if (name.length > 0) {
                        let type = 'String'
                        if (attr.name.split(':').length > 1) {
                            name = attr.name.split(':')[0]
                            if (attr.name.split(':')[1] === 'int') type = 'Number'
                            if (attr.name.split(':')[1] === 'double') type = 'Number'
                            if (attr.name.split(':')[1] === 'boolean') type = 'Boolean'
                            if (attr.name.split(':')[1] === 'date') type = 'Date'
                        }
                        properties[name] = {
                            type: type,
                            isList: false
                        }
                    }
                }

                let value = {
                    label: link.label,
                    ST: ST,
                    properties: properties,
                };
                console.log(i, neoLabel, value)
                // let linkNew = {neoLabel: value};
                // linksNew.push(linkNew);
                linksNew[neoLabel] = value;
            }
        }

        return linksNew

    }
    createRules(links){
        let ruleList = {};
        for (let i = 0; i < links.length; i++) {
            const link = links[i]
            if ((link.start + ':' + link.end) in ruleList) {
                ruleList[link.start + ':' + link.end].push({
                    type: link.neoLabel,
                    maxST: link.maxEIn,
                    maxTS: link.maxSOut,
                    // maxSameTS: link.maxSameTS,
                    // maxSameST: link.maxSameST,
                    maxSameTS: 10,
                    maxSameST: 10,
                })
            } else {
                ruleList[link.start + ':' + link.end] = [{
                    type: link.neoLabel,
                    maxST: link.maxEIn,
                    maxTS: link.maxSOut,
                    // maxSameTS: link.maxSameTS,
                    // maxSameST: link.maxSameST,
                    maxSameTS: 10,
                    maxSameST: 10,
                }]
            }
            console.log('rules', ruleList)

        }
        return ruleList
    }

}