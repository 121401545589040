<template>
  <div>
    <div v-loading.fullscreen.lock="loading">
      <el-row style="padding: 16px">
        <el-col :span="6">
          <el-row>
            <el-card style="width:100%; text-align: center">
              <template #header>
                <div
                  class="clearfix"
                  :style="{ color: neo4jActiveColor }"
                  @click="updateNeo4jStatus"
                >
                  <span>Neo4j</span>
                </div>
              </template>
              <h1 :style="{ color: neo4jActiveColor }">{{ currentOntologyName }}</h1>
              <el-select
                v-model="pickedOntology"
                value-key="_id"
                style="margin-bottom: 16px"
                :placeholder="$t('Выберите папку')"
              >
                <el-option
                  v-for="folder in folders"
                  :key="folder._id"
                  :label="folder.username + ' ' + folder.foldername"
                  style="min-height: 70px; height: unset; max-width: 1200px; display: flex; flex-direction: row;"
                  :value="folder"
                >
                  <div style="font-size: 16px; min-width: 200px;">
                    {{ folder.username + ' ' + folder.foldername }}
                  </div>
                  <div style="white-space: break-spaces; margin-left: 1rem;">
                    <el-tag
                      v-for="item in folder.files"
                      :key="item"
                      style="margin-left: 4px"
                      type="primary"
                      size="mini"
                      effect="dark"
                    >
                      {{ item.filename }}
                    </el-tag>
                  </div>
                  <!--                  <div style="margin-bottom: 6px; font-size: 12px">-->
                  <!--                    {{ 'Узлов: ' + onto.count.nodes + ' | Связей: ' + onto.count.links }}-->
                  <!--                  </div>-->
                </el-option>
              </el-select>
              <el-button
                type="primary"
                @click="riseFromFolder"
                style="width: 55%; padding-left: 0px; padding-right: 0px;"
                :loading="riseFromFolderLoading"
              >
                {{ $t('Создать из папки') }}
              </el-button>
              <el-button
                type="primary"
                @click="deleteFolder"
                :disabled="!pickedOntology"
                style="width: 55%; padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-top: 0.5rem;"
              >
                {{ $t('Удалить папку') }}
              </el-button>
            </el-card>
          </el-row>
          <el-row style="margin-top: 16px">
            <el-card style="width:100%; text-align: center">
              <template #header>
                <div class="clearfix">
                  <span>{{ $t('Имя папки') }}</span>
                </div>
              </template>
              <input type="text" v-model="ontologyName" />
              <input
                hidden
                type="file"
                name="input_files"
                id="files"
                ref="files"
                @change="handleFileUploads()"
                multiple
              />
              <div style="margin-top: 8px">
                <el-button
                  type="primary"
                  @click="clickUploadButton"
                  style="width: 55%; padding-left: 0px; padding-right: 0px;"
                >
                  {{ $t('Выберите файлы') }}
                </el-button>
              </div>
              <div style="margin-top: 8px">
                <el-button
                  type="primary"
                  @click="sendFiles"
                  style="width: 55%; padding-left: 0px; padding-right: 0px;"
                >
                  {{ $t('сохранить файлы в папку') }}
                </el-button>
              </div>
            </el-card>
          </el-row>

          <el-row style="margin-top: 16px">
            <el-card style="width:100%; text-align: center">
              <template #header>
                <div class="clearfix">
                  <span>{{ $t('Создать из текущей бд') }}</span>
                </div>
              </template>
              <div style="margin-top: 8px">
                <el-button
                  type="success"
                  @click="centerDialogVisible = true"
                  style="width: 55%; padding-left: 0px; padding-right: 0px;"
                >
                  {{ $t('Создать') }}
                </el-button>
                <el-dialog
                  title="Имя новой онтологии"
                  v-model="centerDialogVisible"
                  width="30%"
                  center
                >
                  <span style="margin-left: auto">{{ $t('Пожалуйста, назовите онтологию') }}</span>
                  <div>
                    <input type="text" v-model="ontologyName" />
                  </div>
                  <div>
                    <span>
                      {{ $t('Сюда прилетают ноды, нужно компонент с выбором знача/имени как в файлах с') }}
                      ontoParams
                    </span>
                  </div>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="centerDialogVisible = false">{{ $t('Отмена') }}</el-button>
                      <el-button @click="centerDialogVisible = false" type="success">
                        {{ $t('Создать') }}
                      </el-button>
                    </span>
                  </template>
                </el-dialog>
              </div>
            </el-card>
          </el-row>
          <el-row style="margin-top: 16px">
            <el-card style="width:100%; text-align: left ; white-space: pre; font-size: 8px">
              <div>
                {{ neo4jLogs }}
              </div>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="18" style="padding-left: 16px">
          <el-card style="width:100%">
            <FileList v-bind:files="files" @remove-file="removeFile" />
            <span v-if="submitted">{{ $t('Визуализация онтологии') }}</span>
            <SchemeWindowOntology
              ref="ontology"
              v-if="submitted"
              :graph-from-backend="graphFromBackend"
            />
          </el-card>
          <vue-json-pretty style="opacity: 0" :path="'res'" :data="content"></vue-json-pretty>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import FileList from './FileList';
import SchemeWindowOntology from './SchemeWindowOntology';
import OntologyController from '../../controllers/ontology.controller';
import Utils from '../../services/utils';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Neo4jService from '../../services/neo4j.service';
import OntologyService from '../../services/ontology.service';
import { ElNotification } from 'element-plus';

export default {
  from: 'CSVLoader',
  components: {
    FileList,
    SchemeWindowOntology,
    VueJsonPretty,
  },
  data() {
    return {
      currentOntologyName: '',
      neo4jLogs: '',
      neo4jActiveColor: '',
      centerDialogVisible: false,
      ontologyName: '',
      content: '',
      files: [],
      pickedOntology: undefined,
      ontologies: [],
      fileList: [],
      graphFromBackend: [],
      submitted: false,
      ontologyController: null,
      folders: [],
      notification: {
        schemeCreateFromFolder: null,
        schemeCreateFromFolderError: null,
         schemeCreateFile: null,
       schemeCreateFileError: null,
      },
      riseFromFolderLoading: false
    };
  },
  mounted() {
    //автообновление статуса нео раз в 5 минут
    setTimeout(
      function askStatus() {
        this.updateNeo4jStatus();
        setTimeout(askStatus.bind(this), 300000);
      }.bind(this),
      10,
    );
    this.ontologyController = new OntologyController();
    if (this.ontologyController.getOntology() !== null) {
      this.ontologyController = new OntologyController();
      this.ontologyController.putOntologyToStore();
      let ontology = this.ontologyController.getOntology();
      this.currentOntologyName = ontology.name;
      this.loadFolders();
    }
    // const ontology_count = {nodes: ontology.nodes.length, links: ontology.edges.length}
    //     let ontology_labels = []
    //     for (let i = 0; i < ontology.nodes.length; i++) {
    //       const node = ontology.nodes[i]
    //       ontology_labels.push(node.neoLabel)
    //     }
    //     this.ontologies.push({
    //       name: this.ontologyName,
    //       labels: ontology_labels,
    //       count: ontology_count,
    //       _id: ontology._id
    //     })

    this.notification.schemeCreateFromFolder = () => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Название успешно изменено'),
      });
    };

    this.notification.schemeCreateFromFolderError = () => {
      ElNotification({
        title: this.$t('Ошибка'),
        type: 'error',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Ошибка во время создания папки'),
      });
    };
     this.notification.schemeCreateFile = () => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Файл успешно создан'),
      });
    };

    this.notification.schemeCreateFileError = () => {
      ElNotification({
        title: this.$t('Ошибка'),
        type: 'error',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Ошибка во время создания файла'),
      });
    };
  },

  props: {
    component: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    showNotification(obj) {
      this.notification[obj.name](obj.agrs);
    },
    loadFolders() {
      this.ontologyController
        .getFolderParams()
        .then((value) => console.log('параметры папки', (this.folders = value.data)));
    },
    deleteFolder () {
        const itemForDelete = this.pickedOntology;
        this.pickedOntology = null;
        OntologyService.deleteFolder(itemForDelete).then(() => {            
            this.loadFolders();
            this.pickedOntology = undefined;
            ElNotification({title: this.$t('Успешно'),
                message: this.$t('Папка была удалена'),
                type: 'success',
                offset: 56
            });
        }).catch(() => {
            ElNotification({
                title: this.$t('Ошибка'),
                message: this.$t('Произошла ошибка во время удаления'),
                type: 'error',
                offset: 56
            })
        });
    },
    riseFromFolder() {
      this.riseFromFolderLoading = true
      OntologyService.riseFromFolder(this.pickedOntology)
        .then(async () => {
          localStorage.removeItem("displayAttribute");
          await this.ontologyController.putOntologyToStore();
          let ontology = await this.ontologyController.getOntology();
          this.currentOntologyName = ontology.name;
          this.loadFolders();
          this.showNotification({
            name: 'schemeCreateFromFolder',
            agrs: { text: this.$t('Название успешно изменено') },
          });
        })
        .catch(() =>
          this.showNotification({
            name: 'schemeCreateFromFolderError',
            agrs: { text: this.$t('Ошибка при создании папки') },
          }),
        ).finally(() => this.riseFromFolderLoading = false );
    },
    async sendFiles() {
      this.submitted = false;
      //если не заполнил, то онтология назовется именем пользователя
      if (!this.ontologyName) {
        this.ontologyName = this.currentUser.username;
      }
      this.ontologyController
        .sendFiles(this.files, this.currentUser.username, this.ontologyName)
        .then(async (value) => {
          await this.ontologyController.putOntologyToStore();
          let ontology = await this.ontologyController.getOntology();
          this.currentOntologyName = ontology.name;
          this.loadFolders();
          this.neo4jLogs = value.data.neolog;
          this.loadFolders();
          this.showNotification({
            name: 'schemeCreateFile',
            agrs: { text: this.$t('Файл успешно создан') },
          });
          // alert(value.data.neolog);
        })
        .catch(() =>
          this.showNotification({
            name: 'schemeCreateFileError',
            agrs: { text: this.$t('Ошибка при создании файла') },
          }),
        );
    },
    updateNeo4jStatus() {
      Neo4jService.askForCurrentNeo4jStatus()
        .then(() => (this.neo4jActiveColor = 'green'))
        .catch(() => {
          this.neo4jActiveColor = 'red';
        });
    },
    clickUploadButton() {
      document.getElementById('files').click();
    },
    handleFileUploads() {
      const htmlfiles = document.getElementById('files').files;

      for (let i = 0; i < htmlfiles.length; i++) {
        this.files.push({
          filename: htmlfiles[i].name,
          data: htmlfiles[i],
          type: htmlfiles[i].name.toLowerCase().includes('link') ? 'relation' : 'node',
          //забираю первые строки в имени файла потому чтоленив
          neoLabel: htmlfiles[i].name.split('.')[0].split('_')[0] ?? '',
          label: htmlfiles[i].name.split('.')[0].split('_')[1] ?? '',
          color: Utils.randomColor(),
          expand: false,
          user: this.currentUser.username,
          icon: 'fas fa-smile',
        });
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      document.getElementById('files').files.length = this.files.length;
    },
    // async sendFilesAndGetOntologyOld() {
    //   this.submitted = false;
    //   this.ontologyController.sendFilesAndGetOntology(this.files, this.currentUser.username, this.ontologyName).then(() => {
    //         this.content = store.getters['ontologyStore/getOntologyStore']
    //         this.submitted = true;
    //         console.log('current ontology=', store.getters['ontologyStore/getOntologyStore'])
    //       }
    //   )
    //   // успешная загрузка
    // },

    // loadOntologyOld() {
    //   this.ontologyController.loadOntology(this.files, this.currentUser.username, this.ontologyName).then(response => {
    //     this.content = response.data
    //     // console.log('respons e data', response.data)
    //     const ontology_count = {nodes: response.data.nodes.length, links: response.data.edges.length}
    //     let ontology_labels = []
    //     for (let i = 0; i < response.data.nodes.length; i++) {
    //       const node = response.data.nodes[i]
    //       ontology_labels.push(node.neoLabel)
    //     }
    //     this.ontologies.push({
    //       name: this.ontologyName,
    //       labels: ontology_labels,
    //       count: ontology_count,
    //       _id: response.data._id
    //     })
    //     console.log('current ontology=', store.getters['ontologyStore/getOntologyStore'])
    //     alert('Онтология создана')
    //     // this.pikedOntology.
    //     // успешная загрузка
    //   })
    // },
    // async getOntology() {
    //   this.submitted = false;
    //   console.log('thispickedOntology', this.pickedOntology);
    //   const ontology = new Ontology(this.pickedOntology.ontoFull.nodes, this.pickedOntology.ontoFull.edges, this.pickedOntology.ontoFull.id)
    //   store.commit('ontologyStore/setOntologyStore', ontology);
    //   console.log('current ontology=', store.getters['ontologyStore/getOntologyStore'])
    //   this.submitted = true;
    // },
  },
};
</script>

<style scoped>
input[type='file'] {
  color: transparent;
}

label {
  padding: 10px;
}

#loadDB {
  margin-bottom: 10px;
}

#showScheme {
  margin-bottom: 10px;
}

#viewport {
  width: 100%;
}
</style>
