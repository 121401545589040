<template>
    <div
            id="scheme-window"
            :style="{  position: 'relative', left:0, right: 0, width: (width-2) + 'px', height: (height-2) + 'px', border: '1px solid #bbb' }"
    >
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <g id="gElement">
                <foreignObject  :style="{height: 40 + 'px', width: (180)  + 'px', 'z-index':1000000, display: 'none'}" id="contextMenu" >
                    <div id="contextMenu">
                    </div>
                </foreignObject>
            </g>
        </svg>
    </div>
</template>

<script>
    // import {Filter} from "sigma-webpack/plugins/sigma.plugins.filter/sigma.plugins.filter.js";
    import D3Controller from '../../controllers/d3.controller'
    import OntologyController from "../../controllers/ontology.controller";
    export default {
        from: 'SchemeWindowOntology',
        name: 'SchemeWindowOntology',
        props: ['graphFromBackend'],
        data() {
            return {
                ontologyser: null,
                data_from_loader: {},
                graph: {
                    // nodes: [],
                    nodes:[],
                    links: [],
                    values: [],
                    operators: [],
                    logics: [],
                    attributes: [],
                    functions: []
                },
                forceProperties: {
                    center: {
                        x: 0.5,
                        y: 0.5
                    },
                    charge: {
                        enabled: true,
                        strength: -200,
                        distanceMin: 1,
                        distanceMax: 2000
                    },
                    collide: {
                        enabled: true,
                        strength: 0.1,
                        iterations: 1,
                        radius: 35
                    },
                    forceX: {
                        enabled: true,
                        strength: 0.05,
                        x: 0.5
                    },
                    forceY: {
                        enabled: true,
                        strength: 0.05,
                        y: 0.5
                    },
                    link: {
                        enabled: true,
                        distance: 100,
                        iterations: 1
                    }
                },
                // width:1000,
                width: Math.max(
                    document.documentElement.clientWidth,
                    window.innerWidth || 0
                )*1/1 - 64,
                height: 500,
                selections: {},
                colorsEdges: [
                    [0, 0]
                ],
                countEdges: 0,
                legend: {
                    nodes: [],
                    edges: []
                },
                nodesCounter: 0,
                linksCounter: 0,
                isLoading: false,
                ontology: null,
                ontologyController: null
            }
        },
        mounted: function () {
            this.ontologyController = new OntologyController()
            this.ontology = this.ontologyController.getOntology()
            this.controller = new D3Controller(this.$el.querySelector('svg'), this.width, this.height)

            this.init()
            this.controller.bindZoom([1 / 4, 4])
            this.graph.nodes = [];
            for (let name of Object.keys(this.ontology.nodes)) {
                // todo сделать чтобы нормально вытаскивало
                const n = {
                    element: 'ontology',
                    name: name,
                    x: Math.random() * 200,
                    y: Math.random() * 200,
                    color: this.ontology.nodes[name].color
                }
                this.graph.nodes.push(n);
            }
            this.graph.links = [];
            for (let pair of Object.keys(this.ontology.ruleList)) {
                let src;
                let trg;
                const source_name = pair.split(':')[0];
                const target_name = pair.split(':')[1];
                const link_name = this.ontology.ruleList[pair].type
                for (let j = 0; j < this.graph.nodes.length; j++) {
                    const node_name = this.graph.nodes[j].name;
                    if (source_name == node_name) {
                        src = this.graph.nodes[j];
                    }
                    if (target_name == node_name) {
                        trg = this.graph.nodes[j];
                    }

                }
                let counterOfSameLink = 0
                for (let l of this.graph.links) {
                    if (l.target.name === target_name && l.source.name === source_name) counterOfSameLink++
                    else if (l.source.name === target_name && l.target.name === source_name) counterOfSameLink++
                }

                const link = {
                    element: 'link',
                    name: link_name,
                    sameLink: {current: counterOfSameLink, all: counterOfSameLink},
                    sourceD: {dx: 0, dy: 0},
                    targetD: {dx: 0, dy: 0},
                    sourcePart: null,
                    targetPart: null,
                    source: src,
                    target: trg,
                }

                for (let l of this.graph.links) {
                    if ((l.source.name === link.source.name && l.target.name === link.target.name) ||
                        (l.source.name === link.target.name && l.target.name === link.source.name)) {
                        if (l !== link) {
                            l.sameLink = {
                                current: l.sameLink.current,
                                all: counterOfSameLink
                            }
                        }
                    }
                }
                this.graph.links.push(link);
            }
            this.restart('add');
        },
        methods: {
            initFromSchemaData() {
                this.graph.nodes = []
                this.graph.links = []
                this.restart('')
            },
            init() {
                this.controller.bindSimulation(this.forceProperties)
                this.controller.enableDrag()
            },
            restart(action) {
                // console.log(this.graph.nodes)
                // console.log(this.graph.links)
                this.controller.updateGraph(this.graph)

                this.controller.restartSimulation()

                this.controller.removeElements('.node')
                this.controller.removeElements('.link')
                this.controller.removeElements('.marker')
                this.controller.removeElements('.node-link')

                this.controller.createLinks(action, '#contextMenu', null, null, null, null)
                this.controller.createOntology(action, '#contextMenu', null, null)

                this.controller.enableDrag()

                if (this.controller.event()) {
                    // prevent browser's default behavior
                    this.controller.event().preventDefault()
                }

                this.controller.restartSimulation()

                this.controller.setScale()

            }
        }
    }
</script>
<!--<style scoped src="../assets/index.css"></style>-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    div{

        height: 500px;
    }
    h1,
    h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    #container {
        width: 100%;
        height: 100%;
    }

    .cc-selector input {
        margin: 0;
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .cc-selector input:active + .drinkcard-cc {
        opacity: .9;
    }

    .cc-selector input:checked + .drinkcard-cc {
        -webkit-filter: none;
        -moz-filter: none;
        filter: none;
    }

    .ic {
        width: 32px;
        height: 32px;
        margin-top: 0;
        padding-top: 0;
        background-color: #27B8B2;
        border-radius: 25%;
    }

    .icon-cog {
        color: white;
        margin-top: 5px;
        margin-left: 0px;
    }

    .drinkcard-cc {
        cursor: pointer;
        margin-right: -14px;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        -webkit-transition: all 100ms ease-in;
        -moz-transition: all 100ms ease-in;
        transition: all 100ms ease-in;
        -webkit-filter: brightness(1.4) grayscale(.7) opacity(.8);
        -moz-filter: brightness(1.4) grayscale(.7) opacity(.8);
        filter: brightness(1.4) grayscale(.7) opacity(.8);
    }

    .drinkcard-cc:hover {
        -webkit-filter: brightness(1.1) grayscale(.5) opacity(.9);
        -moz-filter: brightness(1.1) grayscale(.5) opacity(.9);
        filter: brightness(1.1) grayscale(.5) opacity(.9);
    }

    #control-pane > div {
        margin: 10px;
        overflow-x: auto;
    }

    .drag_line {
        stroke: #999;
        stroke-width: 3;
        pointer-events: none;
    }

    .drag_line_hidden {
        stroke: #999;
        stroke-width: 0;
        pointer-events: none;
    }

    .link {
        stroke: #999;
        stroke-width: 3;
    }

    .link_selected {
        stroke: #ff7f0e;
    }

</style>
