export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    let headers = {};

    if (!user) {
        return headers;
    }

    if (user.accessToken) {
        // for Node.js Express back-end
        headers["x-access-token"] = user.accessToken;
    }

    headers["Accept-Language"] = user.lang || 'ru';

    return headers;
}

export function authHeaderForFiles(data) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        return {
            'x-access-token': user.accessToken,
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        };

    } else {
        return {};
    }
}

