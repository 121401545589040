const config = {
  // API: 'http://84.201.137.250:8083/api',
  //  API:'https://10.7.1.126:8083/api',
  // API:'https://185.141.127.30:8083/api',
  // socket: 'http://84.201.137.250:8083',
  socket:'http://158.160.29.104:8083',
  API:'http://158.160.29.104:8083/api',
  // socket:'https://gr.mumcfm.ru:8083',
  // API:'https://gr.mumcfm.ru:8083/api'
  instruction_link: 'https://mumcfm.ru/'
};
export default config;
