import CashService from '../services/cash.service'
import {store} from "../store";


export default class CashController {
    constructor() {
    }

    async putCashToStore(session) {
        return CashService.putCashToStore(session).then((response) => {

            store.commit('cashStore/setCash', response.data)
            return response
        }).catch( (err) => { return err.response})
    }

    getCashStore() {
        return store.getters['cashStore/getCash']
    }

    async setCashStore(cash) {
        store.commit('cashStore/setCash', cash)
        this.updateCash(this.getCashStore())
    }

    async setCartStore(data) {
        store.commit('cashStore/setCart', data)
        this.updateCash(this.getCashStore())
    }


    async updateCash(cash) {
        return CashService.updateCash(cash)
    }

}