export default class User {
  constructor(username, email, password, country, picture, lang) {
    this.username = username;
    this.email = email;
    this.password = password;
    this.country = country;
    this.picture = picture
    this.lang = lang || 'ru'
  }
}
