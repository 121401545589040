import axios from 'axios';
import config from "../../config";
import {store} from "../store";
import authHeader from "./auth-header";

const API_URL = config.API+'/Neo4j/';

class Neo4jService {

    askForCurrentNeo4jStatus (){
        return axios.get(API_URL+'status',{ headers: authHeader() })
    }

    getPathCount(data) {
        return axios.post(API_URL + 'getPathCount', data,{ headers: authHeader() });
    }

    getNodeCount(data) {
        return axios.post(API_URL + 'getNodeCount', data,{ headers: authHeader() });
    }

    getEdgesWindow(data) {
        return axios.post(API_URL + 'getEdges', data,{ headers: authHeader() });
    }

    revealLinks(data) {
        return axios.post(API_URL + 'revealLinks', data,{ headers: authHeader() });
    }

    getSubgraphs(data) {
        data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide
        data['sessionId'] = store.getters['sessionStore/getCurrentSession']._id
        data['caseId'] = store.getters['sessionStore/getCurrentSession'].caseId
        return axios.post(API_URL + 'getSubgraphs', data,{ headers: authHeader() });
    }

    getNodeByID(id) {
        return axios.get(API_URL + 'getNodeCount/:' + id,{ headers: authHeader() });
    }

    getNode (data) {
      data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide
        return axios.post(API_URL + 'getNode', data,{ headers: authHeader() });
    }

    getLink (data) {
      data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide
        return axios.post(API_URL + 'getLink', data,{ headers: authHeader() });
    }

    searchNodeByString(data) {
        data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide
        return axios.post(API_URL + 'getNodeByString', data,{ headers: authHeader() });
    }

    searchNodeByIdList(data) {
        data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide
        return axios.post(API_URL + 'getNodeByIdList', data,{ headers: authHeader() });
    }

    searchSubgraphByString(data) {
        return axios.post(API_URL + 'getSubgraphByString', data,{ headers: authHeader() });
    }

    expandNode(data) {
      data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide
        return axios.post(API_URL + 'expandNode/', data,{ headers: authHeader() });
    }

    getSimpleEdges (data) {
        return axios.post(API_URL + 'getSimpleEdges/', data,{ headers: authHeader() });
    }

    getSubgraphGraph(data) {
        return axios.post(API_URL + 'getSubgraphGraph/', data,{ headers: authHeader() });
    }

    getSubgraphMap(data) {
        return axios.post(API_URL + 'getSubgraphMap/', data,{ headers: authHeader() });
    }

    getVGraph(data) {
        return axios.post(API_URL + 'getVGraph', data,{ headers: authHeader() });
    }

    getAttributeNodes(data) {
        return axios.post(API_URL + 'getAttributeNodes', data,{ headers: authHeader() });
    }

    getLatLngNode(data) {
        return axios.post(API_URL + 'getLatLngNode', data,{ headers: authHeader() });
    }

    getLinkSourcesByNode(data) {
        return axios.post(API_URL + 'getLinkSourcesByNode', data,{ headers: authHeader() });
    }


}

export default new Neo4jService();
