import axios from 'axios';
import config from "../../config";
import bcrypt from 'bcryptjs';
const API_URL = config.API + '/auth';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + '/signin', {
                username: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    return response.data;
                }

            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(API_URL + '/signup', {
            username: user.username,
            email: user.email,
            password: bcrypt.hashSync(user.password, 8),
            country: user.country,
            picture: "",
            lang: user.lang
        }).then(response => {
            if (response.data.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data.data));
            }
            return response;
        })
    }
}

export default new AuthService();
