import * as d3 from 'd3'
import Utils from '../services/utils'
import basic from '../store/basic'

import arrowJSON from '../store/arrowJSON'
import {store} from "../store";

export default class D3Controller {
    constructor(svg, width, height) {
        this.ontology = store.getters['ontologyStore/getOntologyStore']
        // this.svg = svgSelection
        // this.graphSelection = graphSelection

        this.svg = d3.select(svg)
            .attr('width', width)
            .attr('height', height)
        this.graphSelection = this.svg.select('#gElement')
        //this.graphSelectionScale = this.svg.select('#gElementScale')
        this.height = height
        this.width = width
        this.simulation = null
        this.zoom = null
        this.graph = {
            nodes: [],
            links: [],
            values: [],
            operators: [],
            attributes: [],
            logics: [],
            functions: []
        }

        this.keydownDefault = null
        this.keyupDefault = null

        this.selected_link = null
        this.selected_node = null
        this.mousedown_node = null
        this.mouseup_node = null

        this.canBeSelected = true
        this.canBeEdited = true
        this.canDrawLine = true
        this.dragEnable = true

        this.dragline = null

        const vm = this
        this.arrowJSON = arrowJSON
        this.tickFunction = function () {
            const arrowJSON = vm.arrowJSON
            const marker = d => {
                let angle = Math.PI / 2
                let diffX = (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx)
                let diffY = d.source.y + d.sourceD.dy - d.target.y - d.targetD.dy
                if (d.element !== 'link') {
                    diffX = (d.source.source.x + d.source.sourceD.dx + d.source.target.x + d.source.targetD.dx) / 2 - d.target.x - d.targetD.dx
                    diffY = (d.source.source.y + d.source.sourceD.dy + d.source.target.y + d.source.targetD.dy) / 2 - d.target.y - d.targetD.dy
                }
                if (diffX !== 0) angle = Math.atan(diffY / diffX)
                if (diffX > 0) angle = Math.PI + angle
                if (d.source === d.target) {
                    if (d.source.element === 'ontology' && d.target.element === 'ontology') {
                        return 'translate(' + d.source.x + ',' +
                            d.source.y + ') ' +
                            'rotate(' + 90 +
                            ')'
                    }
                } else {
                    if (d.source.element === 'ontology' && d.target.element === 'ontology') {
                        let shift = 12
                        let number = (d.sameLink.all - 2 * d.sameLink.current)
                        if (diffX > 0) number = number * (-1)
                        let shiftQ = number * 22
                        let L = Math.sqrt(diffX * diffX + diffY * diffY)
                        let t1 = (L - 40) / L
                        if (L < 700) t1 = arrowJSON['' + Math.ceil(Math.abs(number))][Math.ceil(L - 170)]
                        let t2 = t1 - 0.1
                        let x1 = d.source.x + d.sourceD.dx
                        let y1 = d.source.y + d.sourceD.dy
                        let x2 = (d.source.x + d.sourceD.dx + d.target.x + d.targetD.dx) / 2 - shiftQ * Math.sin(angle)
                        let y2 = (d.source.y + d.sourceD.dy + d.target.y + d.targetD.dy) / 2 + shiftQ * Math.cos(angle)
                        let x3 = d.target.x + d.targetD.dx
                        let y3 = d.target.y + d.targetD.dy
                        let Bx1 = (1 - t1) * (1 - t1) * x1 + 2 * t1 * (1 - t1) * x2 + t1 * t1 * x3
                        let By1 = (1 - t1) * (1 - t1) * y1 + 2 * t1 * (1 - t1) * y2 + t1 * t1 * y3
                        let Bx2 = (1 - t2) * (1 - t2) * x1 + 2 * t2 * (1 - t2) * x2 + t2 * t2 * x3
                        let By2 = (1 - t2) * (1 - t2) * y1 + 2 * t2 * (1 - t2) * y2 + t2 * t2 * y3
                        let dopAngle = Math.PI / 2
                        if (Bx1 - Bx2 !== 0) dopAngle = Math.atan((By1 - By2) / (Bx1 - Bx2))
                        if (diffX > 0) dopAngle = Math.PI + dopAngle
                        let dop = (angle - dopAngle)
                        if (d.target.element !== 'ontology') shift = 12
                        let x = Bx1 - shift * Math.cos(angle - dop)
                        let y = By1 - shift * Math.sin(angle - dop)
                        return 'translate(' + x + ',' +
                            y + ') ' +
                            'rotate(' + 180 / Math.PI * (angle - dop) +
                            ')'
                    } else {
                        let shift = 12 + 8
                        let x = d.target.x + d.targetD.dx - shift * Math.cos(angle)
                        let y = d.target.y + d.targetD.dy - shift * Math.sin(angle)
                        return 'translate(' + x + ',' +
                            y + ') ' +
                            'rotate(' + 180 / Math.PI * (angle) +
                            ')'
                    }
                }
            }

            const linkNode = d => {
                if (d.element === 'link') {
                    let angle = Math.PI / 2
                    if (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx !== 0) angle = Math.atan((d.source.y + d.sourceD.dy - d.target.y - d.targetD.dy) / (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx))
                    let shift = (d.sameLink.all / 2 - d.sameLink.current) * 44
                    let x1 = -shift * Math.sin(angle) / 2 + (d.source.x + d.sourceD.dx + d.target.x + d.targetD.dx) / 2
                    let y1 = shift * Math.cos(angle) / 2 + (d.source.y + d.sourceD.dy + d.target.y + d.targetD.dy) / 2
                    return 'translate(' + ((x1)  - 30 * Math.cos(angle) + 10 * Math.sin(angle)) + ',' +
                        ((y1)  - 30 * Math.sin(angle) - 10 * Math.cos(angle)) + ') ' +
                        'rotate(' + 180 / Math.PI * angle +
                        ')'
                } else {
                    return ''
                }

            }

            const linkText = d => {
                let angle = Math.PI / 2
                if (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx !== 0) angle = Math.atan((d.source.y + d.sourceD.dy - d.target.y - d.targetD.dy) / (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx))
                let shift = (d.sameLink.all / 2 - d.sameLink.current) * 44
                let x1 = -shift * Math.sin(angle) / 2 + (d.source.x + d.sourceD.dx + d.target.x + d.targetD.dx) / 2
                let y1 = shift * Math.cos(angle) / 2 + (d.source.y + d.sourceD.dy + d.target.y + d.targetD.dy) / 2
                return 'translate(' + ((x1) + 10 * Math.sin(angle)) + ',' +
                    ((y1) - 10 * Math.cos(angle)) + ') ' +
                    'rotate(' + 180 / Math.PI * angle +
                    ')'

            }

            const transform = d => {
                return 'translate(' + (d.x) + ',' + (d.y) + ')'
            }

            const link = d => {
                try {
                    if (d.target !== d.source) {
                        if (d.element === 'link') {
                            let angle = Math.PI / 2
                            if (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx !== 0) angle = Math.atan((d.source.y + d.sourceD.dy - d.target.y - d.targetD.dy) / (d.source.x + d.sourceD.dx - d.target.x - d.targetD.dx))
                            let number = (d.sameLink.all - 2 * d.sameLink.current)
                            let shift = number * 22
                            let x1 = -shift * Math.sin(angle) + (d.source.x + d.sourceD.dx + d.target.x + d.targetD.dx) / 2
                            let y1 = shift * Math.cos(angle) + (d.source.y + d.sourceD.dy + d.target.y + d.targetD.dy) / 2
                            return 'M' + (d.source.x + d.sourceD.dx) + ',' + (d.source.y + d.sourceD.dy) + ' Q' + x1 + ',' + y1 + ' '
                                + (d.target.x + d.targetD.dx) + ',' + (d.target.y + d.targetD.dy)
                        } else {
                            let angle = Math.PI / 2
                            if (d.source.source.x + d.source.sourceD.dx - d.source.target.x - d.source.targetD.dx !== 0) {
                                angle = Math.atan((d.source.source.y + d.source.sourceD.dy - d.source.target.y - d.source.targetD.dy) / (d.source.source.x + d.source.sourceD.dx - d.source.target.x - d.source.targetD.dx))
                            }
                            let shift = (d.source.sameLink.all / 2 - d.source.sameLink.current) * 44
                            let sX = -shift * Math.sin(angle) / 2 + (d.source.source.x + d.source.sourceD.dx + d.source.target.x + d.source.targetD.dx) / 2
                            let sY = shift * Math.cos(angle) / 2 + (d.source.source.y + d.source.sourceD.dy + d.source.target.y + d.source.targetD.dy) / 2
                            return 'M' + (sX) + ',' + (sY) + ' L' + (d.target.x + d.targetD.dx) + ',' + (d.target.y + d.targetD.dy)
                        }
                    } else {
                        let x1 = d.source.x - 50
                        let y1 = d.source.y - 120
                        let x2 = d.source.x + 50
                        let y2 = d.source.y - 120
                        return 'M' + d.source.x + ',' + d.source.y + ' C' + x1 + ',' + y1 + ' ' + x2 + ',' + y2 + ' ' + d.source.x + ',' + d.source.y
                    }

                } catch (e) {
                    //
                }
            }

            const graph = vm.graphSelection
            graph.selectAll('.link').attr('d', link)
            graph.selectAll('.text-link').attr('transform', linkText)
            graph.selectAll('.node-link').attr('transform', linkNode)
            graph.selectAll('.node').attr('transform', transform)
            graph.selectAll('.marker').attr('transform', marker)
        }

    }


    createDragLine() {
        this.drag_line = this.graphSelection.insert('line', '.node')
            .attr('class', 'drag_line')
            .attr('id', 'dragLine')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', 0)
    }

    select(element) {
        return d3.select(element)
    }

    event() {
        return d3.event
    }

    mouse(element) {
        return d3.mouse(element)
    }

    updateGraph(graph) {
        this.graph.links = graph.links
        this.graph.nodes = graph.nodes
        this.graph.values = graph.values
        this.graph.operators = graph.operators
        this.graph.logics = graph.logics
        this.graph.attributes = graph.attributes
        this.graph.functions = graph.functions
    }

    bindActions(dblclick, mousedown, mouseup, mousemove) {
        this.graphSelection
            .on('dblclick', dblclick)
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)
            .on('mousemove', mousemove)
    }

    bindKeyUp(func) {
        this.keydownDefault = func
        d3.select(window).on('keyup', func)
    }

    bindKeyDown(func) {
        d3.select(window).on('keydown', func)
        this.keyupDefault = func
    }

    bindZoom(scale) {
        const vm = this
        this.zoom = d3.zoom()
            .scaleExtent(scale)
            .on('zoom', function () {
                const transform = d3.event.transform;
                vm.graphSelection.attr('transform', transform)
            })
        this.svg.call(this.zoom)
    }

    unbindZoom() {
        this.svg.on('.zoom', null)
    }

    tick() {
        this.tickFunction()
    }

    bindSimulation(forceProperties) {
        this.simulation = d3.forceSimulation()
            .force('link', d3.forceLink())
            .force('charge', d3.forceManyBody())
            .force('collide', d3.forceCollide())
            .force('center', d3.forceCenter())
            .force('forceX', d3.forceX())
            .force('forceY', d3.forceY())
            .on('tick', this.tickFunction)
        this.simulation.force('center')
            .x(this.width * forceProperties.center.x)
            .y(this.height * forceProperties.center.y)
        this.simulation.force('charge')
            .strength(forceProperties.charge.strength * forceProperties.charge.enabled)
            .distanceMin(forceProperties.charge.distanceMin)
            .distanceMax(forceProperties.charge.distanceMax)
        this.simulation.force('link')
            .distance(forceProperties.link.distance)
            .iterations(forceProperties.link.iterations)
    }

    restartSimulation() {
        if (this.simulation !== null) {
            this.simulation.nodes(((((this.graph.nodes.concat(this.graph.values))
                .concat(this.graph.operators)).concat(this.graph.logics)).concat(this.graph.attributes)).concat(this.graph.functions))
            this.simulation.force('link').links(this.graph.links)
            this.simulation.alpha(1).restart()
        }
    }

    disableDrag() {
        this.dragEnable = false
        this.graphSelection.selectAll('.node').on('mousedown.drag', null)
    }

    enableDrag() {
        this.dragEnable = true
        const vm = this
        this.graphSelection.selectAll('.node')
            .call(d3.drag()
                .on('start', function (d) {
                    if ((!d3.event.active) && (vm.simulation != null)) {
                        vm.simulation.alphaTarget(0.3).restart()
                    }
                    d.fx = d.x
                    d.fy = d.y
                })
                .on('drag', function (d) {
                    d.fx = d3.event.x
                    d.fy = d3.event.y
                    let minDist = 170
                    for (let link of vm.graph.links) {
                        minDist = 170
                        if (link.source === d) {
                            if (link.source !== link.target) {
                                if (link.target.element !== 'ontology' || d.element !== 'ontology') minDist = 80
                                let DDx = d.fx + link.sourceD.dx - link.target.x - link.targetD.dx
                                let DDy = d.fy + link.sourceD.dy - link.target.y - link.targetD.dy
                                let angle = Math.PI / 2
                                if (DDx !== 0) angle = Math.atan(DDy / DDx)
                                if (DDx > 0) angle = angle + Math.PI
                                if (Math.sqrt(DDx * DDx + DDy * DDy) < minDist) {
                                    d.fx = -Math.cos(angle) * minDist + link.target.x + link.targetD.dx - link.sourceD.dx
                                    d.fy = -Math.sin(angle) * minDist + link.target.y + link.targetD.dy - link.sourceD.dy
                                }
                            }
                        }
                        if (link.target === d) {
                            if (link.source !== link.target) {
                                if (link.source.element !== 'ontology' || d.element !== 'ontology') minDist = 80
                                let DDx = d.fx + link.targetD.dx - link.source.x - link.sourceD.dx
                                let DDy = d.fy + link.targetD.dy - link.source.y - link.sourceD.dy
                                let angle = Math.PI / 2
                                if (DDx !== 0) angle = Math.atan(DDy / DDx)
                                if (DDx > 0) angle = angle + Math.PI
                                if (Math.sqrt(DDx * DDx + DDy * DDy) < minDist) {
                                    d.fx = -Math.cos(angle) * minDist + link.source.x + link.sourceD.dx - link.targetD.dx
                                    d.fy = -Math.sin(angle) * minDist + link.source.y + link.sourceD.dy - link.targetD.dy
                                }
                            }
                        }
                    }
                })
                .on('end', function () {
                    if ((!d3.event.active) && (vm.simulation != null)) {
                        vm.simulation.alphaTarget(0.0001)
                    }
                })
            )
    }

    createDataTypeLabel(selection, size, shift, displayF, textF) {
        let gDataType = selection.insert('g').attr('class', 'data-type-label').attr('style', displayF)
        gDataType.append('rect')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
            })
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', d => {
                return -(size + d.additionalWidth) / 2
            })
            .attr('y', -26 + shift)
            .attr('width', d => {
                return size + d.additionalWidth
            })
            .attr('height', 20)
            .attr('style', 'fill:#020202;stroke:white;stroke-width:2px')

        gDataType.insert('text')
            .attr('x', 0)
            .attr('class', 'data-type-text')
            .attr("contentEditable", true)
            .attr('y', -11 + shift)
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(textF)
    }

    createComplexInputNode(selection, d, size, shiftY, part) {

        const vm = this

        // console.log('createComplexInputNode', selection, d, size, shiftY, part)

        let gJoint = selection.append('g')
            .attr('class', 'in-node')
            .attr('transform', 'translate(' + (-(size + d.additionalWidth) / 2) + ',' + shiftY +')')
            .on('mousedown',
                function () {
                    if (vm.canBeSelected !== 'add' && d3.event.button === 0) {
                        vm.resetMouseVars()
                        vm.mousedown_node = d
                        vm.selected_node = d
                        vm.mousedown_node.dx = -(size + d.additionalWidth) / 2
                        vm.selected_node.dx = -(size + d.additionalWidth) / 2
                        vm.mousedown_node.dy = shiftY
                        vm.selected_node.dy = shiftY
                        vm.mousedown_node.part = 'in' + part
                        vm.selected_node.part = 'in' + part
                        vm.selected_link = null
                        d3.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
                    }
                })
            .on('mouseup',
                function () {
                    if (d3.event.button === 0) {
                        if (vm.instrument !== 'add') {
                            vm.selected_node = d
                            vm.mouseup_node = d
                            vm.mouseup_node.dx = -(size + d.additionalWidth) / 2
                            vm.selected_node.dx = -(size + d.additionalWidth) / 2
                            vm.mouseup_node.dy = shiftY
                            vm.selected_node.dy = shiftY
                            vm.mouseup_node.part = 'in' + part
                            vm.selected_node.part = 'in' + part
                        }
                    }
                })


        gJoint.append('circle')
            .attr('r', 9)
            .attr('style', 'pointer-events: fill; fill:#808080; stroke:none;')

        gJoint.append('polygon')
            .attr('points', '5,0 -4,5 -4,-5')
            .attr('class', 'strokeJoint')
            .attr('style', 'pointer-events: fill; fill:white;')


    }

    createInputNode(selection, size, shiftY) {

        const vm = this

        let gJoint = selection.append('g')
            .attr('class', 'in-node')
            .attr('transform', d => {
                return 'translate(' + (-(size + d.additionalWidth) / 2) + ',' + shiftY +')'
            })
            .on('mousedown',
                function (d) {
                    if (vm.canBeSelected !== 'add' && d3.event.button === 0) {
                        vm.resetMouseVars()
                        vm.mousedown_node = d
                        vm.selected_node = d
                        vm.mousedown_node.dx = -(size + d.additionalWidth) / 2
                        vm.selected_node.dx = -(size + d.additionalWidth) / 2
                        vm.mousedown_node.dy = shiftY
                        vm.selected_node.dy = shiftY
                        vm.mousedown_node.part = 'in'
                        vm.selected_node.part = 'in'
                        vm.selected_link = null
                        d3.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
                    }
                })
            .on('mouseup',
                function (d) {
                    if (d3.event.button === 0) {
                        if (vm.instrument !== 'add') {
                            vm.selected_node = d
                            vm.mouseup_node = d
                            vm.mouseup_node.dx = -(size + d.additionalWidth) / 2
                            vm.selected_node.dx = -(size + d.additionalWidth) / 2
                            vm.mouseup_node.dy = shiftY
                            vm.selected_node.dy = shiftY
                            vm.mouseup_node.part = 'in'
                            vm.selected_node.part = 'in'
                        }
                    }
                })


        gJoint.append('circle')
            .attr('r', 9)
            .attr('style', 'pointer-events: fill; fill:#808080; stroke:none;')

        gJoint.append('polygon')
            .attr('points', '5,0 -4,5 -4,-5')
            .attr('class', 'strokeJoint')
            .attr('style', 'pointer-events: fill; fill:white;')


    }

    createOutputNode(selection, size, shiftY) {

        const vm = this

        let gJoint = selection.append('g')
            .attr('class', 'out-node')
            .attr('transform', d => {
                return 'translate(' + ((size + d.additionalWidth) / 2) + ',' + shiftY + ')'
            })
            .on('mousedown',
                function (d) {
                    if (vm.canBeSelected !== 'add' && d3.event.button === 0) {
                        vm.resetMouseVars()
                        vm.mousedown_node = d
                        vm.selected_node = d
                        vm.mousedown_node.dx = (size + d.additionalWidth) / 2
                        vm.selected_node.dx = (size + d.additionalWidth) / 2
                        vm.mousedown_node.dy = shiftY
                        vm.selected_node.dy = shiftY
                        vm.mousedown_node.part = 'out'
                        vm.selected_node.part = 'out'
                        vm.selected_link = null
                        d3.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
                    }
                })
            .on('mouseup',
                function (d) {
                    if (d3.event.button === 0) {
                        if (vm.instrument !== 'add') {
                            vm.selected_node = d
                            vm.mouseup_node = d
                            vm.mouseup_node.dx = (size + d.additionalWidth) / 2
                            vm.selected_node.dx = (size + d.additionalWidth) / 2
                            vm.mouseup_node.dy = shiftY
                            vm.selected_node.dy = shiftY
                            vm.mouseup_node.part = 'out'
                            vm.selected_node.part = 'out'
                        }
                    }
                })

        gJoint.append('circle')
            .attr('r', 9)
            .attr('style', 'pointer-events: fill; fill:#808080; stroke:none;')

        gJoint.append('polygon')
            .attr('points', '5,0 -4,5 -4,-5')
            .attr('class', 'strokeJoint')
            .attr('style', 'pointer-events: fill; fill:white;')

    }

    setScale() {
        let minX = 10000
        let maxX = -10000
        let minY = 10000
        let maxY = -10000
        let allNodes = ((((this.graph.nodes.concat(this.graph.values))
            .concat(this.graph.operators)).concat(this.graph.logics)).concat(this.graph.attributes)).concat(this.graph.functions)
        for (let node of allNodes) {
            if (minX > node.x) minX = node.x
            if (maxX < node.x) maxX = node.x
            if (minY > node.y) minY = node.y
            if (maxY < node.y) maxY = node.y
            // if (node !== undefined) {
            //     if (minX > node.x) minX = node.x
            //     if (maxX < node.x) maxX = node.x
            //     if (minY > node.y) minY = node.y
            //     if (maxY < node.y) maxY = node.y
            // }
        }
        // console.log(minX, maxX, minY, maxY)

        this.zoom.translateTo(this.svg, (maxX + minX) / 2, (maxY + minY) / 2)

        let scale = 1
        if ((maxX - minX) > (maxY - minY)) scale = 240 / (maxX - minX)
        else scale = 340 / (maxY - minY)
        if (scale < 0.25) scale = 0.25
        this.zoom.scaleTo(this.svg, scale)
    }

    selectNode(node) {
        const vm = this
        if (node !== null) {
            this.graphSelection.selectAll('.stroke').attr('style', d => {
                if (node.index === d.index && d.element === node.element){
                    return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:#AC3B61;stroke-width:6px'
                } else {
                    return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:white;stroke-width:6px'
                }
            })
        }
    }

    removeElements(className) {
        this.graphSelection.selectAll(className).remove()
    }


    createLinks(action, beforeElement, linkMousedown, linkMouseup, linkNodeMousedown, linkNodeMouseup) {
        const link = this.graphSelection.selectAll('.link').data(this.graph.links)
        const vm = this
        link.enter().insert('g', beforeElement).attr('class', 'marker').append('polygon').attr('points', '14,0 -6,6 -6,-6')
            .attr('style', d => {
                if (!d.directed) return  'display: none;'
                if (action === 'addLink') {
                    return 'fill:#AC3B61'
                }
                if (d.target.element === 'attr') {
                    return 'fill:#999'
                } else if (d.target.element !== 'ontology') {
                    return 'fill:#999'
                }
                if (d.linkType === 'negative') {
                    if (d === vm.selected_link) return 'fill:#AC3B61'
                    return 'fill:#550000'
                } else if (d.linkType === 'exclusive') {
                    if (d === vm.selected_link) return 'fill:#AC3B61'
                    return 'fill:#000'
                } else {
                    if (d === vm.selected_link) return 'fill:#AC3B61'
                    return 'fill:#999'
                }
            })




        link.enter().insert('g', beforeElement).attr('class', 'text-link').append('text')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'black')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => vm.ontology.links[d.name].label)


        link.enter().insert('path', beforeElement)
            .attr('class', 'link')
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('y2', d => d.target.y)
            .attr('style', d => {
                if (action === 'addLink') return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
                if (d.linkType === 'negative') {
                    if (d === vm.selected_link) return 'stroke:#AC3B61;stroke-width:6px;stroke-dasharray:0.5%;fill: none;'
                    return 'stroke:#550000;stroke-width:6px;stroke-dasharray:0.5%;fill: none;'
                } else if (d.linkType === 'exclusive') {
                    if (d === vm.selected_link) return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
                    return 'stroke:#000000;stroke-width:6px;fill: none;'
                } else {
                    if (d === vm.selected_link) return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
                    return 'stroke:#999;stroke-width:6px;fill: none;'
                }
            })
            .on('mousedown', linkMousedown)
            .on('mouseup', linkMouseup)


        let gLink = link.enter().insert('g', beforeElement).attr('class', 'node-link').attr('style', d => {
            if (d.source.element === 'ontology' && d.target.element === 'ontology') return 'display: block;'
            else return 'display: none;'
        })

        gLink.insert('rect').attr('style', d => {
            for (let link of vm.graph.links) {
                if (link.source.index === d.index && link.source.element === 'link') {
                    return 'display: block;' + 'fill:#020202;stroke:white;stroke-width:3px'
                }
            }
            return 'display: none;' + 'fill:#020202;stroke:white;stroke-width:3px'
        })
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 60)
            .attr('height', 20)
            .on('mousedown', linkNodeMousedown)
            .on('mouseup', linkNodeMouseup)

        link.exit().remove()
        gLink.exit().remove()
    }

    createOntology(action, beforeElement, mousedown, mouseup) {
        const node = this.graphSelection.selectAll('.ontology').data(this.graph.nodes)
        const vm = this
        const gNode = node.enter().insert('g', beforeElement).attr('class', 'node ontology')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return ''
            })
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)


        this.graphSelection.append('text')
            .attr('class', 'test-icon')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr("fill","black")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size',  42 + 'px')
            .text('');


        // gNode.insert('image')
        //     .attr('xlink:href', d => {
        //         console.log('DD', d)
        //         if (vm.ontology.nodes[d.name].icon.length > 0) return require('@/assets/' + vm.ontology.nodes[d.name].icon)
        //         else return ''
        //     })
        //     .attr('style', 'pointer-events: none;   ')
        //     .attr('draggable', 'false')
        //     .attr('width', d => {
        //         if (vm.ontology.nodes[d.name].icon.length === 0) return 0
        //         else return 80
        //     })
        //     .attr('height', d => {
        //         if (vm.ontology.nodes[d.name].icon.length === 0) return 0
        //         else return 80
        //     })
        //     .attr('x', -80 / 2)
        //     .attr('y', -80 / 2)

        gNode.insert('circle')
            .attr('class', 'stroke')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:white;stroke-width:6px'
            })
            .attr('r', 40)

        gNode.append('text')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr('x', (d) => {
                let text = vm.graphSelection.select('.test-icon').text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -size.width/2
            })
            .attr('y', (d) => {
                // console.log(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let text = vm.graphSelection.select('.test-icon').text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -(size.height)/2 - size.y - 2
            })
            .attr("fill","white")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', function(d) {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
            else return 42 + 'px'} )
            .text(function(d) {
                if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return ''
                else return String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode))
            });

        this.graphSelection.selectAll('.test-icon').remove()

        gNode.insert('text')
            .attr('class', 'text-value')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                if (vm.ontology.nodes[d.name].icon.unicode.length === 0) {
                    return Utils.cutString(d.name, 6)
                }
                else return ''
            })

        gNode.insert('circle')
            .attr('style', d => {
                return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:none;'
            })
            .attr('r', 0)
            .attr('cx', 80 / 2)
            .attr('cy', 80 / 2)

        gNode.insert('image')
            .attr('xlink:href', require('@/assets/big-anchor.svg'))
            .attr('style', 'pointer-events: none;')
            .attr('draggable', 'false')
            .attr('width', 0)
            .attr('height', 0)
            .attr('x', -18 / 2 + 80 / 2 * Math.cos(Math.PI / 4))
            .attr('y', -18 / 2 + 80 / 2 * Math.cos(Math.PI / 4))
        node.exit()
            .remove()

        return gNode
    }

    createValue(action, beforeElement, mousedown, mouseup) {
        const value = this.graphSelection.selectAll('.value').data(this.graph.values)
        const gValue = value.enter().insert('g', beforeElement).attr('class', 'node value')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return ''
            })
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)

        gValue.append('rect')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
            })
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', d => {
                return -(80 + d.additionalWidth) / 2
            })
            .attr('y', -20)
            .attr('width', d => {
                return 80 + d.additionalWidth
            })
            .attr('height', 40)
            .attr('style', 'fill:#020202;stroke:white;stroke-width:3px')


        this.createInputNode(gValue, 80, 0)
        this.createDataTypeLabel(gValue, 80, -10, function () {
                return 'display: block;'
            },
            function (d) {
                return d.name
            })


        gValue.insert('text')
            .attr('class', 'text-value')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                return d.value
            })
        value.exit()
            .remove()
    }

    createFunction(action, beforeElement, mousedown, mouseup) {
        const functionn = this.graphSelection.selectAll('.function').data(this.graph.functions)
        const vm = this
        const gFunction = functionn.enter().insert('g', beforeElement).attr('class', 'node function')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return ''
            })
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)

        gFunction.append('circle')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                const selected = d
                let selection = vm.graphSelection.selectAll(".function")
                    .filter(function(r) {
                        return r.index === selected.index; });
                if (basic.functions[d.name].joints.length === 0 ) {
                    vm.createComplexInputNode(selection, d,60, 0, '')
                } else {
                    let start = -20
                    let h = (-start*2) / (basic.functions[d.name].joints.length - 1)
                    let step = 0
                    for (let joint of basic.functions[d.name].joints) {
                        let shift = start + h*step
                        step++
                        vm.createComplexInputNode(selection, d, 60, shift, joint)

                    }
                }
                return ''

            })
            .attr('r', 30)
            .attr('style', 'fill:#020202;stroke:white;stroke-width:3px')

        this.createOutputNode(gFunction,60, 0)

        gFunction.insert('text')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                return d.name
            })
        functionn.exit()
            .remove()
    }


    createOperator(action, beforeElement, mousedown, mouseup) {
        const operator = this.graphSelection.selectAll('.operator').data(this.graph.operators)
        const gOperator = operator.enter().insert('g', beforeElement).attr('class', 'node operator')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return ''
            })
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)

        gOperator.append('circle')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
            })
            .attr('r', 30)
            .attr('style', 'fill:#020202;stroke:white;stroke-width:3px')


        this.createInputNode(gOperator, 60, 0)
        this.createOutputNode(gOperator, 60, 0)

        gOperator.insert('text')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                return d.name
            })
        operator.exit()
            .remove()
    }

    createAttribute(action, beforeElement, mousedown, mouseup) {
        const attribute = this.graphSelection.selectAll('.attribute').data(this.graph.attributes)
        const gAttribute = attribute.enter().insert('g', beforeElement).attr('class', 'node attribute')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return ''
            })
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)

        gAttribute.append('polygon')
            .attr('class', 'polygon-body')
            .attr('points', d => {
                return '0,-26 ' + (92 + d.additionalWidth) / 2 + ',0 0,26 -' + (92 + d.additionalWidth) / 2 + ',0'
            })
            .attr('x', 0)
            .attr('y', 0)

        this.createInputNode(gAttribute, 86,0)
        this.createOutputNode(gAttribute, 86, 0)
        this.createDataTypeLabel(gAttribute, 80, -10, function (d) {
                if (d.attribute !== null) {
                    if (d.attribute.name.length !== 0) return 'display: block;'
                    else return 'display: none;'
                } else return 'display: none;'
            },
            function (d) {
                if (d.attribute !== null) return d.attribute.type
                else return ''
            })

        gAttribute.insert('text')
            .attr('class', 'text-value')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                if (d.attribute !== null) return d.attribute.name
                else return ''
            })
        attribute.exit()
            .remove()
    }

    createLogic(action, beforeElement, mousedown, mouseup) {
        const logic = this.graphSelection.selectAll('.logic').data(this.graph.logics)
        const gLogic = logic.enter().insert('g', beforeElement).attr('class', 'node logic')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                return ''
            })
            .on('mousedown', mousedown)
            .on('mouseup', mouseup)

        gLogic.append('rect')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
            })
            .attr('rx', 1)
            .attr('ry', 1)
            .attr('x', -30)
            .attr('y', -30)
            .attr('width', 60)
            .attr('height', 60)
            .attr('style', 'fill:#020202;stroke:white;stroke-width:3px')


        this.createInputNode(gLogic, 60, 0)
        this.createOutputNode(gLogic, 60, 0)

        gLogic.insert('text')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                return d.name
            })
        logic.exit()
            .remove()
    }

    resetMouseVars() {
        const vm = this
        this.mousedown_node = null
        this.mouseup_node = null
        this.selected_node = null
        this.selected_link = null
        this.graphSelection.selectAll('.stroke').attr('style', d => {
            return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:white;stroke-width:6px'
        })
        this.graphSelection.selectAll('.strokeJoint').attr('style', 'pointer-events: fill; fill:white;')
        this.graphSelection.selectAll('.link').attr('style', d => {
            if (d.linkType === 'negative') return 'stroke:#550000;stroke-width:6px;stroke-dasharray:0.5%; opacity: 1;fill: none;'
            if (d.linkType === 'exclusive') return 'stroke:#000000;stroke-width:6px;opacity:1;fill: none;'
            return 'stroke:#999;stroke-width:6px; opacity: 1;fill: none;'
        })
        this.graphSelection.selectAll('.marker').selectAll('polygon').attr('style', d => {
            if (!d.directed) return  'display: none;'
            if (d.linkType === 'negative') return 'fill:#550000'
            else if (d.linkType === 'exclusive') return 'fill:#000'
            else return 'fill:#999'
        })
        this.graphSelection.selectAll('.node').attr('style', 'pointer-events: fill; opacity: 1')
        this.graphSelection.selectAll('.text-link').attr('style', 'pointer-events: fill; opacity: 1')
    }
}