export const ontologyStore = {
    namespaced: true,
    state: {
        ontology: null,
    },
    getters: {
        getOntologyStore: (state) => {
            return state.ontology;
        },

    },
    mutations: {
        setOntologyStore: (state, ontology) => {
            state.ontology = ontology;
        },
    }
};