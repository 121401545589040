import {createStore}  from 'vuex';

import { auth } from './auth.module';
import { schemeStore } from './scheme.module';
import { cartStore } from './cart.module';
import { ontologyStore } from "./ontology.module";
import {sessionStore} from "./session.store";
import {cashStore} from "./cash.store";


export const store = createStore({
  modules: {
    auth,
    schemeStore,
    cartStore,
    ontologyStore,
    sessionStore,
    cashStore
  }
});
