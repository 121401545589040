export const sessionStore = {
    namespaced: true,
    state: {
        currentSession: null,
        allSessions: null
    },
    getters: {
        getCurrentSession: (state) => {
            return state.currentSession;
        },
        getSessionByCaseId: (state) => (caseId) => {
            for (let i = 0; i < state.allSessions.length; i++) {
                if (state.allSessions[i].caseId === caseId) {
                    return state.allSessions[i];
                }
            }
            return state.currentSession;
        },
        getAllSessions: (state) => {
            return state.allSessions;
        },
        getAllSessionsActive: (state) => {
            let temp = []
            for (let i = 0; i < state.allSessions.length; i++) {
                if (state.allSessions[i].finished === false){
                    temp.push(state.allSessions[i])
                }
            }
            return temp;
        }

    },
    mutations: {
        setCurrentSession: (state, currentSession) => {
            state.currentSession = currentSession;
        },
        sessionAddLog: (state, log) => {
            state.currentSession.log.data.push(log);
        },
        sessionAddLogAllElements: (state) => {
            state.currentSession.log.allElements++;
        },
        sessionAddLogCurrentElements: (state, count) => {
            state.currentSession.log.currentElements = count;
        },
        sessionAddLogQuery: (state) => {
            state.currentSession.log.queriesAll++;
        },
        sessionAddLogCart: (state, count) => {
            state.currentSession.log.inCart = count;
        },
        sessionAddAnswer: (state, answer) => { 
            state.currentSession.answer = answer;
            state.currentSession.finished = true;
            state.currentSession.finishDate = Date.now();
        },
        setAllSessions: (state, allSessions) => {
            state.allSessions = allSessions;
        },
        setStateCart: (state, cart) => {
            state.currentSession.state.cart = cart;
        },
        addSession: (state, newSession) => {
            state.allSessions.push(newSession)
        },
        sessionAddShemeById: (state, shemeId) => {
            state.currentSession.schemes.push(shemeId);
        },
        SessiondeleteShemebyId: (state, shemeId) => {
            state.currentSession.schemes = state.currentSession.schemes.filter(e => e !== shemeId)
        },
        deleteSessionById: (state, sessionId) => {
            let temp = []
            for (let i = 0; i < state.allSessions.length; i++) {
                if (state.allSessions[i]._id !== sessionId){
                    temp.push(state.allSessions[i]);
                }

            }
            state.allSessions = temp
        }


        }
    };
