import config from "../../config";
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = config.API + '/caseSession';

class CaseSessionService {
//
    allCasesSessions() {
        return axios({
            method: 'get',
            url: API_URL,
            config :{ headers: authHeader() }
        })

    }

    caseSessionByUserId(userId) {
        return axios({
            method: 'get',
            url: API_URL + '/getByUserId/' + userId
            ,
            config :{ headers: authHeader() }
        })
    }

    createCaseSession(userId, data) {

      // console.log('создаю сессию со скрытым айдишником',data.caseNumberToHide)

        return axios({
            data: data,
            method: 'put',
            url: API_URL + '/' + userId
            ,
            config :{ headers: authHeader() }
        })
    }

    updateCaseSession(data) {
        return axios({
            data: data,
            method: 'post',
            url: API_URL + '/' + data._id
            ,
            config :{ headers: authHeader() }
        })
    }

    getTribuna() {
        return axios({
            method: 'get',
            url: API_URL + '/' + 'tribuna'
            ,
            config :{ headers: authHeader() }
        })
    }

    getCases() {
        return axios({
            method: 'get',
            url: API_URL + '/' + 'tribuna/cases'
            ,
            config :{ headers: authHeader() }
        })
    }

    getCaseById(caseId) {
        return axios({
            method: 'get',
            url: API_URL + '/' + 'tribuna/cases/'+caseId
            ,
            config :{ headers: authHeader() }
        })
    }

    deleteCaseSession(gameSessionId) {
        return axios({
            method: 'delete',
            url: API_URL + '/' + gameSessionId
            ,
            config :{ headers: authHeader() }
        })
    }

}

export default new CaseSessionService();
