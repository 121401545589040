<template>
    <div class="col-md-12">
        <div class="card card-container">
            <img
                    id="profile-img"
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    class="profile-img-card"
            />
            <el-form :model="user" :rules="rules" ref="form" @submit.prevent="handleRegister">
                <el-form-item :label="$t('Имя')" prop="username">
                    <el-input v-model="user.username"
                              id="username"
                              type="text"></el-input>
                </el-form-item>
                <el-form-item label="E-mail" prop="email">
                    <el-input v-model="user.email"
                              id="email"
                              type="email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Пароль')" prop="password">
                    <el-input v-model="user.password"
                              id="password"
                              type="password"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Страна')" prop="country">
                    <el-select v-model="user.country"
                               :no-match-text="$t('Ничего не найдено')"
                               value-key="2"
                               filterable
                               style="margin-right: 8px"
                               :placeholder="$t('Выберите страну')">
                        <el-option
                                v-for="item in countriesList"
                                :key="item['2']"
                                :label="$t(item['4'])"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>


              <el-form-item :label="$t('Язык')" prop="lang">
                <el-select v-model="user.lang"
                           :no-match-text="$t('Ничего не найдено')"
                           filterable
                           style="margin-right: 8px"
                           :placeholder="$t('Выберите язык')">
                  <el-option
                      v-for="language in langOptions"
                      :key="language.label"
                      :label="language.label"
                      :value="language.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

                <div class="form-group">
                    <button class="btn btn-primary btn-block">
                        <span>{{ $t('Зарегистрироваться') }}</span>
                    </button>
                </div>
            </el-form>
            <div
                    v-if="message"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
            >{{message}}
            </div>
        </div>
    </div>
</template>

<script>
    import User from '../../models/user';
    import ProfileController from "../../controllers/profile.controller";
    import { availableLanguages } from '@/i18n/i18n';
    export default {
        name: 'Register',
        data() {
            return {
                user: new User('', '', '', ''),
                submitted: false,
                successful: false,
                message: '',
                rules: {
                    username: [
                        {required: true, message: this.$t('Введите имя'), trigger: 'blur'},
                        {min: 3, max: 5000, message: this.$t('Минимально 3 символа'), trigger: 'blur'}
                    ],
                    email: [
                        {required: true, message: this.$t('Введите корректный email'), trigger: 'blur'},
                        {min: 0, max: 50, message: this.$t('Максимально 50 символов'), trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: this.$t('Введите пароль'), trigger: 'change'},
                        {min: 6, max: 5000, message: this.$t('Минимально 6 символов'), trigger: 'blur'}
                    ]
                },
                countriesList: null,
                profileController: null,
                langOptions: availableLanguages
            };
        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        mounted() {
            if (this.loggedIn) {
                this.$router.push('/profile');
            }
            this.profileController = new ProfileController();
            this.profileController.getCountries().then((response) => {
                this.countriesList = response.data.data
                for (let i = 0; i < this.countriesList.length; i++) {
                    if (this.countriesList[i]['0'] == 'RU') {
                        this.user.country = this.countriesList[i];
                    }
                }
            })
        },
        methods: {
            handleRegister() {
                this.message = '';
                this.submitted = true;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('auth/register', this.user).then(
                            response => {
                                this.message = response.message;
                                this.successful = true;
                                if (this.loggedIn) {
                                    this.handleLogin_new()
                                }
                            },
                            error => {
                                this.message =
                                    (error.response && error.response.data) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                            },
                        )

                    } else {
                        return false;
                    }
                });
            },
            handleLogin_new() {
                this.loading = true;
                if (this.user.username && this.user.password) {
                    this.$store.dispatch('auth/login', this.user).then(
                        () => {
                            window.location.href = '/profile';
                        },
                        error => {
                            this.loading = false;
                            this.message =
                                (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            }
        }
    };
</script>

<style scoped>
    label {
        display: block;
        margin-top: 10px;
    }

    .card-container.card {
        max-width: 350px !important;
        padding: 40px 40px;
    }

    .card {
        background-color: #f7f7f7;
        padding: 20px 25px 30px;
        margin: 0 auto 25px;
        margin-top: 50px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }

    .profile-img-card {
        width: 96px;
        height: 96px;
        margin: 0 auto 10px;
        display: block;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
    }
</style>
