import CaseSessionService from "../services/caseSession.service";
import { store } from "../store";

export default class CaseSessionController {
  constructor() {}

  veildDataForSpecificCase() {
    //отправить сессию
  }

  allCasesSessions() {
    return CaseSessionService.allCasesSessions();
  }

  getTribuna() {
    return CaseSessionService.getTribuna();
  }

  getCases() {
    return CaseSessionService.getCases();
  }

  getCaseById(caseId) {
    return CaseSessionService.getCaseById(caseId);
  }

  async setCurrentSessionStore(session) {
    store.commit("sessionStore/setCurrentSession", session);
    if (session !== null) this.updateCaseSession(this.getCurrentSessionStore());
  }

  setAllSessionsStore(sessions) {
    store.commit("sessionStore/setCurrentSession", sessions);
  }

  getAllActiveSessionsStore() {
    return store.getters["sessionStore/getAllSessionsActive"];
  }

  deleteSessionByIdStore(id) {
    store.commit("sessionStore/deleteSessionById", id);
  }

  getAllSessionsStore() {
    return store.getters["sessionStore/getAllSessions"];
  }

  getCurrentSessionStore() {
    return store.getters["sessionStore/getCurrentSession"];
  }

  getSessionByCaseIdStore(id) {
    return store.getters["sessionStore/getSessionByCaseId"](id);
  }

  async addLogStore(action, name) {
    store.commit("sessionStore/sessionAddLog", {
      action: action,
      name: name,
      time: Date.now()
    });
    this.updateCaseSession(this.getCurrentSessionStore());
  }

  async addLogCartStore(len) {
    store.commit("sessionStore/sessionAddLogCart", len);
    this.updateCaseSession(this.getCurrentSessionStore());
  }

  async addLogCurrentElementsStore(counter) {
    store.commit("sessionStore/sessionAddLogCurrentElements", counter);
    this.updateCaseSession(this.getCurrentSessionStore());
  }

  async addAnswer(answer) {
    store.commit("sessionStore/sessionAddAnswer", answer);
    this.updateCaseSession(this.getCurrentSessionStore());
  }

  caseSessionByUserId(userId) {
    return CaseSessionService.caseSessionByUserId(userId).then(function(
      response
    ) {
      store.commit("sessionStore/setAllSessions", response.data);
      return response.data;
    });
  }

  createCaseSession(userId, data) {
    return CaseSessionService.createCaseSession(userId, data).then(response => {
      console.log("created session=", response.data);
    });
  }

  async updateCaseSession(currentSession) {
    // console.log("currentSession", currentSession);
    return CaseSessionService.updateCaseSession(currentSession);
  }

  deleteCaseSession(gameSessionId) {
    return CaseSessionService.deleteCaseSession(gameSessionId);
  }

  async createAdminCaseSession(adminStatus) {
    if (
      adminStatus &&
      store.getters["ontologyStore/getOntologyStore"] !== null
    ) {
      const adminCase = {
        html: "", // строка html кода
        aims: "[]", // список ID узлов
        participants: [store.state.auth.user.id], // список ID юзеров
        name: "adminSession " + store.state.auth.user.username, // имя кейсы
        difficulty: 0, // сложность 1-5, число?
        spread_: 0, // распространенность 1-5, число?
        duration: 0, // длительность в минутах, число
        date: "", // дата создания
        status: true, //true - Расследование стартовала
        adminSession: true,
        ontologyId: store.getters["ontologyStore/getOntologyStore"].id,
        caseId: "adminCase"
      };
      return CaseSessionService.createCaseSession(
        store.state.auth.user.id,
        adminCase
      ).then(response => {
        store.commit("sessionStore/setCurrentSession", response.data);
        console.log(
          "created Admin Session[store]=",
          store.getters["sessionStore/getCurrentSession"]
        );
      });
    } else return false;
  }
}