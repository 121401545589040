const faIconChars = require('font-awesome-icon-chars');
const {solid} = faIconChars;

class Utils {



    fontAwesomeToUnicode(str) {
        let char = str.replace(/f.. fa-/g, '')
        for (let icon of solid) {
            if (icon.name === char) {
                return icon.unicode
            }
        }
    }

    testNumber(str) {
        str = str.toString()
        let replaceStr = str.replace(/[0-9]/g, '').replace('.', '')
        if (replaceStr.length > 0) return null
        if (str.includes('.')) {
            str = str.replace(/,/g, '.')
            return parseFloat(str)
        } else {
            return parseInt(str)
        }
    }

    randomColor() {
        let end = 80
        let r = Math.floor(Math.random() * (255 - end)).toString(16)
        let g = Math.floor(Math.random() * (255 - end)).toString(16)
        let b = Math.floor(Math.random() * (255 - end)).toString(16)
        if (r.length === 1) r = r + Math.floor(Math.random() * 11).toString(16)
        if (g.length === 1) g = g + Math.floor(Math.random() * 11).toString(16)
        if (b.length === 1) b = b + Math.floor(Math.random() * 11).toString(16)
        let color = "#" + r + g + b
        return color
    }

    arraysEqual(a, b) {
        if (a === b) return true
        if (a == null || b == null) return false
        if (a.length !== b.length) return false
        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false
        }
        return true
    }

    cutString(str, len) {
        if (str.length >= len + 2) return str.slice(0, len) + '...'
        else return str
    }

    hslToRgb(h, s, l) {
        let r, g, b
        h /= 360
        s /= 100
        l /= 100
        if (s === 0) {
            r = g = b = l // achromatic
        } else {
            var hue2rgb = function hue2rgb(p, q, t) {
                if (t < 0) t += 1
                if (t > 1) t -= 1
                if (t < 1 / 6) return p + (q - p) * 6 * t
                if (t < 1 / 2) return q
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
                return p
            }

            let q = l < 0.5 ? l * (1 + s) : l + s - l * s
            let p = 2 * l - q
            r = hue2rgb(p, q, h + 1 / 3)
            g = hue2rgb(p, q, h)
            b = hue2rgb(p, q, h - 1 / 3)
        }
        const toHex = x => {
            const hex = Math.round(x * 255).toString(16)
            return hex.length === 1 ? '0' + hex : hex
        }
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`
    }

     isEqual(object1, object2) {
        const props1 = Object.getOwnPropertyNames(object1);
        const props2 = Object.getOwnPropertyNames(object2);
      
        if (props1.length !== props2.length) {
          return false;
        }
      
        for (let i = 0; i < props1.length; i += 1) {
          const prop = props1[i];
          const bothAreObjects = typeof(object1[prop]) === 'object' && typeof(object2[prop]) === 'object';
      
          if ((!bothAreObjects && (object1[prop] !== object2[prop]))
          || (bothAreObjects && !this.isEqual(object1[prop], object2[prop]))) {
            return false;
          }
        }
      
        return true;
      }

}

export default new Utils();