export const ru = {
    "Email изменен": "Email изменен",
    "Активность": "Активность",
    "Активных сессий нет": "Активных сессий нет",
    "Архив": "Архив",
    "Атрибут": "Атрибут",
    "базу загрузил": "базу загрузил",
    "Банк": "Банк",
    "Без направления": "Без направления",
    "Больше": "Больше",
    "Больше или равно": "Больше или равно",
    "В избранное": "В избранное",
    "В избранном": "В избранном",
    "Введите": "Введите",
    "Введите имя": "Введите имя",
    "Введите имя схемы": "Введите имя схемы",
    "Введите корректный": "Введите корректный",
    "Введите корректный email": "Введите корректный email",
    "Введите новый пароль": "Введите новый пароль",
    "Введите пароль": "Введите пароль",
    "Введите текст кейса": "Введите текст кейса",
    "Величина ребра": "Величина ребра",
    "Версия": "Версия",
    "Вернуть тип": "Вернуть тип",
    "визуализатор схем": "визуализатор схем",
    "Визуализация онтологии": "Визуализация онтологии",
    "Визуализировать": "Визуализировать",
    "Войти": "Войти",
    "Вперед": "Вперед",
    "Время": "Время",
    "Все": "Все",
    "Все атрибуты": "Все атрибуты",
    "Все связи": "Все связи",
    "Все узлы": "Все узлы",
    "Всего запросов": "Всего запросов",
    "Вход": "Вход",
    "Входящие": "Входящие",
    "Вхождение строки": "Вхождение строки",
    "Вы уверены, что хотите удалить схему": "Вы уверены, что хотите удалить схему?",
    "Выберете источник": "Выберете источник",
    "Выберете связь": "Выберете связь",
    "Выберите атрибуты": "Выберите атрибуты",
    "Выберите аттрибут": "Выберите аттрибут",
    "Выберите значение": "Выберите значение",
    "Выберите папку": "Выберите папку",
    "Выберите роль": "Выберите роль",
    "Выберите свойство": "Выберите свойство",
    "Выберите связь": "Выберите связь",
    "Выберите страну": "Выберите страну",
    "Выберите файлы": "Выберите файлы",
    "Выбор иконки": "Выбор иконки",
    "Выбрать": "Выбрать",
    "Выгружено": "Выгружено",
    "Выполнено": "Выполнено",
    "Выход": "Выход",
    "Вычитание": "Вычитание",
    "Главная": "Главная",
    "Граф": "Граф",
    "Граф должен быть связанным": "Граф должен быть связанным",
    "Граф не связан": "Граф не связан",
    "Граф по пути": "Граф по пути",
    "Графовый запрос": "Графовый запрос",
    "Графус": "Графус",
    "Да": "Да",
    "ДА ВСЕ НОРМ": "ДА ВСЕ НОРМ",
    "Данное действие удалит весь прогресс конструирования кейса. Продолжить?": "Данное действие удалит весь прогресс конструирования кейса. Продолжить?",
    "Данные успешно обновлены": "Данные успешно обновлены",
    "Данные успешно отредактированы": "Данные успешно отредактированы",
    "Дата": "Дата",
    "Дата изменения": "Дата изменения",
    "Дата создания": "Дата создания",
    "Деление": "Деление",
    "длительность в минутах, число": "длительность в минутах, число",
    "Добавить": "Добавить",
    "Добавить атрибут": "Добавить атрибут",
    "Добавить из избранного": "Добавить из избранного",
    "Добавить связь": "Добавить связь",
    "Добавить узел": "Добавить узел",
    "Добавление в избранное": "Добавление в избранное",
    "Добавление связи": "Добавление связи",
    "Добавление схемы": "Добавление схемы",
    "Добавление узла": "Добавление узла",
    "Добавьте хотя бы 1 цель": "Добавьте хотя бы 1 цель",
    "Добро пожаловать в Графус": "Добро пожаловать в Графус",
    "Дополнительный запрос": "Дополнительный запрос",
    "Другое": "Другое",
    "Завершить кейс и отправить ответ": "Завершить кейс и отправить ответ",
    "Загрузка": "Загрузка",
    "Задайте уровень распространенности": "Задайте уровень распространенности",
    "Задайте уровень сложности": "Задайте уровень сложности",
    "Закрыть": "Закрыть",
    "Запрос": "Запрос",
    "Запрос по": "Запрос по",
    "Зарегистрироваться": "Зарегистрироваться",
    "игрок": "игрок",
    "из": "из",
    "Из избранного": "Из избранного",
    "Избранное": "Избранное",
    "изменен": "изменен",
    "Изменение пароля": "Изменение пароля",
    "Изменить": "Изменить",
    "Изменить направление": "Изменить направление",
    "Имя": "Имя",
    "Имя изменено": "Имя изменено",
    "Имя новой онтологии": "Имя новой онтологии",
    "Имя папки": "Имя папки",
    "Имя пользователя": "Имя пользователя",
    "Имя файла": "Имя файла",
    "Инструкция": "Инструкция",
    "Инструмент": "Инструмент",
    "Инструмент ‘Граф‘": "Инструмент ‘Граф‘",
    "Инструмент Граф по пути‘": "Инструмент Граф по пути‘",
    "Инструмент ‘Карта‘": "Инструмент ‘Карта‘",
    "Инструмент ‘Список‘": "Инструмент ‘Список‘",
    "Информация": "Информация",
    "Информация о пользователе": "Информация о пользователе",
    "Исходящие": "Исходящие",
    "Ищет по базе": "Ищет по базе",
    "К атрибуту": "К атрибуту",
    "К значению": "К значению",
    "К логической функции": "К логической функции",
    "К оператору": "К оператору",
    "К функции": "К функции",
    "Как второй": "Как второй",
    "Как первый": "Как первый",
    "Карта": "Карта",
    "Кейс": "Кейс",
    "Кейсы": "Кейсы",
    "Кол-во действий": "Кол-во действий",
    "Кол-во минут": "Кол-во минут",
    "Кол-во секунд": "Кол-во секунд",
    "Конструктор": "Конструктор",
    "конструктор схем": "конструктор схем",
    "Логический": "Логический",
    "Любой атрибут": "Любой атрибут",
    "Любой тип": "Любой тип",
    "Максимально 50 символов": "Максимально 50 символов",
    "Массив": "Массив",
    "Меньше": "Меньше",
    "Меньше или равно": "Меньше или равно",
    "мин": "мин",
    "Минимально 3 символа": "Минимально 3 символа",
    "Минимально 6 символов": "Минимально 6 символов",
    "минут": "минут",
    "Мои кейсы": "Мои кейсы",
    "МУМЦФМ": "МУМЦФМ",
    "Назад": "Назад",
    "Назад к кейсам": "Назад к кейсам",
    "Название": "Название",
    "Название организации": "Название организации",
    "Название сессии": "Название сессии",
    "Название схемы": "Название схемы",
    "Название успешно изменено": "Название успешно изменено",
    "Название элемента": "Название элемента",
    "Название элемента в базе": "Название элемента в базе",
    "Направление": "Направление",
    "Настройка системы": "Настройка системы",
    "Настройки": "Настройки",
    "Настройки пользователей": "Настройки пользователей",
    "Настройки системы": "Настройки системы",
    "Находится в конструкторе": "Находится в конструкторе",
    "Не верно составлен запрос": "Не верно составлен запрос",
    "не выбрана": "не выбрана",
    "Не удалось зарегистрироваться. Имя пользователя уже используется": "Не удалось зарегистрироваться. Имя пользователя уже используется",
    "не удалось подключиться": "не удалось подключиться",
    "Нет": "Нет",
    "Нет атрибутов": "Нет атрибутов",
    "Нет данных": "Нет данных",
    "Нет результатов": "Нет результатов",
    "Ничего не найдено": "Ничего не найдено",
    "Новая схема": "Новая схема",
    "Новый кейс": "Новый кейс",
    "Новый пользователь зарегистрирован": "Новый пользователь зарегистрирован",
    "Область": "Область",
    "Обновить": "Обновить",
    "Обрезать": "Обрезать",
    "Обучающая система по проведению финансовых расследований для сотрудников финансовых разведок": "Обучающая система по проведению финансовых расследований для сотрудников финансовых разведок",
    "Обход графа": "Обход графа",
    "Общий": "Общий",
    "Объём определён": "Объём определён",
    "Ололош": "Ололош",
    "Онтология": "Онтология",
    "Описание": "Описание",
    "Остановите игру перед тем, как редактировать кейс": "Остановите игру перед тем, как редактировать кейс",
    "Остановите игру перед тем, как отправлять кейс в архив": "Остановите игру перед тем, как отправлять кейс в архив",
    "Остановите игру перед тем, как удалять кейс": "Остановите игру перед тем, как удалять кейс",
    "Ответ": "Ответ",
    "ответ": "ответ",
    "Ответ по умолчанию": "Ответ по умолчанию",
    "Отдалить": "Отдалить",
    "Отмена": "Отмена",
    "Отображаемое название": "Отображаемое название",
    "Отправил ответ": "Отправил ответ",
    "Отправить": "Отправить",
    "Ошибка": "Ошибка",
    "Ошибка во время изменения данных": "Ошибка во время изменения данных",
    "Ошибка во время создания папки": "Ошибка во время создания папки",
    "Ошибка во время создания файла": "Ошибка во время создания файла",
    "Ошибка во время удаления": "Ошибка во время удаления",
    "Ошибка заполнения": "Ошибка заполнения",
    "Ошибка при создании папки": "Ошибка при создании папки",
    "Ошибка при создании файла": "Ошибка при создании файла",
    "Ошибка удаления": "Ошибка удаления",
    "Папка была удалена": "Папка была удалена",
    "Параметры": "Параметры",
    "параметры папки": "параметры папки",
    "Параметры тени": "Параметры тени",
    "Пароли не совпадают": "Пароли не совпадают",
    "Пароли не совпадают!": "Пароли не совпадают!",
    "Пароль": "Пароль",
    "Пароль изменен": "Пароль изменен",
    "Пишет ответ": "Пишет ответ",
    "По ID": "По ID",
    "Повернуть": "Повернуть",
    "Повторите новый пароль": "Повторите новый пароль",
    "Подграф": "Подграф",
    "Подграфы": "Подграфы",
    "Подграфы не найдены": "Подграфы не найдены",
    "Подробнее": "Подробнее",
    "Подтвердить": "Подтвердить",
    "Пожалуйста, назовите онтологию": "Пожалуйста, назовите онтологию",
    "Поиск": "Поиск",
    "Поиск в таблице": "Поиск в таблице",
    "Поиск по базе": "Поиск по базе",
    "Поиск пользователя": "Поиск пользователя",
    "поисковая строка": "поисковая строка",
    "Показать": "Показать",
    "Показать все надписи": "Показать все надписи",
    "Показать выделенное": "Показать выделенное",
    "Показать сессию": "Показать сессию",
    "Поле не может быть пустым": "Поле не может быть пустым",
    "Получение координат": "Получение координат",
    "Пользователи": "Пользователи",
    "Построение связи": "Построение связи",
    "Почта": "Почта",
    "Предупреждение": "Предупреждение",
    "Приблизить": "Приблизить",
    "Применить": "Применить",
    "Прогресс успешно удален": "Прогресс успешно удален",
    "Продолжительность": "Продолжительность",
    "Продолжительность (мин)": "Продолжительность (мин)",
    "Произошла ошибка во время удаления": "Произошла ошибка во время удаления",
    "Просматривает задание": "Просматривает задание",
    "Просмотр связей": "Просмотр связей",
    "Пустой": "Пустой",
    "Равно": "Равно",
    "Раскладка": "Раскладка",
    "Раскраска": "Раскраска",
    "Раскрытие узла": "Раскрытие узла",
    "Раскрыть": "Раскрыть",
    "Расследование": "Расследование",
    "Редактировать": "Редактировать",
    "Результаты": "Результаты",
    "Роли": "Роли",
    "Россия": "Россия",
    "Сброс": "Сброс",
    "Сбросить": "Сбросить",
    "Связей": "Связей",
    "связей": "связей",
    "Связи": "Связи",
    "Связи через одного": "Связи через одного",
    "Связь": "Связь",
    "Связь с": "Связь с",
    "Сделать пустым": "Сделать пустым",
    "сек": "сек",
    "Сложение": "Сложение",
    "Создано элементов": "Создано элементов",
    "Создать": "Создать",
    "Создать из папки": "Создать из папки",
    "Создать из текущей бд": "Создать из текущей бд",
    "Соседи": "Соседи",
    "Соседние связи": "Соседние связи",
    "Составление графа": "Составление графа",
    "Сохранение схемы": "Сохранение схемы",
    "Сохранено схем": "Сохранено схем",
    "Сохранить": "Сохранить",
    "Сохранить схему": "Сохранить схему",
    "сохранить файлы в папку": "сохранить файлы в папку",
    "Список": "Список",
    "Список схем": "Список схем",
    "Ссылка на чат (Telegram или другой мессенджер на усмотрение)": "Ссылка на чат (Telegram или другой мессенджер на усмотрение)",
    "статей": "статей",
    "Степень": "Степень",
    "Страна": "Страна",
    "Страна изменена": "Страна изменена",
    "Страниц": "Страниц",
    "строк": "строк",
    "Строка": "Строка",
    "Схема": "Схема",
    "Схема пуста": "Схема пуста",
    "Схема сохранена": "Схема сохранена",
    "Схема удалена": "Схема удалена",
    "Сюда прилетают ноды, нужно компонент с выбором знача/имени как в файлах с": "Сюда прилетают ноды, нужно компонент с выбором знача/имени как в файлах с",
    "Таких связей несколько": "Таких связей несколько",
    "Текст": "Текст",
    "Текущие кейсы": "Текущие кейсы",
    "Текущие расследования": "Текущие расследования",
    "Текущие сессии": "Текущие сессии",
    "Тип": "Тип",
    "Удаление из избранного": "Удаление из избранного",
    "Удаление схемы": "Удаление схемы",
    "Удаление элемента": "Удаление элемента",
    "Удаление элемента графа": "Удаление элемента графа",
    "Удалить": "Удалить",
    "Удалить папку": "Удалить папку",
    "Удалить узел": "Удалить узел",
    "Узел": "Узел",
    "Узел удален": "Узел удален",
    "узлов и": "узлов и",
    "Узлы": "Узлы",
    "Умножение": "Умножение",
    "Уникальный элемент уже добавлен": "Уникальный элемент уже добавлен",
    "Управление": "Управление",
    "Управление пользователями": "Управление пользователями",
    "Успешно": "Успешно",
    "Участники": "Участники",
    "Файл успешно создан": "Файл успешно создан",
    "ФИАН": "ФИАН",
    "Фильтр": "Фильтр",
    "Форма для свободного ответа": "Форма для свободного ответа",
    "форма запроса": "форма запроса",
    "Цвет": "Цвет",
    "Цели": "Цели",
    "ч": "ч",
    "чат": "чат",
    "Человек": "Человек",
    "Через одного": "Через одного",
    "Число": "Число",
    "Шаблон для формирования текста запроса": "Шаблон для формирования текста запроса",
    "Шаблон для формирования текста ответа": "Шаблон для формирования текста ответа",
    "Шаг 1. Вы находитесь в системе обучения финансовым расследованиям Графус": "Шаг 1. Вы находитесь в системе обучения финансовым расследованиям Графус",
    "Шаг 2. Зайдите в свой профиль и выберите вкладку": "Шаг 2. Зайдите в свой профиль и выберите вкладку",
    "Шаг 2. Зайдите в свой профиль и выберите вкладку ‘Мои кейсы‘": "Шаг 2. Зайдите в свой профиль и выберите вкладку ‘Мои кейсы‘",
    "Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм": "Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм",
    "Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм чат": "Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм чат",
    "Шаг 4. После старта кейса будет создана аналитическая сессия": "Шаг 4. После старта кейса будет создана аналитическая сессия",
    "Шаг 4. После старта кейса будет создана аналитическая сессия Расследование": "Шаг 4. После старта кейса будет создана аналитическая сессия Расследование",
    "Шаг 5. Во вкладке расследование вам представлено 3 аналитических инстурмента": "Шаг 5. Во вкладке расследование вам представлено 3 аналитических инстурмента",
    "Шаг 6. Используя аналитические инструменты добавьте в избранное сущности, подходящие под ответ кейса": "Шаг 6. Используя аналитические инструменты добавьте в избранное сущности, подходящие под ответ кейса",
    "Шаг 7. Сформируйте ответ и завершите кейс. Ваш результат будет отображен на табло администратора": "Шаг 7. Сформируйте ответ и завершите кейс. Ваш результат будет отображен на табло администратора",
    "Элементов сейчас": "Элементов сейчас",
    "По": "По",
    "Обучающая система по проведению финансовых расследований": "Обучающая система по проведению финансовых расследований",
    "Страниц {pages} из {total}": "Страниц {pages} из {total}",
    "Язык": "Язык",
    "Андорра": "Андорра",
    "Объединенные Арабские Эмираты": "Объединенные Арабские Эмираты",
    "Афганистан": "Афганистан",
    "Антигуа и Барбуда": "Антигуа и Барбуда",
    "Ангилья": "Ангилья",
    "Албания": "Албания",
    "Армения": "Армения",
    "Антильские острова": "Антильские острова",
    "Ангола": "Ангола",
    "Антарктика": "Антарктика",
    "Аргентина": "Аргентина",
    "Восточное Самоа (США)": "Восточное Самоа (США)",
    "Австрия": "Австрия",
    "Австралия": "Австралия",
    "Аруба": "Аруба",
    "Аландские острова": "Аландские острова",
    "Азербайджан": "Азербайджан",
    "Босния и Герцеговина": "Босния и Герцеговина",
    "Барбадос": "Барбадос",
    "Бангладеш": "Бангладеш",
    "Бельгия": "Бельгия",
    "Буркина-Фасо": "Буркина-Фасо",
    "Болгария": "Болгария",
    "Бахрейн": "Бахрейн",
    "Бурунди": "Бурунди",
    "Бенин": "Бенин",
    "Сен-Бартелеми (Карибы)": "Сен-Бартелеми (Карибы)",
    "Бермудские острова": "Бермудские острова",
    "Бруней": "Бруней",
    "Боливия": "Боливия",
    "Бонэйр, Синт-Эстатиус и Саба": "Бонэйр, Синт-Эстатиус и Саба",
    "Бразилия": "Бразилия",
    "Багамские острова": "Багамские острова",
    "Бутан": "Бутан",
    "Буве": "Буве",
    "Ботсвана": "Ботсвана",
    "Беларусь": "Беларусь",
    "Белиз": "Белиз",
    "Канада": "Канада",
    "Кокосовые острова": "Кокосовые острова",
    "Демократическая Республика Конго": "Демократическая Республика Конго",
    "Центрально-Африканская Республика (ЦАР)": "Центрально-Африканская Республика (ЦАР)",
    "Конго": "Конго",
    "Швейцария": "Швейцария",
    "Кот-д'Ивуар": "Кот-д'Ивуар",
    "Острова Кука": "Острова Кука",
    "Чили": "Чили",
    "Камерун": "Камерун",
    "Китай": "Китай",
    "Колумбия": "Колумбия",
    "Коста-Рика": "Коста-Рика",
    "Куба": "Куба",
    "Кабо-Верде": "Кабо-Верде",
    "Кюрасао": "Кюрасао",
    "Остров Рождества": "Остров Рождества",
    "Кипр": "Кипр",
    "Чешская Республика": "Чешская Республика",
    "Германия": "Германия",
    "Джибути": "Джибути",
    "Дания": "Дания",
    "Доминика": "Доминика",
    "Доминиканская Республика": "Доминиканская Республика",
    "Алжир": "Алжир",
    "Эквадор": "Эквадор",
    "Эстония": "Эстония",
    "Египет": "Египет",
    "Западная Сахара": "Западная Сахара",
    "Эритрея": "Эритрея",
    "Испания": "Испания",
    "Эфиопия": "Эфиопия",
    "Финляндия": "Финляндия",
    "Фиджи": "Фиджи",
    "Фолклендские острова": "Фолклендские острова",
    "Микронезия": "Микронезия",
    "Фарерские острова": "Фарерские острова",
    "Франция": "Франция",
    "Габон": "Габон",
    "Великобритания": "Великобритания",
    "Гренада": "Гренада",
    "Грузия": "Грузия",
    "Гвиана": "Гвиана",
    "Гернси": "Гернси",
    "Гана": "Гана",
    "Гибралтар": "Гибралтар",
    "Гренландия": "Гренландия",
    "Гамбия": "Гамбия",
    "Гвинея": "Гвинея",
    "Гваделупа": "Гваделупа",
    "Экваториальная Гвинея": "Экваториальная Гвинея",
    "Греция": "Греция",
    "Южная Георгия и Южные Сандвичевы острова": "Южная Георгия и Южные Сандвичевы острова",
    "Гватемала": "Гватемала",
    "Гуам": "Гуам",
    "Гвинея-Бисау": "Гвинея-Бисау",
    "Гайана": "Гайана",
    "Гонконг": "Гонконг",
    "Остров Херд и острова Макдональд": "Остров Херд и острова Макдональд",
    "Гондурас": "Гондурас",
    "Хорватия": "Хорватия",
    "Гаити": "Гаити",
    "Венгрия": "Венгрия",
    "Индонезия": "Индонезия",
    "Ирландия": "Ирландия",
    "Израиль": "Израиль",
    "Остров Мэн": "Остров Мэн",
    "Индия": "Индия",
    "Британская Территория в Индийском Океане": "Британская Территория в Индийском Океане",
    "Ирак": "Ирак",
    "Иран": "Иран",
    "Исландия": "Исландия",
    "Италия": "Италия",
    "Джерси": "Джерси",
    "Ямайка": "Ямайка",
    "Иордания": "Иордания",
    "Япония": "Япония",
    "Кения": "Кения",
    "Кыргызстан": "Кыргызстан",
    "Камбоджа": "Камбоджа",
    "Кирибати": "Кирибати",
    "Коморские острова": "Коморские острова",
    "Сент-Китс и Невис": "Сент-Китс и Невис",
    "Северная Корея (КНДР)": "Северная Корея (КНДР)",
    "Южная Корея": "Южная Корея",
    "Кувейт": "Кувейт",
    "Каймановы острова": "Каймановы острова",
    "Казахстан": "Казахстан",
    "Лаос": "Лаос",
    "Ливан": "Ливан",
    "Сент-Люсия": "Сент-Люсия",
    "Лихтенштейн": "Лихтенштейн",
    "Шри-Ланка": "Шри-Ланка",
    "Либерия": "Либерия",
    "Лесото": "Лесото",
    "Литва": "Литва",
    "Люксембург": "Люксембург",
    "Латвия": "Латвия",
    "Ливия": "Ливия",
    "Марокко": "Марокко",
    "Монако": "Монако",
    "Молдова": "Молдова",
    "Черногория": "Черногория",
    "Сен-Мартен (французский)": "Сен-Мартен (французский)",
    "Мадагаскар": "Мадагаскар",
    "Маршалловы острова": "Маршалловы острова",
    "Македония": "Македония",
    "Мали": "Мали",
    "Мьянма": "Мьянма",
    "Монголия": "Монголия",
    "Макао (Аомынь)": "Макао (Аомынь)",
    "Марианские острова": "Марианские острова",
    "Мартиника": "Мартиника",
    "Мавритания": "Мавритания",
    "Монтсеррат": "Монтсеррат",
    "Мальта": "Мальта",
    "Маврикий": "Маврикий",
    "Мальдивы": "Мальдивы",
    "Малави": "Малави",
    "Мексика": "Мексика",
    "Малайзия": "Малайзия",
    "Мозамбик": "Мозамбик",
    "Намибия": "Намибия",
    "Новая Каледония": "Новая Каледония",
    "Нигер": "Нигер",
    "Норфолк": "Норфолк",
    "Нигерия": "Нигерия",
    "Никарагуа": "Никарагуа",
    "Нидерланды": "Нидерланды",
    "Норвегия": "Норвегия",
    "Непал": "Непал",
    "Науру": "Науру",
    "Ниуэ": "Ниуэ",
    "Новая Зеландия": "Новая Зеландия",
    "Оман": "Оман",
    "Панама": "Панама",
    "Перу": "Перу",
    "Французская Полинезия": "Французская Полинезия",
    "Папуа-Новая Гвинея": "Папуа-Новая Гвинея",
    "Филиппины": "Филиппины",
    "Пакистан": "Пакистан",
    "Польша": "Польша",
    "Сен-Пьер и Микелон": "Сен-Пьер и Микелон",
    "Питкэрн": "Питкэрн",
    "Пуэрто-Рико": "Пуэрто-Рико",
    "Палестина": "Палестина",
    "Португалия": "Португалия",
    "Палау": "Палау",
    "Парагвай": "Парагвай",
    "Катар": "Катар",
    "Реюньон": "Реюньон",
    "Румыния": "Румыния",
    "Сербия": "Сербия",
    "Руанда": "Руанда",
    "Саудовская Аравия": "Саудовская Аравия",
    "Соломоновы острова": "Соломоновы острова",
    "Сейшельские острова": "Сейшельские острова",
    "Судан": "Судан",
    "Швеция": "Швеция",
    "Сингапур": "Сингапур",
    "Остров Святой Елены": "Остров Святой Елены",
    "Словения": "Словения",
    "Шпицберген и Ян-Майен": "Шпицберген и Ян-Майен",
    "Словакия": "Словакия",
    "Сьерра-Леоне": "Сьерра-Леоне",
    "Сан-Марино": "Сан-Марино",
    "Сенегал": "Сенегал",
    "Сомали": "Сомали",
    "Суринам": "Суринам",
    "Южный Судан": "Южный Судан",
    "Сан-Томе и Принсипи": "Сан-Томе и Принсипи",
    "Сальвадор": "Сальвадор",
    "Сен-Мартен (голландский)": "Сен-Мартен (голландский)",
    "Сирия": "Сирия",
    "Свазиленд": "Свазиленд",
    "Теркс и Кайкос": "Теркс и Кайкос",
    "Чад": "Чад",
    "Французские Южные Территории": "Французские Южные Территории",
    "Того": "Того",
    "Таиланд": "Таиланд",
    "Таджикистан": "Таджикистан",
    "Токелау": "Токелау",
    "Восточный Тимор": "Восточный Тимор",
    "Туркменистан": "Туркменистан",
    "Тунис": "Тунис",
    "Тонга": "Тонга",
    "Турция": "Турция",
    "Тринидад и Тобаго": "Тринидад и Тобаго",
    "Тувалу": "Тувалу",
    "Тайвань": "Тайвань",
    "Танзания": "Танзания",
    "Украина": "Украина",
    "Уганда": "Уганда",
    "Внешние малые острова США": "Внешние малые острова США",
    "США": "США",
    "Уругвай": "Уругвай",
    "Узбекистан": "Узбекистан",
    "Ватикан": "Ватикан",
    "С.винсент.гренад.": "С.винсент.гренад.",
    "Венесуэла": "Венесуэла",
    "Виргинские острова (Великобритания)": "Виргинские острова (Великобритания)",
    "Виргинские острова (США)": "Виргинские острова (США)",
    "Вьетнам": "Вьетнам",
    "Вануату": "Вануату",
    "Уоллис и Футуна": "Уоллис и Футуна",
    "Западное Самоа": "Западное Самоа",
    "Йемен": "Йемен",
    "Майотта": "Майотта",
    "Южно-Африканская Республика (ЮАР)": "Южно-Африканская Республика (ЮАР)",
    "Замбия": "Замбия",
    "Зимбабве": "Зимбабве",
    "Выберите язык": "Выберите язык",
    "Contract_Contractor": "Исполнитель",
    "Contract_Customer": "Заказчик",
    "Founder": "Учредитель",
    "Director": "Директор",
    "Link": "Перевод ДС",
    "Transactions": "Перевод ДС",
    "Relatives": "Родственники",
    "Phonecalls": "Телефонные звонки",
    "Имя (Name)":"Имя",
    "Наименование (Name)": "Наименование",
    "Регистрационный номер (Registration number)":"Регистрационный номер",
    "Комментарий (Comment)":"Комментарий",
    "Дата рождения (Date of birth)":"Дата рождения",
    "ИНН (TIN)":"ИНН",
    "Место рождения (Place of birth)":"Место рождения",
    "Место проживания (Place of residence)":"Место проживания"
}