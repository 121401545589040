import axios from 'axios';
// import {authHeaderForFiles} from './auth-header';
import config from "../../config";
import Utils from "./utils";
import authHeader from "./auth-header";
// import ontology from "../store/onto";

const API_URL = config.API + '/ontology/';

class OntologyService {

    riseFromFolder(data){
        return axios.post(API_URL + 'riseFromFolder', data, { headers: authHeader() })
    }

    deleteFolder (data){
        return axios.post(API_URL + 'deleteFolder', data, { headers: authHeader() })
    }

    createFolder(username, ontologyname) {
        return axios.post(API_URL + 'createFolder', {
            username: username,
            ontologyname: ontologyname,
            config: { headers: authHeader() }
        })
    }

    sendPreparedFiles(files, ontologyname, apiPath) {
        //формдата это http multipart/form-data объект {ключ-значение}
        const filesFormData = new FormData()

        files.forEach(function (file_with_status) {
            //проставление статусов в имени файла, чтобы быстро подхватить загрузчиком neo4j-admin
            let status = "nodes_";
            if (file_with_status.type === "relation") {
                status = "relation_";
            }
            //создание объекта с параметрами, который отправится с каждым файлом
            const params = {
                'label': file_with_status.label,
                'neoLabel': file_with_status.neoLabel,
                'color': file_with_status.color,
                'ontologyName': ontologyname,
                'user': file_with_status.user,
                'icon': {'unicode': Utils.fontAwesomeToUnicode(file_with_status.icon), 'name': file_with_status.icon}
            }
            //добавление в формдату по очереди параметры+файл для каждого файла
            filesFormData.append(status + file_with_status.data.name, JSON.stringify(params))
            filesFormData.append('file', file_with_status.data, status + file_with_status.data.name)
        })

        return axios({
            method: 'post',
            url: API_URL + apiPath,
            data: filesFormData,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${filesFormData._boundary}`,
            }
            ,
            config :{ headers: authHeader() }
        })
    }

    loadOntology(files, username, ontologyname) {
        let obj = {
            ontologyName: ontologyname,
            user: username,
            params: {}
        }
        files.forEach(function (file_with_status) {
            let status = "nodes_";
            if (file_with_status.type === "relation") {
                status = "relation_";
            }
            const params = {
                'label': file_with_status.label,
                'neoLabel': file_with_status.neoLabel,
                'color': file_with_status.color,
                'ontologyName': ontologyname,
                'user': file_with_status.user,
                'icon': {'unicode': Utils.fontAwesomeToUnicode(file_with_status.icon), 'name': file_with_status.icon}
            }
            const fileName = status + file_with_status.data.name;

            let a = {};
            a[fileName] = params
            // obj['params'].push(a)
            obj['params'][fileName] = params
        })


        return axios({
            data: obj,
            method: 'post',
            url: API_URL + 'loadOntology',
        })
    }

    getOntology() {
        return axios({
            method: 'get',
            url: API_URL + 'getOntology',
        })
    }

    getFolderParams() {
        return axios({
            method: 'get',
            url: API_URL + 'getFolderParams',
        })
    }

}

export default new OntologyService();

