<template>
    <div>
        <grid :data="gridData" :columns="gridColumns"></grid>
    </div>
</template>

<script>

    import Grid from './GridNew'
    import {parse} from 'papaparse';

    export default {
        from: "previewFile",
        props: {
            file: File,
        },
        components: {
            Grid
        },
        data() {
            return {
                gridData: [],
                gridColumns: []
            }
        },

        beforeMount() {
            this.readFile()
        },
        methods: {
            readFile() {
                /* return first object in FileList */
                parse(this.file, {
                    header: true,
                    complete: this.onComplete
                });
            },
            onComplete(results) {
                this.gridData = results.data
                this.gridColumns = results.meta['fields']
            }
        }
    }

</script>

<style scoped>

</style>