const basic = {
    values: {
        'String': {
            element: 'value',
            label: 'Строка'
        },
        'Number': {
            element: 'value',
            label: 'Число'
        },
        'Boolean': {
            element: 'value',
            label: 'Логический'
        },
        'Date': {
            element: 'value',
            label: 'Дата'
        }
    },
    logics: {
        'NOT': {
            element: 'logic',
            label: 'Строка'
        },
        'AND': {
            element: 'logic',
            label: 'Число'
        },
        'OR': {
            element: 'logic',
            label: 'Логический'
        },
        'XOR': {
            element: 'logic',
            label: 'Дата'
        }
    },
    functions: {
        '+': {
            element: 'function',
            label: 'Сложение',
            constructorLabel: '+',
            joints: []
        },
        '-': {
            element: 'function',
            label: 'Вычитание',
            constructorLabel: 'A - B',
            joints: ['A', 'B']
        },
        '*': {
            element: 'function',
            label: 'Умножение',
            constructorLabel: '*',
            joints: []
        },
        '/': {
            element: 'function',
            label: 'Деление',
            constructorLabel: 'A / B',
            joints: ['A', 'B']
        }
    },
    operators: {
        '=': {
            element: 'operator',
            label: 'Равно'
        },
        '>': {
            element: 'operator',
            label: 'Больше'
        },
        '<': {
            element: 'operator',
            label: 'Меньше'
        },
        '>=': {
            element: 'operator',
            label: 'Больше или равно'
        },
        '<=': {
            element: 'operator',
            label: 'Меньше или равно'
        },
        '=~ IN': {
            element: 'operator',
            label: 'Вхождение строки'
        },
        'Array': {
            element: 'operator',
            label: 'Массив'
        }
    },
    links: {
        'toValue': {
            label: 'К значению',
        },
        'toOperator': {
            label: 'К оператору',
        },
        'toFunction': {
            label: 'К функции',
        },
        'toAttribute': {
            label: 'К атрибуту',
        },
        'toLogic': {
            label: 'К логической функции',
        }
    },
    ruleList: {
        'attrString:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrString:out:=~ IN:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrString:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:>:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:<:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:>=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:<=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'attrBoolean:out:=:in': {type: 'toOperator', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrDate:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrDate:out:>:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrDate:out:<:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrDate:out:>=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrDate:out:<=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrDate:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},


        'attrNumber:out:+:in': {type: 'toFunction', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:-:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:-:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:*:in': {type: 'toFunction', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:/:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrNumber:out:/:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},

        '+:out:+:in': {type: 'toFunction', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '-:out:+:in': {type: 'toFunction', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '*:out:+:in': {type: 'toFunction', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '/:out:+:in': {type: 'toFunction', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '+:out:-:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:-:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:-:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:-:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:-:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:-:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:-:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:-:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:*:in': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:*:in': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:*:in': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:*:in': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:/:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:/:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:/:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:/:inA': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:/:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:/:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:/:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:/:inB': {type: 'toFunction', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},

        '+:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:>:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:<:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:>=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:<=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '+:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '-:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:>:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:<:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:>=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:<=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '-:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '*:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:>:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:<:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:>=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:<=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '*:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '/:out:=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:>:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:<:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:>=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:<=:in': {type: 'toOperator', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '/:out:Array:in': {type: 'toOperator', maxST: -1, maxTS: -1, maxSameST: 1, maxSameTS: 1},


        'attrBoolean:out:NOT:in': {type: 'toLogic', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrBoolean:out:AND:in': {type: 'toLogic', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrBoolean:out:OR:in': {type: 'toLogic', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'attrBoolean:out:XOR:in': {type: 'toLogic', maxST: -1, maxTS: 1, maxSameST: 1, maxSameTS: 1},

        'NOT:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'NOT:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'AND:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'NOT:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'OR:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'NOT:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'XOR:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        'AND:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'AND:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'OR:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'AND:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'XOR:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'OR:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'OR:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'XOR:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        'XOR:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},

        '=:out:String:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '=~ IN:out:String:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '=:out:Number:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>:out:Number:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<:out:Number:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>=:out:Number:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<=:out:Number:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '=:out:Date:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>:out:Date:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<:out:Date:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>=:out:Date:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<=:out:Date:in': {type: 'toValue', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},

        '=:out:attrString:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '=~ IN:out:attrString:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '=:out:attrNumber:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>:out:attrNumber:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<:out:attrNumber:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>=:out:attrNumber:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<=:out:attrNumber:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '=:out:attrDate:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>:out:attrDate:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<:out:attrDate:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>=:out:attrDate:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<=:out:attrDate:in': {type: 'toAttribute', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},

        '=:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>=:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '<=:out:NOT:in': {type: 'toLogic', maxST: 1, maxTS: 1, maxSameST: 1, maxSameTS: 1},
        '>:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '<:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '>=:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '<=:out:AND:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '=:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '>:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '<:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '>=:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '<=:out:OR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '=:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '>:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '<:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '>=:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1},
        '<=:out:XOR:in': {type: 'toLogic', maxST: 1, maxTS: -1, maxSameST: 1, maxSameTS: 1}
    },
}

export default basic;