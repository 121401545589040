export const cashStore = {
    namespaced: true,
    state: {
        cash: null,
    },
    getters: {
        getCash: (state) => {
            return state.cash;
        }
    },
    mutations: {
        setCash: (state, cash) => {
            state.cash = cash;
        },
        setCart: (state, cart) => {
            state.cash.cart = cart;
        }
    }
};
