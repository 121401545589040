export const en = {
    "Email изменен": "E-mail changed",
    "Активность": "Activity",
    "Активных сессий нет": "No active sessions",
    "Архив": "Archive",
    "Атрибут": "Attribute",
    "базу загрузил": "database loaded",
    "Банк": "Bank",
    "Без направления": "No direction",
    "Больше": "More",
    "Больше или равно": "More or equal",
    "В избранное": "To favorites",
    "В избранном": "In favorites",
    "Введите": "Enter",
    "Введите имя": "Enter username",
    "Введите имя схемы": "Enter schema name",
    "Введите корректный": "Enter existing",
    "Введите корректный email": "Enter existing e-mail",
    "Введите новый пароль": "Enter a new password",
    "Введите пароль": "Enter password",
    "Введите текст кейса": "Enter case text",
    "Величина ребра": "Edge value",
    "Вернуть тип": "Return type",
    "Версия": "Version",
    "визуализатор схем": "schema visualizer",
    "Визуализация онтологии": "Ontology visualization",
    "Визуализировать": "Visualize",
    "Войти": "Login",
    "Вперед": "Forward",
    "Время": "Time",
    "Все": "All",
    "Все атрибуты": "All attributes",
    "Все связи": "All connections",
    "Все узлы": "All nodes",
    "Всего запросов": "Total Requests",
    "Вход": "Login",
    "Входящие": "Incoming",
    "Вхождение строки": "String entry",
    "Вы уверены, что хотите удалить схему": "Are you sure you want to delete the schema?",
    "Выберете источник": "Select a source",
    "Выберете связь": "Select a connection",
    "Выберите атрибуты": "Select attributes",
    "Выберите аттрибут": "Select an attribute",
    "Выберите значение": "Select",
    "Выберите папку": "Select folder",
    "Выберите роль": "Select role",
    "Выберите свойство": "Select property",
    "Выберите связь": "Select a connection",
    "Выберите страну": "Select country",
    "Выберите файлы": "Select files",
    "Выберите язык": "Select language",
    "Выбор иконки": "Icon selection",
    "Выбрать": "Select",
    "Выгружено": "Uploaded",
    "Выполнено": "Success",
    "Выход": "Logout",
    "Вычитание": "Subtraction",
    "Главная": "Main",
    "Граф": "Graph",
    "Граф должен быть связанным": "Graph must be connected",
    "Граф не связан": "Graph not connected",
    "Граф по пути": "Graph on the way",
    "Графовый запрос": "Graph request",
    "Графус": "Graphus",
    "Да": "Yes",
    "ДА ВСЕ НОРМ": "YES OLL KORREKT",
    "Данное действие удалит весь прогресс конструирования кейса. Продолжить?": "This action will delete all case construction progress. Proceed?",
    "Данные успешно обновлены": "Data updated successfully",
    "Данные успешно отредактированы": "Data edited successfully",
    "Дата": "Date",
    "Дата изменения": "Modified date",
    "Дата создания": "Creation date",
    "Деление": "Division",
    "длительность в минутах, число": "Duration in minutes, number",
    "Добавить": "Add",
    "Добавить атрибут": "Add attribute",
    "Добавить из избранного": "Add to favorites",
    "Добавить связь": "Add connection",
    "Добавить узел": "Add node",
    "Добавление в избранное": "Adding to favorites",
    "Добавление связи": "Adding a connection",
    "Добавление схемы": "Adding a schema",
    "Добавление узла": "Adding a Node",
    "Добавьте хотя бы 1 цель": "Add at least 1 goal",
    "Добро пожаловать в Графус": "Wellcome to Graphus",
    "Дополнительный запрос": "Additional request",
    "Другое": "Other",
    "Завершить кейс и отправить ответ": "Close case and send answer",
    "Загрузка": "Loading",
    "Задайте уровень распространенности": "Set the prevalence level",
    "Задайте уровень сложности": "Set the difficulty level",
    "Закрыть": "Close",
    "Запрос": "Request",
    "Запрос по": "Request by",
    "Зарегистрироваться": "Register",
    "игрок": "player",
    "из": "from",
    "Из избранного": "From favorites",
    "Избранное": "Favorites",
    "изменен": "changed",
    "Изменение пароля": "Change Password",
    "Изменить": "Edit",
    "Изменить направление": "Change direction",
    "Имя": "Username",
    "Имя изменено": "Username changed",
    "Имя новой онтологии": "New ontology name",
    "Имя папки": "Folder name",
    "Имя пользователя": "User name",
    "Имя файла": "File name",
    "Инструкция": "Instruction",
    "Инструмент": "Tool",
    "Инструмент ‘Граф‘": "Tool Graph‘",
    "Инструмент Граф по пути‘": "Tool Path graph‘",
    "Инструмент ‘Карта‘": "Tool Map‘",
    "Инструмент ‘Список‘": "Tool ‘List‘",
    "Информация": "Information",
    "Информация о пользователе": "User information",
    "Исходящие": "Outgoing",
    "Ищет по базе": "Searching in the database",
    "К атрибуту": "To attribute",
    "К значению": "To value",
    "К логической функции": "To logic function",
    "К оператору": "To the operator",
    "К функции": "To function",
    "Как второй": "Like a second",
    "Как первый": "Like the first",
    "Карта": "Map",
    "Кейс": "Case",
    "Кейсы": "Cases",
    "Кол-во действий": "Number of actions",
    "Кол-во минут": "Number of minutes",
    "Кол-во секунд": "Number of seconds",
    "Конструктор": "Constructor",
    "конструктор схем": "schema constructor",
    "Логический": "Logical",
    "Любой атрибут": "Any attribute",
    "Любой тип": "Any type",
    "Максимально 50 символов": "Maximum 50 characters",
    "Массив": "Array",
    "Меньше": "Less",
    "Меньше или равно": "Less or equal",
    "мин": "min",
    "Минимально 3 символа": "Minimum 3 characters.",
    "Минимально 6 символов": "Minimum 6 characters",
    "минут": "minutes",
    "Мои кейсы": "My cases",
    "МУМЦФМ": "ITMCFM",
    "Назад": "Back",
    "Назад к кейсам": "Back to cases",
    "Название": "Title",
    "Название организации": "Organization",
    "Название сессии": "Session name",
    "Название схемы": "Schema name",
    "Название успешно изменено": "Name changed successfully",
    "Название элемента": "Element name",
    "Название элемента в базе": "Element name in DB",
    "Направление": "Direction",
    "Настройка системы": "System setting",
    "Настройки": "Settings",
    "Настройки пользователей": "Users setting",
    "Настройки системы": "System setting",
    "Находится в конструкторе": "Located in the constructor",
    "Не верно составлен запрос": "Invalid request",
    "не выбрана": "not selected",
    "Не удалось зарегистрироваться. Имя пользователя уже используется": "Could not register new user. Username is already in use",
    "не удалось подключиться": "Could not connect",
    "Нет": "No",
    "Нет атрибутов": "No attributes",
    "Нет данных": "No data",
    "Нет результатов": "No results",
    "Ничего не найдено": "Nothing found",
    "Новая схема": "New scheme",
    "Новый кейс": "New case",
    "Новый пользователь зарегистрирован": "New user registered",
    "Область": "Area",
    "Обновить": "Refresh",
    "Обрезать": "Crop",
    "Обучающая система по проведению финансовых расследований для сотрудников финансовых разведок": "Financial Investigation Training System for Financial Intelligence Officers",
    "Обход графа": "Traversal of the graph",
    "Общий": "Common",
    "Объём определён": "Volume defined",
    "Ололош": "Ololosh",
    "Онтология": "Ontology",
    "Описание": "Description",
    "Остановите игру перед тем, как редактировать кейс": "Stop the game before edit the the case",
    "Остановите игру перед тем, как удалять кейс": "Stop the game before deleting the case",
    "Остановите игру перед тем, как отправлять кейс в архив": "Stop the game before sending the case to the archive",
    "Ответ": "Answer",
    "ответ": "answer",
    "Ответ по умолчанию": "Default response",
    "Отдалить": "Zoom out",
    "Отмена": "Cancel",
    "Отображаемое название": "Display name",
    "Отправил ответ": "Sent an answer",
    "Отправить": "Send",
    "Ошибка": "Warning",
    "Ошибка во время изменения данных": "Error while changing data",
    "Ошибка во время создания папки": "Error while creating folder",
    "Ошибка во время создания файла": "Error during file creation",
    "Ошибка во время удаления": "Error during deletion",
    "Ошибка заполнения": "Fill error",
    "Ошибка при создании папки": "Error creating folder",
    "Ошибка при создании файла": "Error creating file",
    "Ошибка удаления": "Deletion error",
    "Папка была удалена": "Folder has been deleted",
    "Параметры": "Parameters",
    "параметры папки": "folder options",
    "Параметры тени": "Shadow options",
    "Пароли не совпадают": "Passwords do not match",
    "Пароли не совпадают!": "Passwords do not match!",
    "Пароль": "Password",
    "Пароль изменен": "Password changed",
    "Пишет ответ": "Writes an answer",
    "По ID": "By ID",
    "Повернуть": "Rotate",
    "Повторите новый пароль": "Re-enter a new password",
    "Подграф": "Subgraph",
    "Подграфы": "Subgraphs",
    "Подграфы не найдены": "Subgraphs not found",
    "Подробнее": "More",
    "Подтвердить": "Confirm",
    "Пожалуйста, назовите онтологию": "Please name the ontology",
    "Поиск": "Search",
    "Поиск в таблице": "Table search",
    "Поиск по базе": "Search in Database",
    "Поиск пользователя": "User search",
    "поисковая строка": "search string",
    "Показать": "Show",
    "Показать все надписи": "Show all labels",
    "Показать выделенное": "Show selected",
    "Показать сессию": "Show session",
    "Поле не может быть пустым": "The field cannot be empty",
    "Получение координат": "Getting coordinates",
    "Пользователи": "Users",
    "Построение связи": "Building a connection",
    "Почта": "E-mail",
    "Предупреждение": "Warning",
    "Приблизить": "Zoom in",
    "Применить": "Apply",
    "Прогресс успешно удален": "Progress deleted successfully",
    "Продолжительность": "Duration",
    "Продолжительность (мин)": "Duration (min)",
    "Произошла ошибка во время удаления": "An error occurred during deletion",
    "Просматривает задание": "Viewing a task",
    "Просмотр связей": "View connections",
    "Пустой": "Empty",
    "Равно": "Equals",
    "Раскладка": "Layout",
    "Раскраска": "Coloring",
    "Раскрытие узла": "Node expansion",
    "Раскрыть": "Expand",
    "Расследование": "Investigation",
    "Редактировать": "Edit",
    "Результаты": "Results",
    "Роли": "Roles",
    "Россия": "Russia",
    "Сброс": "Reset",
    "Сбросить": "Discard",
    "Связей": "Connections",
    "связей": "connections",
    "Связи": "Connections",
    "Связи через одного": "Connections through one",
    "Связь": "Connection",
    "Связь с": "Connection with",
    "Сделать пустым": "Make empty",
    "сек": "sec",
    "Сложение": "Addition",
    "Создано элементов": "Elements created",
    "Создать": "Create",
    "Создать из папки": "Create from folder",
    "Создать из текущей бд": "Create from current DB",
    "Соседи": "Neighbours",
    "Соседние связи": "Neighborhood connections",
    "Составление графа": "Compilation of the graph",
    "Сохранение схемы": "Saving a schema",
    "Сохранено схем": "Schemes saved",
    "Сохранить": "Save",
    "Сохранить схему": "Save scheme",
    "сохранить файлы в папку": "save files to folder",
    "Список": "List",
    "Список схем": "List of schemes",
    "Ссылка на чат (Telegram или другой мессенджер на усмотрение": "Chat link (Telegram or another messenger of your choice",
    "Ссылка на чат (Telegram или другой мессенджер на усмотрение)": "Chat link (Telegram or another messenger of your choice)",
    "статей": "articles",
    "Степень": "Rank",
    "Страна": "Country",
    "Страна изменена": "Country changed",
    "Страниц": "Pages",
    "строк": "lines",
    "Строка": "Line",
    "Схема": "Scheme",
    "Схема пуста": "Schema is empty",
    "Схема сохранена": "Scheme Saved",
    "Схема удалена": "Scheme deleted",
    "Сюда прилетают ноды, нужно компонент с выбором знача/имени как в файлах с": "Nodes fly here, you need a component with a choice of value name as in files with",
    "Таких связей несколько": "There are several such connections",
    "Текст": "Text",
    "Текущие кейсы": "Current cases",
    "Текущие расследования": "Current investigations",
    "Текущие сессии": "Current sessions",
    "Тип": "Type",
    "Удаление из избранного": "Remove from favorites",
    "Удаление схемы": "Deleting a schema",
    "Удаление элемента": "Deleting an Element",
    "Удаление элемента графа": "Deleting a graph element",
    "Удалить": "Delete",
    "Удалить папку": "Delete folder",
    "Удалить узел": "Delete node",
    "Узел": "Node",
    "Узел удален": "Node deleted",
    "узлов и": "nodes and",
    "Узлы": "Nodes",
    "Умножение": "Multiplication",
    "Уникальный элемент уже добавлен": "Unique element already added",
    "Управление": "Management",
    "Управление пользователями": "User management",
    "Успешно": "Successfully",
    "Участники": "Members",
    "Файл успешно создан": "File created successfully",
    "ФИАН": "ITMCFM",
    "Фильтр": "Filter",
    "Форма для свободного ответа": "Free form for answer",
    "форма запроса": "request form",
    "Цвет": "Color",
    "Цели": "Goals",
    "ч": "h",
    "чат": "chat",
    "Человек": "Person",
    "Через одного": "Through one",
    "Число": "Number",
    "Шаблон для формирования текста запроса": "Template for generating the request text",
    "Шаблон для формирования текста ответа": "Template for generating response text",
    "Шаг 1. Вы находитесь в системе обучения финансовым расследованиям Графус": "Step 1. You are in the Grafus Financial Investigation Training System",
    "Шаг 2. Зайдите в свой профиль и выберите вкладку": "Step 2. Go to your profile and select the tab",
    "Шаг 2. Зайдите в свой профиль и выберите вкладку ‘Мои кейсы‘": "Step 2. Go to your profile and select the tab ‘My Cases‘",
    "Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм": "Step 3. Read the task and start the case. You can also go to the chat attached by the telegram administrator",
    "Шаг 3. Прочитайте задание и начните кейс. Так же можно перейти в прикрепленный администратором телеграмм чат": "Step 3. Read the task and start the case. You can also go to the chat attached by the telegram administrator",
    "Шаг 4. После старта кейса будет создана аналитическая сессия": "Step 4. After the start of the case",
    "Шаг 4. После старта кейса будет создана аналитическая сессия Расследование": "Step 4. After the start of the case",
    "Шаг 5. Во вкладке расследование вам представлено 3 аналитических инстурмента": "Step 5. In the investigation tab",
    "Шаг 6. Используя аналитические инструменты добавьте в избранное сущности, подходящие под ответ кейса": "Step 6. Using analytical tools, add to favorites entities that match the case response",
    "Шаг 7. Сформируйте ответ и завершите кейс. Ваш результат будет отображен на табло администратора": "Step 7. Form an answer and complete the case. Your score will be displayed on the admin scoreboard",
    "Элементов сейчас": "Elements now",
    "По": "By",
    "Обучающая система по проведению финансовых расследований": "Financial Investigation Training System",
    "Страниц {pages} из {total}": "Page {pages} of {total}",
    "Язык": "Language",
    "Андорра": "Andorra",
    "Объединенные Арабские Эмираты": "United Arab Emirates",
    "Афганистан": "Afghanistan",
    "Антигуа и Барбуда": "Antigua and Barbuda",
    "Ангилья": "Anguilla",
    "Албания": "Albania",
    "Армения": "Armenia",
    "Антильские острова": "Netherlands Antilles",
    "Ангола": "Angola",
    "Антарктика": "Antarctica",
    "Аргентина": "Argentina",
    "Восточное Самоа (США)": "American Samoa",
    "Австрия": "Austria",
    "Австралия": "Australia",
    "Аруба": "Aruba",
    "Аландские острова": "Aland Islands",
    "Азербайджан": "Azerbaijan",
    "Босния и Герцеговина": "Bosnia and Herzegovina",
    "Барбадос": "Barbados",
    "Бангладеш": "Bangladesh",
    "Бельгия": "Belgium",
    "Буркина-Фасо": "Burkina Faso",
    "Болгария": "Bulgaria",
    "Бахрейн": "Bahrain",
    "Бурунди": "Burundi",
    "Бенин": "Benin",
    "Сен-Бартелеми (Карибы)": "Saint Barthelemy",
    "Бермудские острова": "Bermuda",
    "Бруней": "Brunei Darussalam",
    "Боливия": "Bolivia, Plurinational State of",
    "Бонэйр, Синт-Эстатиус и Саба": "Bonaire, Sint Eustatius and Saba",
    "Бразилия": "Brazil",
    "Багамские острова": "Bahamas",
    "Бутан": "Bhutan",
    "Буве": "Bouvet Island",
    "Ботсвана": "Botswana",
    "Беларусь": "Belarus",
    "Белиз": "Belize",
    "Канада": "Canada",
    "Кокосовые острова": "Cocos (Keeling) Islands",
    "Демократическая Республика Конго": "Congo, the Democratic Republic of the",
    "Центрально-Африканская Республика (ЦАР)": "Central African Republic",
    "Конго": "Congo",
    "Швейцария": "Switzerland",
    "Кот-д'Ивуар": "Côte d'Ivoire",
    "Острова Кука": "Cook Islands",
    "Чили": "Chile",
    "Камерун": "Cameroon",
    "Китай": "China",
    "Колумбия": "Colombia",
    "Коста-Рика": "Costa Rica",
    "Куба": "Cuba",
    "Кабо-Верде": "Cabo Verde",
    "Кюрасао": "Cura?ao",
    "Остров Рождества": "Christmas Island",
    "Кипр": "Cyprus",
    "Чешская Республика": "Czech Republic",
    "Германия": "Germany",
    "Джибути": "Djibouti",
    "Дания": "Denmark",
    "Доминика": "Dominica",
    "Доминиканская Республика": "Dominican Republic",
    "Алжир": "Algeria",
    "Эквадор": "Ecuador",
    "Эстония": "Estonia",
    "Египет": "Egypt",
    "Западная Сахара": "Western Sahara",
    "Эритрея": "Eritrea",
    "Испания": "Spain",
    "Эфиопия": "Ethiopia",
    "Финляндия": "Finland",
    "Фиджи": "Fiji",
    "Фолклендские острова": "Falkland Islands (Malvinas)",
    "Микронезия": "Micronesia, Federated States of",
    "Фарерские острова": "Faroe Islands",
    "Франция": "France",
    "Габон": "Gabon",
    "Великобритания": "United Kingdom",
    "Гренада": "Grenada",
    "Грузия": "Georgia",
    "Гвиана": "French Guiana",
    "Гернси": "Guernsey",
    "Гана": "Ghana",
    "Гибралтар": "Gibraltar",
    "Гренландия": "Greenland",
    "Гамбия": "Gambia",
    "Гвинея": "Guinea",
    "Гваделупа": "Guadeloupe",
    "Экваториальная Гвинея": "Equatorial Guinea",
    "Греция": "Greece",
    "Южная Георгия и Южные Сандвичевы острова": "South Georgia and the South Sandwich Islands",
    "Гватемала": "Guatemala",
    "Гуам": "Guam",
    "Гвинея-Бисау": "Guinea-Bissau",
    "Гайана": "Guyana",
    "Гонконг": "Hong Kong",
    "Остров Херд и острова Макдональд": "Heard Island and McDonald Islands",
    "Гондурас": "Honduras",
    "Хорватия": "Croatia",
    "Гаити": "Haiti",
    "Венгрия": "Hungary",
    "Индонезия": "Indonesia",
    "Ирландия": "Ireland",
    "Израиль": "Israel",
    "Остров Мэн": "Isle of Man",
    "Индия": "India",
    "Британская Территория в Индийском Океане": "British Indian Ocean Territory",
    "Ирак": "Iraq",
    "Иран": "Iran, Islamic Republic of",
    "Исландия": "Iceland",
    "Италия": "Italy",
    "Джерси": "Jersey",
    "Ямайка": "Jamaica",
    "Иордания": "Jordan",
    "Япония": "Japan",
    "Кения": "Kenya",
    "Кыргызстан": "Kyrgyzstan",
    "Камбоджа": "Cambodia",
    "Кирибати": "Kiribati",
    "Коморские острова": "Comoros",
    "Сент-Китс и Невис": "Saint Kitts and Nevis",
    "Северная Корея (КНДР)": "Korea, Democratic People's Republic of",
    "Южная Корея": "Korea, Republic of",
    "Кувейт": "Kuwait",
    "Каймановы острова": "Cayman Islands",
    "Казахстан": "Kazakhstan",
    "Лаос": "Lao People's Democratic Republic",
    "Ливан": "Lebanon",
    "Сент-Люсия": "Saint Lucia",
    "Лихтенштейн": "Liechtenstein",
    "Шри-Ланка": "Sri Lanka",
    "Либерия": "Liberia",
    "Лесото": "Lesotho",
    "Литва": "Lithuania",
    "Люксембург": "Luxembourg",
    "Латвия": "Latvia",
    "Ливия": "Libya",
    "Марокко": "Morocco",
    "Монако": "Monaco",
    "Молдова": "Moldova, Republic of",
    "Черногория": "Montenegro",
    "Сен-Мартен (французский)": "Saint Martin (French part)",
    "Мадагаскар": "Madagascar",
    "Маршалловы острова": "Marshall Islands",
    "Македония": "Macedonia, the former Yugoslav Republic of",
    "Мали": "Mali",
    "Мьянма": "Myanmar",
    "Монголия": "Mongolia",
    "Макао (Аомынь)": "Macao",
    "Марианские острова": "Northern Mariana Islands",
    "Мартиника": "Martinique",
    "Мавритания": "Mauritania",
    "Монтсеррат": "Montserrat",
    "Мальта": "Malta",
    "Маврикий": "Mauritius",
    "Мальдивы": "Maldives",
    "Малави": "Malawi",
    "Мексика": "Mexico",
    "Малайзия": "Malaysia",
    "Мозамбик": "Mozambique",
    "Намибия": "Namibia",
    "Новая Каледония": "New Caledonia",
    "Нигер": "Niger",
    "Норфолк": "Norfolk Island",
    "Нигерия": "Nigeria",
    "Никарагуа": "Nicaragua",
    "Нидерланды": "Netherlands",
    "Норвегия": "Norway",
    "Непал": "Nepal",
    "Науру": "Nauru",
    "Ниуэ": "Niue",
    "Новая Зеландия": "New Zealand",
    "Оман": "Oman",
    "Панама": "Panama",
    "Перу": "Peru",
    "Французская Полинезия": "French Polynesia",
    "Папуа-Новая Гвинея": "Papua New Guinea",
    "Филиппины": "Philippines",
    "Пакистан": "Pakistan",
    "Польша": "Poland",
    "Сен-Пьер и Микелон": "Saint Pierre and Miquelon",
    "Питкэрн": "Pitcairn",
    "Пуэрто-Рико": "Puerto Rico",
    "Палестина": "Palestine, State of",
    "Португалия": "Portugal",
    "Палау": "Palau",
    "Парагвай": "Paraguay",
    "Катар": "Qatar",
    "Реюньон": "R?union",
    "Румыния": "Romania",
    "Сербия": "Serbia",
    "Руанда": "Rwanda",
    "Саудовская Аравия": "Saudi Arabia",
    "Соломоновы острова": "Solomon Islands",
    "Сейшельские острова": "Seychelles",
    "Судан": "Sudan",
    "Швеция": "Sweden",
    "Сингапур": "Singapore",
    "Остров Святой Елены": "Saint Helena, Ascension and Tristan da Cunha",
    "Словения": "Slovenia",
    "Шпицберген и Ян-Майен": "Svalbard and Jan Mayen",
    "Словакия": "Slovakia",
    "Сьерра-Леоне": "Sierra Leone",
    "Сан-Марино": "San Marino",
    "Сенегал": "Senegal",
    "Сомали": "Somalia",
    "Суринам": "Suriname",
    "Южный Судан": "South Sudan",
    "Сан-Томе и Принсипи": "Sao Tome and Principe",
    "Сальвадор": "El Salvador",
    "Сен-Мартен (голландский)": "Sint Maarten (Dutch part)",
    "Сирия": "Syrian Arab Republic",
    "Свазиленд": "Swaziland",
    "Теркс и Кайкос": "Turks and Caicos Islands",
    "Чад": "Chad",
    "Французские Южные Территории": "French Southern Territories",
    "Того": "Togo",
    "Таиланд": "Thailand",
    "Таджикистан": "Tajikistan",
    "Токелау": "Tokelau",
    "Восточный Тимор": "Timor-Leste",
    "Туркменистан": "Turkmenistan",
    "Тунис": "Tunisia",
    "Тонга": "Tonga",
    "Турция": "Turkey",
    "Тринидад и Тобаго": "Trinidad and Tobago",
    "Тувалу": "Tuvalu",
    "Тайвань": "Taiwan, Province of China",
    "Танзания": "Tanzania, United Republic of",
    "Украина": "Ukraine",
    "Уганда": "Uganda",
    "Внешние малые острова США": "United States Minor Outlying Islands",
    "США": "United States",
    "Уругвай": "Uruguay",
    "Узбекистан": "Uzbekistan",
    "Ватикан": "Holy See (Vatican City State)",
    "С.винсент.гренад.": "Saint Vincent and the Grenadines",
    "Венесуэла": "Venezuela, Bolivarian Republic of",
    "Виргинские острова (Великобритания)": "Virgin Islands, British",
    "Виргинские острова (США)": "Virgin Islands, U.S.",
    "Вьетнам": "Viet Nam",
    "Вануату": "Vanuatu",
    "Уоллис и Футуна": "Wallis and Futuna",
    "Западное Самоа": "Samoa",
    "Йемен": "Yemen",
    "Майотта": "Mayotte",
    "Южно-Африканская Республика (ЮАР)": "South Africa",
    "Замбия": "Zambia",
    "Зимбабве": "Zimbabwe",
    "Contract_Contractor": "Contractor",
    "Contract_Customer": "Customer",
    "Имя (Name)":"Name",
    "Наименование (Name)": "Name",
    "Регистрационный номер (Registration number)":"Registration number",
    "Комментарий (Comment)":"Comment",
    "Дата рождения (Date of birth)":"Date of birth",
    "ИНН (TIN)":"TIN",
    "Место рождения (Place of birth)":"Place of birth",
    "Место проживания (Place of residence)":"Place of residence"
}