import { createApp } from 'vue'
import App from './App.vue'
import {store} from './store'
import router from './router'
import ElementUI from 'element-plus'
import VueEditor from "vue3-editor";
import 'flag-icon-css/css/flag-icon.css'

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/fontawesome-free-solid'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { FontAwesomeIcon } from '@fortawesome/fontawesome-free/js/fontawesome'
import { FontAwesomeIconSolid } from '@fortawesome/fontawesome-free/js/solid'
import 'bootstrap/dist/css/bootstrap.css'
import 'element-plus/lib/theme-chalk/index.css';
import './assets/theme.scss'
import  'quill/dist/quill.core.css'
import 'cropperjs/dist/cropper.css';
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import leaflet from 'leaflet'
import leafletC from 'leaflet-curve'
import VectorGrid from 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.js'
// import VeeValidate from 'vee-validate';
// import cytoscape from 'cytoscape'

//import io from "socket.io-client"
import {socket} from "@/services/socket.service";
import {i18n, ElementUILocale} from './i18n/i18n'

const app = createApp(App)
app.config.globalProperties.$L = leaflet

//интересный импорт сокетов
app.config.globalProperties.$io = socket

app.use(FontAwesomeIcon)
app.use(FontAwesomeIconSolid)
app.use(VectorGrid)
app.use(VueEditor);
app.use(leafletC);
// library.add(faUserSecret)
// app.component('font-awesome-icon', FontAwesomeIcon)

app.use(ElementUI, { locale: ElementUILocale })
app.use(store)
// app.use(VeeValidate)
app.use(i18n)
app.use(router).mount('#fff')
