import OntologyService from "../services/ontology.service";
import UserService from '../services/user.service'
import {store} from "../store";
import Ontology from "../models/ontology";


export default class OntologyController {
    constructor() {
        // this.type = type
    }
    async getFolderParams(){
        return OntologyService.getFolderParams()

    }

    async sendFiles(files, username, ontologyname) {
        //перед отправкой надо создать папку, проблема бекенда и формдаты
        await OntologyService.createFolder(username, ontologyname)

        return OntologyService.sendPreparedFiles(files, ontologyname, 'saveFiles')
    }

    async sendFilesAndGetOntology(files, username, ontologyname) {
        await UserService.deleteAllFilesFromUserNameFolder(username)

        return OntologyService.sendPreparedFiles(files, ontologyname, 'drawOntology')
            .then(response => {
                const ontology = new Ontology(response.data.nodes, response.data.edges, response.data.id)
                store.commit('ontologyStore/setOntologyStore', ontology)
            })


    }


    getOntology() {
        return store.getters['ontologyStore/getOntologyStore']
    }

    loadOntology(files, username, ontologyname) {
        return OntologyService.loadOntology(files, username, ontologyname)
    }

    putOntologyToStore() {
        return OntologyService.getOntology().then(response => {
            // console.log('онтология датка из респонса', response.data)
            if (typeof response.data[0] !== "undefined") {
                const ontology = new Ontology(response.data[0].nodes, response.data[0].edges,
                    response.data[0]._id,
                    response.data[0].name,
                    response.data[0].username,
                )
                // console.log('ontology from Service, data=', ontology)
                store.commit('ontologyStore/setOntologyStore', ontology)
            }
        })
    }




}