<template>
    <div class="wrapper">
        <el-input
                v-model="searchQuery"
                size="mini"
                style="width: 30%"
                :placeholder="$t('Поиск')"/>
        <el-table
                :data="dataPerPage"
                style="width: 100%"
                @sort-change="sortChange"
                stripe>
            <el-table-column v-for="key in columns"
                v-bind:key="key"
                sortable
                :label="key"
                :prop="key">
            </el-table-column>
        </el-table>
        <el-pagination
                style="margin-top: 8px"
                :page-sizes="pageSizeMenu"
                :page-size="rowsPerPage"
                :currentPage="startRow / rowsPerPage + 1"
                background
                layout="sizes, prev, pager, next"
                @current-change="pageChange"
                @size-change="handleSizeChange"
                popper-class="pagesize"
                :total="totalRows">
        </el-pagination>
    </div>
</template>

<script>

    export default {
        name: "Grid",
        props: {
            data: Array,
            columns: Array
        },
        data: function () {
            let sortOrders = {};
            for (let i = 0; i < this.columns.length; i++) {
                sortOrders[this.columns[i]] = 1;
            }
            return {
                searchQuery: '',
                sort: {column: null, prop: null, order: null},
                startRow: 0,
                rowsPerPage: 10,
                pageSizeMenu: [10, 20, 50, 100],
                sortOrders: {'dummy': 1}
            }
        },
        computed: {
            filteredData: function () {
                const filterKey = this.searchQuery && this.searchQuery.toLowerCase()
                let data = this.data
                if (filterKey) {
                    data = data.filter(function (row) {
                        return Object.keys(row).some(function (key) {
                            return (
                                String(row[key])
                                    .toLowerCase()
                                    .indexOf(filterKey) > -1
                            )
                        })
                    })
                }
                if (this.sort.column !== null ) {
                    let order = this.sort.order === 'ascending' ? 1 : -1
                    const vm = this
                    data = data.slice().sort(function (a, b) {
                        a = a[vm.sort.prop]
                        b = b[vm.sort.prop]
                        return (a === b ? 0 : a > b ? 1 : -1) * order
                    })
                }

                return data
            },
            dataPerPage: function () {
                return this.filteredData.filter((item, index) => index >= this.startRow && index < (this.startRow + this.rowsPerPage))
            },
            totalRows: function () {
                return this.filteredData.length
            }

        },
        methods: {
            sortChange(sort) {
                this.sort = sort
            },
            handleSizeChange(size) {
              this.rowsPerPage = size
            },
            pageChange(page){
                let newStartRow = ((page-1) * this.rowsPerPage)
                if (newStartRow >= 0 && newStartRow < this.filteredData.length) {
                    this.startRow = newStartRow;
                }
            },
            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1)
            },
            sortBy(key) {
                this.sortKey = key
                const val = this.sortOrders[key] * (-1) || 1;
                const source = {};
                source[this.sortKey] = val;
                this.sortOrders = Object.assign({}, source)
            },
            movePages: function (amount) {
                let newStartRow = this.startRow + (amount * this.rowsPerPage);
                if (newStartRow >= 0 && newStartRow < this.filteredData.length) {
                    this.startRow = newStartRow;
                }
            }
        }
    }
</script>
<style scoped>
    /*.table-body{*/
    /*    display: inline-block;*/
    /*    overflow: hidden;*/
    /*}*/
    table {
        table-layout: fixed;
        border: 2px solid #2c3e50;
        border-radius: 3px;
        background-color: #fff;
        width: 100%;
        font-size: 14px;
    }
    th {
        background-color: #2c3e50;
        color: rgba(255, 255, 255, 0.95);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    td {
        background-color: #f9f9f9;
    }
    th, td {
        /*border: 2px solid red;*/
        padding: 10px 10px ;
        text-align: left;
        white-space: normal;
        word-wrap: break-word;
    }

    th.active {
        color: #fff;
    }

    th.active .arrow {
        opacity: 1;
    }

    >>> .select-trigger .el-input__inner{
        width: 150px
    }

    >>> .el-pagination__sizes {
        width: 150px
    }


    .arrow {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        margin-left: 5px;
        opacity: 0.66;
    }

    .arrow.asc {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #fff;
    }

    .arrow.dsc {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #fff;
    }

    #grid-template {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-flex-direction: column;
    }

    #search {
        border: 1px solid #2c3e50;
        width: 100%;
        display: flex;
    }

    #search_span {
        /*border-radius: 3px;*/
        padding-top: 4px;
        width: 20%;
    }

    #search_query {
        width: 80%;
    }
</style>