import config from "../../config";

import io from 'socket.io-client'

const SOCKET_URL = config.socket

export const socket = io(SOCKET_URL);

// export const socket = io(SOCKET_URL,
//     {
//         query: {
//             x: '42'
//         }
//     });