const arrowJson = {
    "0": [0.764706, 0.766082, 0.767442, 0.768786, 0.770115, 0.771429, 0.772727,
        0.774011, 0.775281, 0.776536, 0.777778, 0.779006, 0.78022, 0.781421,
        0.782609, 0.783784, 0.784946, 0.786096, 0.787234, 0.78836, 0.789474,
        0.790576, 0.791667, 0.792746, 0.793814, 0.794872, 0.795918, 0.796954,
        0.79798, 0.798995, 0.8, 0.800995, 0.80198, 0.802956, 0.803922,
        0.804878, 0.805825, 0.806763, 0.807692, 0.808612, 0.809524, 0.810427,
        0.811321, 0.812207, 0.813084, 0.813953, 0.814815, 0.815668, 0.816514,
        0.817352, 0.818182, 0.819005, 0.81982, 0.820628, 0.821429, 0.822222,
        0.823009, 0.823789, 0.824561, 0.825328, 0.826087, 0.82684, 0.827586,
        0.828326, 0.82906, 0.829787, 0.830508, 0.831224, 0.831933, 0.832636,
        0.833333, 0.834025, 0.834711, 0.835391, 0.836066, 0.836735, 0.837398,
        0.838057, 0.83871, 0.839357, 0.84, 0.840637, 0.84127, 0.841897,
        0.84252, 0.843137, 0.84375, 0.844358, 0.844961, 0.84556, 0.846154,
        0.846743, 0.847328, 0.847909, 0.848485, 0.849057, 0.849624, 0.850187,
        0.850746, 0.851301, 0.851852, 0.852399, 0.852941, 0.85348, 0.854015,
        0.854545, 0.855072, 0.855596, 0.856115, 0.856631, 0.857143, 0.857651,
        0.858156, 0.858657, 0.859155, 0.859649, 0.86014, 0.860627, 0.861111,
        0.861592, 0.862069, 0.862543, 0.863014, 0.863481, 0.863946, 0.864407,
        0.864865, 0.86532, 0.865772, 0.866221, 0.866667, 0.86711, 0.86755,
        0.867987, 0.868421, 0.868852, 0.869281, 0.869707, 0.87013, 0.87055,
        0.870968, 0.871383, 0.871795, 0.872204, 0.872611, 0.873016, 0.873418,
        0.873817, 0.874214, 0.874608, 0.875, 0.875389, 0.875776, 0.876161,
        0.876543, 0.876923, 0.877301, 0.877676, 0.878049, 0.878419, 0.878788,
        0.879154, 0.879518, 0.87988, 0.88024, 0.880597, 0.880952, 0.881306,
        0.881657, 0.882006, 0.882353, 0.882698, 0.883041, 0.883382, 0.883721,
        0.884058, 0.884393, 0.884726, 0.885057, 0.885387, 0.885714, 0.88604,
        0.886364, 0.886686, 0.887006, 0.887324, 0.88764, 0.887955, 0.888268,
        0.888579, 0.888889, 0.889197, 0.889503, 0.889807, 0.89011, 0.890411,
        0.89071, 0.891008, 0.891304, 0.891599, 0.891892, 0.892183, 0.892473,
        0.892761, 0.893048, 0.893333, 0.893617, 0.893899, 0.89418, 0.894459,
        0.894737, 0.895013, 0.895288, 0.895561, 0.895833, 0.896104, 0.896373,
        0.896641, 0.896907, 0.897172, 0.897436, 0.897698, 0.897959, 0.898219,
        0.898477, 0.898734, 0.89899, 0.899244, 0.899497, 0.899749, 0.9,
        0.900249, 0.900498, 0.900744, 0.90099, 0.901235, 0.901478, 0.90172,
        0.901961, 0.9022, 0.902439, 0.902676, 0.902913, 0.903148, 0.903382,
        0.903614, 0.903846, 0.904077, 0.904306, 0.904535, 0.904762, 0.904988,
        0.905213, 0.905437, 0.90566, 0.905882, 0.906103, 0.906323, 0.906542,
        0.90676, 0.906977, 0.907193, 0.907407, 0.907621, 0.907834, 0.908046,
        0.908257, 0.908467, 0.908676, 0.908884, 0.909091, 0.909297, 0.909502,
        0.909707, 0.90991, 0.910112, 0.910314, 0.910515, 0.910714, 0.910913,
        0.911111, 0.911308, 0.911504, 0.9117, 0.911894, 0.912088, 0.912281,
        0.912473, 0.912664, 0.912854, 0.913043, 0.913232, 0.91342, 0.913607,
        0.913793, 0.913978, 0.914163, 0.914347, 0.91453, 0.914712, 0.914894,
        0.915074, 0.915254, 0.915433, 0.915612, 0.915789, 0.915966, 0.916143,
        0.916318, 0.916493, 0.916667, 0.91684, 0.917012, 0.917184, 0.917355,
        0.917526, 0.917695, 0.917864, 0.918033, 0.9182, 0.918367, 0.918534,
        0.918699, 0.918864, 0.919028, 0.919192, 0.919355, 0.919517, 0.919679,
        0.91984, 0.92, 0.92016, 0.920319, 0.920477, 0.920635, 0.920792,
        0.920949, 0.921105, 0.92126, 0.921415, 0.921569, 0.921722, 0.921875,
        0.922027, 0.922179, 0.92233, 0.922481, 0.922631, 0.92278, 0.922929,
        0.923077, 0.923225, 0.923372, 0.923518, 0.923664, 0.92381, 0.923954,
        0.924099, 0.924242, 0.924386, 0.924528, 0.92467, 0.924812, 0.924953,
        0.925094, 0.925234, 0.925373, 0.925512, 0.925651, 0.925788, 0.925926,
        0.926063, 0.926199, 0.926335, 0.926471, 0.926606, 0.92674, 0.926874,
        0.927007, 0.92714, 0.927273, 0.927405, 0.927536, 0.927667, 0.927798,
        0.927928, 0.928058, 0.928187, 0.928315, 0.928444, 0.928571, 0.928699,
        0.928826, 0.928952, 0.929078, 0.929204, 0.929329, 0.929453, 0.929577,
        0.929701, 0.929825, 0.929947, 0.93007, 0.930192, 0.930314, 0.930435,
        0.930556, 0.930676, 0.930796, 0.930915, 0.931034, 0.931153, 0.931271,
        0.931389, 0.931507, 0.931624, 0.931741, 0.931857, 0.931973, 0.932088,
        0.932203, 0.932318, 0.932432, 0.932546, 0.93266, 0.932773, 0.932886,
        0.932998, 0.93311, 0.933222, 0.933333, 0.933444, 0.933555, 0.933665,
        0.933775, 0.933884, 0.933993, 0.934102, 0.934211, 0.934319, 0.934426,
        0.934534, 0.934641, 0.934747, 0.934853, 0.934959, 0.935065, 0.93517,
        0.935275, 0.93538, 0.935484, 0.935588, 0.935691, 0.935795, 0.935897,
        0.936, 0.936102, 0.936204, 0.936306, 0.936407, 0.936508, 0.936609,
        0.936709, 0.936809, 0.936909, 0.937008, 0.937107, 0.937206, 0.937304,
        0.937402, 0.9375, 0.937598, 0.937695, 0.937792, 0.937888, 0.937984,
        0.93808, 0.938176, 0.938272, 0.938367, 0.938462, 0.938556, 0.93865,
        0.938744, 0.938838, 0.938931, 0.939024, 0.939117, 0.93921, 0.939302,
        0.939394, 0.939486, 0.939577, 0.939668, 0.939759, 0.93985, 0.93994,
        0.94003, 0.94012, 0.940209, 0.940299, 0.940387, 0.940476, 0.940565,
        0.940653, 0.940741, 0.940828, 0.940916, 0.941003, 0.94109, 0.941176,
        0.941263, 0.941349, 0.941435, 0.94152, 0.941606, 0.941691, 0.941776,
        0.94186, 0.941945, 0.942029, 0.942113, 0.942197, 0.94228, 0.942363,
        0.942446, 0.942529, 0.942611, 0.942693, 0.942775, 0.942857],
    "1": [0.769235, 0.770548, 0.771847, 0.77313, 0.7744, 0.775655, 0.776896,
        0.778124, 0.779338, 0.780539, 0.781727, 0.782902, 0.784065, 0.785215,
        0.786353, 0.787479, 0.788593, 0.789696, 0.790787, 0.791867, 0.792936,
        0.793994, 0.795041, 0.796077, 0.797103, 0.798119, 0.799125, 0.800121,
        0.801107, 0.802083, 0.80305, 0.804007, 0.804956, 0.805895, 0.806825,
        0.807746, 0.808658, 0.809562, 0.810458, 0.811345, 0.812224, 0.813094,
        0.813957, 0.814812, 0.815658, 0.816498, 0.817329, 0.818153, 0.81897,
        0.81978, 0.820582, 0.821377, 0.822165, 0.822947, 0.823721, 0.824489,
        0.82525, 0.826005, 0.826753, 0.827494, 0.82823, 0.828959, 0.829682,
        0.830399, 0.83111, 0.831815, 0.832514, 0.833207, 0.833895, 0.834577,
        0.835254, 0.835925, 0.83659, 0.837251, 0.837905, 0.838555, 0.8392,
        0.839839, 0.840474, 0.841103, 0.841727, 0.842347, 0.842962, 0.843572,
        0.844177, 0.844778, 0.845374, 0.845965, 0.846552, 0.847135, 0.847713,
        0.848287, 0.848856, 0.849422, 0.849983, 0.85054, 0.851092, 0.851641,
        0.852186, 0.852727, 0.853264, 0.853797, 0.854326, 0.854851, 0.855373,
        0.85589, 0.856405, 0.856915, 0.857422, 0.857925, 0.858425, 0.858921,
        0.859414, 0.859904, 0.86039, 0.860873, 0.861352, 0.861828, 0.862301,
        0.862771, 0.863237, 0.8637, 0.864161, 0.864618, 0.865072, 0.865523,
        0.865971, 0.866416, 0.866858, 0.867297, 0.867734, 0.868167, 0.868598,
        0.869026, 0.869451, 0.869873, 0.870293, 0.87071, 0.871124, 0.871536,
        0.871945, 0.872351, 0.872755, 0.873156, 0.873555, 0.873952, 0.874346,
        0.874737, 0.875126, 0.875513, 0.875897, 0.876279, 0.876658, 0.877035,
        0.87741, 0.877783, 0.878153, 0.878522, 0.878888, 0.879251, 0.879613,
        0.879972, 0.88033, 0.880685, 0.881038, 0.881389, 0.881738, 0.882085,
        0.88243, 0.882773, 0.883114, 0.883453, 0.88379, 0.884125, 0.884458,
        0.884789, 0.885119, 0.885446, 0.885772, 0.886095, 0.886417, 0.886737,
        0.887056, 0.887372, 0.887687, 0.888, 0.888312, 0.888621, 0.888929,
        0.889235, 0.88954, 0.889843, 0.890144, 0.890443, 0.890741, 0.891038,
        0.891332, 0.891626, 0.891917, 0.892207, 0.892496, 0.892783, 0.893068,
        0.893352, 0.893634, 0.893915, 0.894195, 0.894473, 0.894749, 0.895024,
        0.895298, 0.89557, 0.895841, 0.89611, 0.896378, 0.896645, 0.89691,
        0.897174, 0.897437, 0.897698, 0.897958, 0.898217, 0.898474, 0.89873,
        0.898985, 0.899238, 0.899491, 0.899742, 0.899991, 0.90024, 0.900487,
        0.900733, 0.900978, 0.901221, 0.901464, 0.901705, 0.901945, 0.902184,
        0.902422, 0.902658, 0.902894, 0.903128, 0.903361, 0.903593, 0.903824,
        0.904054, 0.904283, 0.904511, 0.904737, 0.904963, 0.905187, 0.905411,
        0.905633, 0.905854, 0.906075, 0.906294, 0.906512, 0.906729, 0.906946,
        0.907161, 0.907375, 0.907588, 0.907801, 0.908012, 0.908222, 0.908432,
        0.90864, 0.908848, 0.909054, 0.90926, 0.909465, 0.909668, 0.909871,
        0.910073, 0.910274, 0.910474, 0.910674, 0.910872, 0.91107, 0.911266,
        0.911462, 0.911657, 0.911851, 0.912044, 0.912237, 0.912428, 0.912619,
        0.912809, 0.912998, 0.913186, 0.913373, 0.91356, 0.913746, 0.913931,
        0.914115, 0.914299, 0.914481, 0.914663, 0.914844, 0.915025, 0.915204,
        0.915383, 0.915561, 0.915739, 0.915915, 0.916091, 0.916266, 0.916441,
        0.916614, 0.916787, 0.916959, 0.917131, 0.917302, 0.917472, 0.917641,
        0.91781, 0.917978, 0.918146, 0.918312, 0.918478, 0.918644, 0.918808,
        0.918972, 0.919136, 0.919298, 0.91946, 0.919622, 0.919783, 0.919943,
        0.920102, 0.920261, 0.920419, 0.920577, 0.920734, 0.92089, 0.921046,
        0.921201, 0.921356, 0.921509, 0.921663, 0.921815, 0.921968, 0.922119,
        0.92227, 0.92242, 0.92257, 0.922719, 0.922868, 0.923016, 0.923164,
        0.923311, 0.923457, 0.923603, 0.923748, 0.923893, 0.924037, 0.924181,
        0.924324, 0.924466, 0.924608, 0.92475, 0.924891, 0.925031, 0.925171,
        0.92531, 0.925449, 0.925588, 0.925725, 0.925863, 0.926, 0.926136,
        0.926272, 0.926407, 0.926542, 0.926676, 0.92681, 0.926943, 0.927076,
        0.927209, 0.927341, 0.927472, 0.927603, 0.927734, 0.927864, 0.927993,
        0.928122, 0.928251, 0.928379, 0.928507, 0.928634, 0.928761, 0.928887,
        0.929013, 0.929139, 0.929264, 0.929388, 0.929512, 0.929636, 0.929759,
        0.929882, 0.930005, 0.930127, 0.930248, 0.930369, 0.93049, 0.930611,
        0.93073, 0.93085, 0.930969, 0.931088, 0.931206, 0.931324, 0.931441,
        0.931558, 0.931675, 0.931791, 0.931907, 0.932023, 0.932138, 0.932252,
        0.932367, 0.932481, 0.932594, 0.932707, 0.93282, 0.932933, 0.933045,
        0.933156, 0.933268, 0.933378, 0.933489, 0.933599, 0.933709, 0.933818,
        0.933928, 0.934036, 0.934145, 0.934253, 0.93436, 0.934468, 0.934575,
        0.934681, 0.934788, 0.934894, 0.934999, 0.935104, 0.935209, 0.935314,
        0.935418, 0.935522, 0.935626, 0.935729, 0.935832, 0.935934, 0.936037,
        0.936139, 0.93624, 0.936341, 0.936442, 0.936543, 0.936643, 0.936743,
        0.936843, 0.936942, 0.937042, 0.93714, 0.937239, 0.937337, 0.937435,
        0.937532, 0.937629, 0.937726, 0.937823, 0.937919, 0.938015, 0.938111,
        0.938207, 0.938302, 0.938397, 0.938491, 0.938585, 0.938679, 0.938773,
        0.938866, 0.93896, 0.939052, 0.939145, 0.939237, 0.939329, 0.939421,
        0.939512, 0.939604, 0.939695, 0.939785, 0.939876, 0.939966, 0.940055,
        0.940145, 0.940234, 0.940323, 0.940412, 0.940501, 0.940589, 0.940677,
        0.940764, 0.940852, 0.940939, 0.941026, 0.941113, 0.941199, 0.941285,
        0.941371, 0.941457, 0.941542, 0.941627, 0.941712, 0.941797, 0.941881,
        0.941966, 0.94205, 0.942133, 0.942217, 0.9423, 0.942383, 0.942466,
        0.942548, 0.942631, 0.942713, 0.942794, 0.942876, 0.942957],
    "2": [0.781894, 0.783035, 0.784165, 0.785283, 0.786389, 0.787484, 0.788568,
        0.789642, 0.790704, 0.791756, 0.792797, 0.793828, 0.794849, 0.79586,
        0.796861, 0.797852, 0.798834, 0.799807, 0.80077, 0.801724, 0.802669,
        0.803605, 0.804532, 0.805451, 0.806361, 0.807263, 0.808157, 0.809042,
        0.80992, 0.810789, 0.811651, 0.812505, 0.813351, 0.81419, 0.815021,
        0.815845, 0.816662, 0.817471, 0.818274, 0.81907, 0.819859, 0.820641,
        0.821416, 0.822185, 0.822947, 0.823703, 0.824453, 0.825196, 0.825933,
        0.826664, 0.827389, 0.828108, 0.828822, 0.829529, 0.830231, 0.830927,
        0.831617, 0.832302, 0.832981, 0.833655, 0.834324, 0.834987, 0.835645,
        0.836298, 0.836946, 0.837589, 0.838226, 0.838859, 0.839487, 0.840111,
        0.840729, 0.841343, 0.841952, 0.842557, 0.843157, 0.843752, 0.844344,
        0.84493, 0.845513, 0.846091, 0.846665, 0.847234, 0.8478, 0.848361,
        0.848919, 0.849472, 0.850021, 0.850567, 0.851108, 0.851646, 0.85218,
        0.85271, 0.853236, 0.853759, 0.854278, 0.854793, 0.855305, 0.855813,
        0.856318, 0.856819, 0.857317, 0.857812, 0.858303, 0.858791, 0.859275,
        0.859756, 0.860234, 0.860709, 0.861181, 0.861649, 0.862115, 0.862577,
        0.863036, 0.863492, 0.863946, 0.864396, 0.864843, 0.865288, 0.865729,
        0.866168, 0.866604, 0.867037, 0.867468, 0.867895, 0.86832, 0.868743,
        0.869162, 0.869579, 0.869994, 0.870405, 0.870815, 0.871221, 0.871626,
        0.872027, 0.872426, 0.872823, 0.873218, 0.873609, 0.873999, 0.874386,
        0.874771, 0.875154, 0.875534, 0.875912, 0.876287, 0.876661, 0.877032,
        0.877401, 0.877768, 0.878133, 0.878495, 0.878856, 0.879214, 0.879571,
        0.879925, 0.880277, 0.880627, 0.880975, 0.881321, 0.881665, 0.882008,
        0.882348, 0.882686, 0.883023, 0.883357, 0.88369, 0.88402, 0.884349,
        0.884676, 0.885002, 0.885325, 0.885647, 0.885967, 0.886285, 0.886601,
        0.886916, 0.887229, 0.88754, 0.887849, 0.888157, 0.888463, 0.888768,
        0.889071, 0.889372, 0.889672, 0.88997, 0.890266, 0.890561, 0.890854,
        0.891146, 0.891436, 0.891725, 0.892012, 0.892298, 0.892582, 0.892865,
        0.893146, 0.893426, 0.893705, 0.893982, 0.894257, 0.894531, 0.894804,
        0.895075, 0.895345, 0.895614, 0.895881, 0.896147, 0.896412, 0.896675,
        0.896937, 0.897197, 0.897457, 0.897715, 0.897971, 0.898227, 0.898481,
        0.898734, 0.898986, 0.899236, 0.899485, 0.899733, 0.89998, 0.900226,
        0.90047, 0.900713, 0.900955, 0.901196, 0.901436, 0.901675, 0.901912,
        0.902149, 0.902384, 0.902618, 0.902851, 0.903083, 0.903314, 0.903543,
        0.903772, 0.904, 0.904226, 0.904452, 0.904676, 0.904899, 0.905122,
        0.905343, 0.905563, 0.905783, 0.906001, 0.906218, 0.906434, 0.90665,
        0.906864, 0.907077, 0.90729, 0.907501, 0.907712, 0.907921, 0.90813,
        0.908337, 0.908544, 0.90875, 0.908955, 0.909159, 0.909362, 0.909564,
        0.909765, 0.909966, 0.910165, 0.910364, 0.910562, 0.910759, 0.910955,
        0.91115, 0.911345, 0.911538, 0.911731, 0.911923, 0.912114, 0.912304,
        0.912493, 0.912682, 0.91287, 0.913057, 0.913243, 0.913428, 0.913613,
        0.913797, 0.91398, 0.914162, 0.914344, 0.914525, 0.914705, 0.914884,
        0.915063, 0.915241, 0.915418, 0.915594, 0.91577, 0.915944, 0.916119,
        0.916292, 0.916465, 0.916637, 0.916808, 0.916979, 0.917149, 0.917318,
        0.917487, 0.917655, 0.917822, 0.917989, 0.918154, 0.91832, 0.918484,
        0.918648, 0.918811, 0.918974, 0.919136, 0.919297, 0.919458, 0.919618,
        0.919778, 0.919936, 0.920095, 0.920252, 0.920409, 0.920565, 0.920721,
        0.920876, 0.921031, 0.921185, 0.921338, 0.921491, 0.921643, 0.921795,
        0.921946, 0.922096, 0.922246, 0.922395, 0.922544, 0.922692, 0.92284,
        0.922987, 0.923133, 0.923279, 0.923424, 0.923569, 0.923713, 0.923857,
        0.924, 0.924143, 0.924285, 0.924427, 0.924568, 0.924709, 0.924849,
        0.924988, 0.925127, 0.925266, 0.925404, 0.925541, 0.925678, 0.925815,
        0.925951, 0.926086, 0.926221, 0.926356, 0.92649, 0.926623, 0.926757,
        0.926889, 0.927021, 0.927153, 0.927284, 0.927415, 0.927545, 0.927675,
        0.927804, 0.927933, 0.928061, 0.928189, 0.928317, 0.928444, 0.928571,
        0.928697, 0.928823, 0.928948, 0.929073, 0.929197, 0.929321, 0.929445,
        0.929568, 0.929691, 0.929813, 0.929935, 0.930056, 0.930177, 0.930298,
        0.930418, 0.930538, 0.930657, 0.930776, 0.930895, 0.931013, 0.931131,
        0.931248, 0.931365, 0.931481, 0.931598, 0.931713, 0.931829, 0.931944,
        0.932058, 0.932173, 0.932287, 0.9324, 0.932513, 0.932626, 0.932738,
        0.93285, 0.932962, 0.933073, 0.933184, 0.933295, 0.933405, 0.933514,
        0.933624, 0.933733, 0.933842, 0.93395, 0.934058, 0.934166, 0.934273,
        0.93438, 0.934487, 0.934593, 0.934699, 0.934805, 0.93491, 0.935015,
        0.935119, 0.935224, 0.935328, 0.935431, 0.935534, 0.935637, 0.93574,
        0.935842, 0.935944, 0.936046, 0.936147, 0.936248, 0.936349, 0.936449,
        0.936549, 0.936649, 0.936748, 0.936848, 0.936946, 0.937045, 0.937143,
        0.937241, 0.937339, 0.937436, 0.937533, 0.93763, 0.937726, 0.937822,
        0.937918, 0.938013, 0.938109, 0.938204, 0.938298, 0.938393, 0.938487,
        0.93858, 0.938674, 0.938767, 0.93886, 0.938953, 0.939045, 0.939137,
        0.939229, 0.939321, 0.939412, 0.939503, 0.939594, 0.939684, 0.939774,
        0.939864, 0.939954, 0.940043, 0.940132, 0.940221, 0.94031, 0.940398,
        0.940486, 0.940574, 0.940662, 0.940749, 0.940836, 0.940923, 0.94101,
        0.941096, 0.941182, 0.941268, 0.941353, 0.941439, 0.941524, 0.941608,
        0.941693, 0.941777, 0.941861, 0.941945, 0.942029, 0.942112, 0.942195,
        0.942278, 0.942361, 0.942443, 0.942525, 0.942607, 0.942689, 0.942771,
        0.942852, 0.942933, 0.943014, 0.943094, 0.943175, 0.943255],
    "3": [0.800138, 0.801046, 0.801945, 0.802837, 0.803722, 0.804599, 0.805468,
        0.80633, 0.807185, 0.808032, 0.808873, 0.809706, 0.810533, 0.811352,
        0.812165, 0.812972, 0.813771, 0.814564, 0.815351, 0.816132, 0.816906,
        0.817674, 0.818435, 0.819191, 0.819941, 0.820685, 0.821422, 0.822155,
        0.822881, 0.823602, 0.824317, 0.825027, 0.825731, 0.82643, 0.827124,
        0.827812, 0.828495, 0.829173, 0.829846, 0.830513, 0.831176, 0.831834,
        0.832487, 0.833135, 0.833778, 0.834417, 0.835051, 0.83568, 0.836305,
        0.836925, 0.837541, 0.838153, 0.83876, 0.839362, 0.839961, 0.840555,
        0.841145, 0.84173, 0.842312, 0.84289, 0.843463, 0.844033, 0.844598,
        0.84516, 0.845718, 0.846272, 0.846822, 0.847368, 0.847911, 0.84845,
        0.848985, 0.849517, 0.850045, 0.85057, 0.851091, 0.851609, 0.852123,
        0.852634, 0.853142, 0.853646, 0.854147, 0.854644, 0.855139, 0.85563,
        0.856118, 0.856602, 0.857084, 0.857563, 0.858038, 0.858511, 0.85898,
        0.859447, 0.85991, 0.860371, 0.860829, 0.861284, 0.861736, 0.862185,
        0.862631, 0.863075, 0.863516, 0.863954, 0.864389, 0.864822, 0.865252,
        0.86568, 0.866105, 0.866527, 0.866947, 0.867364, 0.867779, 0.868192,
        0.868601, 0.869009, 0.869414, 0.869816, 0.870217, 0.870614, 0.87101,
        0.871403, 0.871794, 0.872183, 0.872569, 0.872953, 0.873335, 0.873715,
        0.874092, 0.874467, 0.874841, 0.875212, 0.875581, 0.875947, 0.876312,
        0.876675, 0.877036, 0.877394, 0.877751, 0.878105, 0.878458, 0.878809,
        0.879158, 0.879504, 0.879849, 0.880192, 0.880533, 0.880873, 0.88121,
        0.881546, 0.881879, 0.882211, 0.882541, 0.88287, 0.883196, 0.883521,
        0.883844, 0.884166, 0.884485, 0.884803, 0.885119, 0.885434, 0.885747,
        0.886058, 0.886368, 0.886676, 0.886982, 0.887287, 0.88759, 0.887892,
        0.888192, 0.888491, 0.888788, 0.889083, 0.889377, 0.889669, 0.88996,
        0.89025, 0.890538, 0.890824, 0.89111, 0.891393, 0.891675, 0.891956,
        0.892236, 0.892514, 0.89279, 0.893066, 0.89334, 0.893612, 0.893883,
        0.894153, 0.894422, 0.894689, 0.894955, 0.895219, 0.895483, 0.895745,
        0.896005, 0.896265, 0.896523, 0.89678, 0.897036, 0.89729, 0.897544,
        0.897796, 0.898047, 0.898297, 0.898545, 0.898792, 0.899039, 0.899284,
        0.899527, 0.89977, 0.900012, 0.900252, 0.900492, 0.90073, 0.900967,
        0.901203, 0.901438, 0.901672, 0.901905, 0.902136, 0.902367, 0.902597,
        0.902825, 0.903053, 0.903279, 0.903504, 0.903729, 0.903952, 0.904175,
        0.904396, 0.904617, 0.904836, 0.905054, 0.905272, 0.905488, 0.905704,
        0.905918, 0.906132, 0.906345, 0.906557, 0.906767, 0.906977, 0.907186,
        0.907394, 0.907601, 0.907808, 0.908013, 0.908218, 0.908421, 0.908624,
        0.908826, 0.909027, 0.909227, 0.909426, 0.909625, 0.909822, 0.910019,
        0.910215, 0.91041, 0.910604, 0.910797, 0.91099, 0.911182, 0.911373,
        0.911563, 0.911752, 0.911941, 0.912129, 0.912316, 0.912502, 0.912688,
        0.912872, 0.913056, 0.913239, 0.913422, 0.913604, 0.913785, 0.913965,
        0.914144, 0.914323, 0.914501, 0.914678, 0.914855, 0.915031, 0.915206,
        0.91538, 0.915554, 0.915727, 0.9159, 0.916071, 0.916242, 0.916413,
        0.916582, 0.916751, 0.91692, 0.917087, 0.917254, 0.917421, 0.917586,
        0.917751, 0.917916, 0.918079, 0.918243, 0.918405, 0.918567, 0.918728,
        0.918889, 0.919049, 0.919208, 0.919367, 0.919525, 0.919682, 0.919839,
        0.919996, 0.920151, 0.920306, 0.920461, 0.920615, 0.920768, 0.920921,
        0.921073, 0.921225, 0.921376, 0.921527, 0.921677, 0.921826, 0.921975,
        0.922123, 0.922271, 0.922418, 0.922565, 0.922711, 0.922856, 0.923001,
        0.923146, 0.92329, 0.923433, 0.923576, 0.923718, 0.92386, 0.924002,
        0.924142, 0.924283, 0.924423, 0.924562, 0.924701, 0.924839, 0.924977,
        0.925114, 0.925251, 0.925387, 0.925523, 0.925658, 0.925793, 0.925928,
        0.926062, 0.926195, 0.926328, 0.926461, 0.926593, 0.926724, 0.926855,
        0.926986, 0.927116, 0.927246, 0.927375, 0.927504, 0.927632, 0.92776,
        0.927888, 0.928015, 0.928142, 0.928268, 0.928393, 0.928519, 0.928644,
        0.928768, 0.928892, 0.929016, 0.929139, 0.929262, 0.929384, 0.929506,
        0.929628, 0.929749, 0.929869, 0.92999, 0.930109, 0.930229, 0.930348,
        0.930467, 0.930585, 0.930703, 0.93082, 0.930938, 0.931054, 0.931171,
        0.931287, 0.931402, 0.931517, 0.931632, 0.931747, 0.931861, 0.931974,
        0.932088, 0.932201, 0.932313, 0.932425, 0.932537, 0.932649, 0.93276,
        0.93287, 0.932981, 0.933091, 0.933201, 0.93331, 0.933419, 0.933527,
        0.933636, 0.933744, 0.933851, 0.933958, 0.934065, 0.934172, 0.934278,
        0.934384, 0.93449, 0.934595, 0.9347, 0.934804, 0.934908, 0.935012,
        0.935116, 0.935219, 0.935322, 0.935425, 0.935527, 0.935629, 0.935731,
        0.935832, 0.935933, 0.936034, 0.936134, 0.936234, 0.936334, 0.936433,
        0.936532, 0.936631, 0.93673, 0.936828, 0.936926, 0.937024, 0.937121,
        0.937218, 0.937315, 0.937411, 0.937507, 0.937603, 0.937699, 0.937794,
        0.937889, 0.937984, 0.938078, 0.938173, 0.938267, 0.93836, 0.938453,
        0.938547, 0.938639, 0.938732, 0.938824, 0.938916, 0.939008, 0.939099,
        0.93919, 0.939281, 0.939372, 0.939462, 0.939552, 0.939642, 0.939731,
        0.939821, 0.93991, 0.939998, 0.940087, 0.940175, 0.940263, 0.940351,
        0.940438, 0.940526, 0.940613, 0.940699, 0.940786, 0.940872, 0.940958,
        0.941044, 0.941129, 0.941214, 0.941299, 0.941384, 0.941469, 0.941553,
        0.941637, 0.941721, 0.941804, 0.941888, 0.941971, 0.942054, 0.942136,
        0.942219, 0.942301, 0.942383, 0.942464, 0.942546, 0.942627, 0.942708,
        0.942789, 0.942869, 0.94295, 0.94303, 0.94311, 0.943189, 0.943269,
        0.943348, 0.943427, 0.943506, 0.943585, 0.943663, 0.943741],
    "4": [0.82069, 0.821362, 0.82203, 0.822693, 0.823352, 0.824008, 0.824659,
        0.825305, 0.825948, 0.826586, 0.827221, 0.827852, 0.828478, 0.829101,
        0.829719, 0.830334, 0.830945, 0.831552, 0.832155, 0.832755, 0.833351,
        0.833943, 0.834531, 0.835116, 0.835697, 0.836275, 0.836849, 0.837419,
        0.837986, 0.83855, 0.83911, 0.839667, 0.84022, 0.84077, 0.841316,
        0.84186, 0.8424, 0.842936, 0.84347, 0.844, 0.844527, 0.845051,
        0.845572, 0.84609, 0.846604, 0.847116, 0.847625, 0.84813, 0.848633,
        0.849132, 0.849629, 0.850123, 0.850614, 0.851102, 0.851587, 0.852069,
        0.852549, 0.853025, 0.853499, 0.853971, 0.854439, 0.854905, 0.855368,
        0.855829, 0.856286, 0.856742, 0.857194, 0.857645, 0.858092, 0.858537,
        0.85898, 0.85942, 0.859857, 0.860292, 0.860725, 0.861155, 0.861583,
        0.862008, 0.862431, 0.862852, 0.86327, 0.863686, 0.8641, 0.864512,
        0.864921, 0.865328, 0.865733, 0.866135, 0.866536, 0.866934, 0.86733,
        0.867724, 0.868115, 0.868505, 0.868893, 0.869278, 0.869662, 0.870043,
        0.870422, 0.870799, 0.871175, 0.871548, 0.871919, 0.872289, 0.872656,
        0.873022, 0.873385, 0.873747, 0.874106, 0.874464, 0.87482, 0.875174,
        0.875527, 0.875877, 0.876226, 0.876573, 0.876918, 0.877261, 0.877603,
        0.877943, 0.878281, 0.878617, 0.878952, 0.879284, 0.879616, 0.879945,
        0.880273, 0.880599, 0.880924, 0.881247, 0.881568, 0.881888, 0.882206,
        0.882523, 0.882838, 0.883151, 0.883463, 0.883773, 0.884082, 0.88439,
        0.884695, 0.885, 0.885302, 0.885604, 0.885904, 0.886202, 0.886499,
        0.886794, 0.887088, 0.887381, 0.887672, 0.887962, 0.888251, 0.888538,
        0.888823, 0.889108, 0.889391, 0.889672, 0.889953, 0.890232, 0.890509,
        0.890786, 0.891061, 0.891334, 0.891607, 0.891878, 0.892148, 0.892417,
        0.892684, 0.89295, 0.893215, 0.893479, 0.893741, 0.894003, 0.894263,
        0.894522, 0.894779, 0.895036, 0.895291, 0.895545, 0.895798, 0.89605,
        0.896301, 0.896551, 0.896799, 0.897046, 0.897293, 0.897538, 0.897782,
        0.898025, 0.898267, 0.898507, 0.898747, 0.898986, 0.899223, 0.89946,
        0.899695, 0.89993, 0.900163, 0.900396, 0.900627, 0.900857, 0.901087,
        0.901315, 0.901543, 0.901769, 0.901994, 0.902219, 0.902442, 0.902665,
        0.902886, 0.903107, 0.903326, 0.903545, 0.903763, 0.90398, 0.904196,
        0.904411, 0.904625, 0.904838, 0.90505, 0.905262, 0.905472, 0.905682,
        0.90589, 0.906098, 0.906305, 0.906511, 0.906717, 0.906921, 0.907125,
        0.907327, 0.907529, 0.90773, 0.907931, 0.90813, 0.908328, 0.908526,
        0.908723, 0.908919, 0.909115, 0.909309, 0.909503, 0.909696, 0.909888,
        0.91008, 0.91027, 0.91046, 0.910649, 0.910838, 0.911025, 0.911212,
        0.911398, 0.911583, 0.911768, 0.911952, 0.912135, 0.912318, 0.912499,
        0.91268, 0.912861, 0.91304, 0.913219, 0.913397, 0.913575, 0.913751,
        0.913928, 0.914103, 0.914278, 0.914452, 0.914625, 0.914798, 0.91497,
        0.915141, 0.915312, 0.915482, 0.915651, 0.91582, 0.915988, 0.916156,
        0.916323, 0.916489, 0.916654, 0.916819, 0.916984, 0.917147, 0.91731,
        0.917473, 0.917635, 0.917796, 0.917957, 0.918117, 0.918276, 0.918435,
        0.918593, 0.918751, 0.918908, 0.919064, 0.91922, 0.919376, 0.919531,
        0.919685, 0.919838, 0.919991, 0.920144, 0.920296, 0.920447, 0.920598,
        0.920749, 0.920898, 0.921048, 0.921196, 0.921344, 0.921492, 0.921639,
        0.921786, 0.921932, 0.922077, 0.922222, 0.922367, 0.92251, 0.922654,
        0.922797, 0.922939, 0.923081, 0.923223, 0.923364, 0.923504, 0.923644,
        0.923783, 0.923922, 0.924061, 0.924199, 0.924336, 0.924473, 0.92461,
        0.924746, 0.924881, 0.925016, 0.925151, 0.925285, 0.925419, 0.925552,
        0.925685, 0.925817, 0.925949, 0.926081, 0.926212, 0.926342, 0.926472,
        0.926602, 0.926731, 0.92686, 0.926988, 0.927116, 0.927244, 0.927371,
        0.927497, 0.927623, 0.927749, 0.927874, 0.927999, 0.928124, 0.928248,
        0.928372, 0.928495, 0.928618, 0.92874, 0.928862, 0.928984, 0.929105,
        0.929226, 0.929346, 0.929466, 0.929586, 0.929705, 0.929824, 0.929943,
        0.930061, 0.930179, 0.930296, 0.930413, 0.930529, 0.930646, 0.930761,
        0.930877, 0.930992, 0.931107, 0.931221, 0.931335, 0.931448, 0.931562,
        0.931674, 0.931787, 0.931899, 0.932011, 0.932122, 0.932233, 0.932344,
        0.932455, 0.932565, 0.932674, 0.932784, 0.932893, 0.933001, 0.93311,
        0.933218, 0.933325, 0.933432, 0.933539, 0.933646, 0.933752, 0.933858,
        0.933964, 0.934069, 0.934174, 0.934279, 0.934383, 0.934487, 0.934591,
        0.934694, 0.934797, 0.9349, 0.935003, 0.935105, 0.935207, 0.935308,
        0.935409, 0.93551, 0.935611, 0.935711, 0.935811, 0.935911, 0.93601,
        0.936109, 0.936208, 0.936306, 0.936405, 0.936502, 0.9366, 0.936697,
        0.936794, 0.936891, 0.936987, 0.937084, 0.93718, 0.937275, 0.93737,
        0.937465, 0.93756, 0.937655, 0.937749, 0.937843, 0.937936, 0.93803,
        0.938123, 0.938216, 0.938308, 0.9384, 0.938492, 0.938584, 0.938676,
        0.938767, 0.938858, 0.938948, 0.939039, 0.939129, 0.939219, 0.939308,
        0.939398, 0.939487, 0.939576, 0.939664, 0.939753, 0.939841, 0.939929,
        0.940016, 0.940104, 0.940191, 0.940278, 0.940364, 0.940451, 0.940537,
        0.940623, 0.940708, 0.940794, 0.940879, 0.940964, 0.941049, 0.941133,
        0.941217, 0.941301, 0.941385, 0.941468, 0.941552, 0.941635, 0.941718,
        0.9418, 0.941883, 0.941965, 0.942047, 0.942128, 0.94221, 0.942291,
        0.942372, 0.942453, 0.942533, 0.942614, 0.942694, 0.942774, 0.942853,
        0.942933, 0.943012, 0.943091, 0.94317, 0.943249, 0.943327, 0.943405,
        0.943483, 0.943561, 0.943639, 0.943716, 0.943793, 0.94387, 0.943947,
        0.944023, 0.9441, 0.944176, 0.944252, 0.944327, 0.944403],
    "5": [0.840781, 0.841258, 0.841733, 0.842206, 0.842677, 0.843146, 0.843613,
        0.844079, 0.844542, 0.845003, 0.845463, 0.84592, 0.846376, 0.846829,
        0.847281, 0.847731, 0.848179, 0.848625, 0.849069, 0.849511, 0.849951,
        0.85039, 0.850826, 0.851261, 0.851694, 0.852124, 0.852553, 0.852981,
        0.853406, 0.853829, 0.854251, 0.854671, 0.855089, 0.855505, 0.855919,
        0.856331, 0.856742, 0.857151, 0.857558, 0.857963, 0.858367, 0.858768,
        0.859168, 0.859567, 0.859963, 0.860358, 0.860751, 0.861142, 0.861531,
        0.861919, 0.862305, 0.86269, 0.863072, 0.863453, 0.863832, 0.86421,
        0.864586, 0.86496, 0.865333, 0.865704, 0.866073, 0.866441, 0.866807,
        0.867171, 0.867534, 0.867895, 0.868255, 0.868613, 0.868969, 0.869324,
        0.869678, 0.870029, 0.87038, 0.870728, 0.871075, 0.871421, 0.871765,
        0.872108, 0.872449, 0.872788, 0.873126, 0.873463, 0.873798, 0.874131,
        0.874464, 0.874794, 0.875124, 0.875451, 0.875778, 0.876103, 0.876426,
        0.876748, 0.877069, 0.877388, 0.877706, 0.878023, 0.878338, 0.878651,
        0.878964, 0.879275, 0.879584, 0.879893, 0.8802, 0.880505, 0.88081,
        0.881113, 0.881414, 0.881715, 0.882014, 0.882312, 0.882608, 0.882904,
        0.883198, 0.88349, 0.883782, 0.884072, 0.884361, 0.884649, 0.884935,
        0.88522, 0.885504, 0.885787, 0.886069, 0.886349, 0.886629, 0.886907,
        0.887183, 0.887459, 0.887734, 0.888007, 0.888279, 0.88855, 0.88882,
        0.889089, 0.889357, 0.889623, 0.889889, 0.890153, 0.890416, 0.890678,
        0.890939, 0.891199, 0.891458, 0.891716, 0.891972, 0.892228, 0.892482,
        0.892736, 0.892988, 0.89324, 0.89349, 0.893739, 0.893988, 0.894235,
        0.894481, 0.894726, 0.89497, 0.895214, 0.895456, 0.895697, 0.895937,
        0.896177, 0.896415, 0.896652, 0.896889, 0.897124, 0.897358, 0.897592,
        0.897824, 0.898056, 0.898287, 0.898516, 0.898745, 0.898973, 0.8992,
        0.899426, 0.899651, 0.899875, 0.900099, 0.900321, 0.900543, 0.900764,
        0.900983, 0.901202, 0.90142, 0.901638, 0.901854, 0.902069, 0.902284,
        0.902498, 0.902711, 0.902923, 0.903134, 0.903345, 0.903554, 0.903763,
        0.903971, 0.904178, 0.904385, 0.90459, 0.904795, 0.904999, 0.905202,
        0.905405, 0.905606, 0.905807, 0.906007, 0.906206, 0.906405, 0.906603,
        0.9068, 0.906996, 0.907192, 0.907386, 0.90758, 0.907774, 0.907966,
        0.908158, 0.908349, 0.908539, 0.908729, 0.908918, 0.909106, 0.909294,
        0.909481, 0.909667, 0.909852, 0.910037, 0.910221, 0.910404, 0.910587,
        0.910769, 0.91095, 0.911131, 0.911311, 0.91149, 0.911668, 0.911846,
        0.912024, 0.9122, 0.912376, 0.912552, 0.912726, 0.9129, 0.913074,
        0.913246, 0.913419, 0.91359, 0.913761, 0.913931, 0.914101, 0.91427,
        0.914438, 0.914606, 0.914773, 0.91494, 0.915106, 0.915271, 0.915436,
        0.915601, 0.915764, 0.915927, 0.91609, 0.916252, 0.916413, 0.916574,
        0.916734, 0.916893, 0.917052, 0.917211, 0.917369, 0.917526, 0.917683,
        0.917839, 0.917995, 0.91815, 0.918305, 0.918459, 0.918612, 0.918765,
        0.918918, 0.919069, 0.919221, 0.919372, 0.919522, 0.919672, 0.919821,
        0.91997, 0.920118, 0.920266, 0.920413, 0.92056, 0.920706, 0.920852,
        0.920997, 0.921142, 0.921286, 0.92143, 0.921573, 0.921716, 0.921858,
        0.922, 0.922141, 0.922282, 0.922423, 0.922562, 0.922702, 0.922841,
        0.922979, 0.923117, 0.923255, 0.923392, 0.923529, 0.923665, 0.923801,
        0.923936, 0.924071, 0.924205, 0.924339, 0.924473, 0.924606, 0.924738,
        0.92487, 0.925002, 0.925134, 0.925264, 0.925395, 0.925525, 0.925655,
        0.925784, 0.925912, 0.926041, 0.926169, 0.926296, 0.926423, 0.92655,
        0.926676, 0.926802, 0.926928, 0.927053, 0.927177, 0.927302, 0.927425,
        0.927549, 0.927672, 0.927795, 0.927917, 0.928039, 0.92816, 0.928281,
        0.928402, 0.928522, 0.928642, 0.928762, 0.928881, 0.929, 0.929118,
        0.929236, 0.929354, 0.929471, 0.929588, 0.929705, 0.929821, 0.929937,
        0.930053, 0.930168, 0.930283, 0.930397, 0.930511, 0.930625, 0.930738,
        0.930851, 0.930964, 0.931076, 0.931188, 0.9313, 0.931411, 0.931522,
        0.931633, 0.931743, 0.931853, 0.931962, 0.932072, 0.932181, 0.932289,
        0.932398, 0.932505, 0.932613, 0.93272, 0.932827, 0.932934, 0.93304,
        0.933146, 0.933252, 0.933357, 0.933462, 0.933567, 0.933672, 0.933776,
        0.93388, 0.933983, 0.934086, 0.934189, 0.934292, 0.934394, 0.934496,
        0.934598, 0.934699, 0.9348, 0.934901, 0.935001, 0.935101, 0.935201,
        0.935301, 0.9354, 0.935499, 0.935598, 0.935696, 0.935794, 0.935892,
        0.93599, 0.936087, 0.936184, 0.936281, 0.936377, 0.936474, 0.936569,
        0.936665, 0.93676, 0.936856, 0.93695, 0.937045, 0.937139, 0.937233,
        0.937327, 0.93742, 0.937513, 0.937606, 0.937699, 0.937791, 0.937884,
        0.937975, 0.938067, 0.938158, 0.938249, 0.93834, 0.938431, 0.938521,
        0.938611, 0.938701, 0.938791, 0.93888, 0.938969, 0.939058, 0.939146,
        0.939235, 0.939323, 0.939411, 0.939498, 0.939586, 0.939673, 0.93976,
        0.939846, 0.939933, 0.940019, 0.940105, 0.94019, 0.940276, 0.940361,
        0.940446, 0.940531, 0.940615, 0.9407, 0.940784, 0.940868, 0.940951,
        0.941035, 0.941118, 0.941201, 0.941283, 0.941366, 0.941448, 0.94153,
        0.941612, 0.941693, 0.941775, 0.941856, 0.941937, 0.942018, 0.942098,
        0.942178, 0.942259, 0.942338, 0.942418, 0.942497, 0.942577, 0.942656,
        0.942735, 0.942813, 0.942892, 0.94297, 0.943048, 0.943126, 0.943203,
        0.94328, 0.943358, 0.943435, 0.943511, 0.943588, 0.943664, 0.94374,
        0.943816, 0.943892, 0.943968, 0.944043, 0.944118, 0.944193, 0.944268,
        0.944343, 0.944417, 0.944491, 0.944565, 0.944639, 0.944713, 0.944786,
        0.94486, 0.944933, 0.945006, 0.945078, 0.945151, 0.945223],
    "6": [0.858851, 0.859185, 0.859518, 0.85985, 0.860182, 0.860512, 0.860843,
        0.861172, 0.861501, 0.861829, 0.862156, 0.862482, 0.862808, 0.863133,
        0.863457, 0.86378, 0.864103, 0.864425, 0.864746, 0.865066, 0.865385,
        0.865704, 0.866022, 0.866339, 0.866655, 0.86697, 0.867285, 0.867598,
        0.867911, 0.868223, 0.868534, 0.868845, 0.869154, 0.869463, 0.86977,
        0.870077, 0.870383, 0.870689, 0.870993, 0.871296, 0.871599, 0.871901,
        0.872201, 0.872501, 0.8728, 0.873099, 0.873396, 0.873692, 0.873988,
        0.874283, 0.874576, 0.874869, 0.875161, 0.875452, 0.875743, 0.876032,
        0.87632, 0.876608, 0.876895, 0.877181, 0.877465, 0.877749, 0.878033,
        0.878315, 0.878596, 0.878877, 0.879156, 0.879435, 0.879713, 0.87999,
        0.880266, 0.880541, 0.880815, 0.881089, 0.881361, 0.881633, 0.881904,
        0.882174, 0.882443, 0.882711, 0.882978, 0.883245, 0.88351, 0.883775,
        0.884039, 0.884301, 0.884564, 0.884825, 0.885085, 0.885345, 0.885603,
        0.885861, 0.886118, 0.886374, 0.886629, 0.886883, 0.887137, 0.88739,
        0.887641, 0.887892, 0.888142, 0.888392, 0.88864, 0.888888, 0.889135,
        0.88938, 0.889626, 0.88987, 0.890113, 0.890356, 0.890598, 0.890839,
        0.891079, 0.891318, 0.891557, 0.891795, 0.892032, 0.892268, 0.892503,
        0.892738, 0.892971, 0.893204, 0.893436, 0.893668, 0.893898, 0.894128,
        0.894357, 0.894585, 0.894813, 0.89504, 0.895265, 0.895491, 0.895715,
        0.895939, 0.896161, 0.896383, 0.896605, 0.896825, 0.897045, 0.897264,
        0.897483, 0.8977, 0.897917, 0.898133, 0.898348, 0.898563, 0.898777,
        0.89899, 0.899202, 0.899414, 0.899625, 0.899835, 0.900045, 0.900254,
        0.900462, 0.900669, 0.900876, 0.901082, 0.901287, 0.901492, 0.901695,
        0.901899, 0.902101, 0.902303, 0.902504, 0.902704, 0.902904, 0.903103,
        0.903302, 0.903499, 0.903696, 0.903893, 0.904089, 0.904284, 0.904478,
        0.904672, 0.904865, 0.905057, 0.905249, 0.90544, 0.905631, 0.90582,
        0.90601, 0.906198, 0.906386, 0.906573, 0.90676, 0.906946, 0.907131,
        0.907316, 0.9075, 0.907684, 0.907867, 0.908049, 0.908231, 0.908412,
        0.908592, 0.908772, 0.908951, 0.90913, 0.909308, 0.909485, 0.909662,
        0.909838, 0.910014, 0.910189, 0.910364, 0.910538, 0.910711, 0.910884,
        0.911056, 0.911228, 0.911399, 0.911569, 0.911739, 0.911909, 0.912077,
        0.912246, 0.912413, 0.91258, 0.912747, 0.912913, 0.913078, 0.913243,
        0.913408, 0.913572, 0.913735, 0.913898, 0.91406, 0.914222, 0.914383,
        0.914544, 0.914704, 0.914863, 0.915022, 0.915181, 0.915339, 0.915497,
        0.915654, 0.91581, 0.915966, 0.916122, 0.916277, 0.916431, 0.916585,
        0.916739, 0.916892, 0.917044, 0.917196, 0.917348, 0.917499, 0.917649,
        0.917799, 0.917949, 0.918098, 0.918247, 0.918395, 0.918542, 0.91869,
        0.918836, 0.918983, 0.919128, 0.919274, 0.919419, 0.919563, 0.919707,
        0.91985, 0.919993, 0.920136, 0.920278, 0.92042, 0.920561, 0.920702,
        0.920842, 0.920982, 0.921122, 0.921261, 0.921399, 0.921537, 0.921675,
        0.921812, 0.921949, 0.922086, 0.922222, 0.922357, 0.922492, 0.922627,
        0.922761, 0.922895, 0.923029, 0.923162, 0.923294, 0.923427, 0.923558,
        0.92369, 0.923821, 0.923951, 0.924082, 0.924211, 0.924341, 0.92447,
        0.924598, 0.924727, 0.924854, 0.924982, 0.925109, 0.925236, 0.925362,
        0.925488, 0.925613, 0.925738, 0.925863, 0.925987, 0.926111, 0.926235,
        0.926358, 0.926481, 0.926604, 0.926726, 0.926847, 0.926969, 0.92709,
        0.92721, 0.927331, 0.927451, 0.92757, 0.927689, 0.927808, 0.927927,
        0.928045, 0.928163, 0.92828, 0.928397, 0.928514, 0.92863, 0.928746,
        0.928862, 0.928978, 0.929093, 0.929207, 0.929322, 0.929436, 0.929549,
        0.929663, 0.929776, 0.929888, 0.930001, 0.930113, 0.930224, 0.930336,
        0.930447, 0.930557, 0.930668, 0.930778, 0.930888, 0.930997, 0.931106,
        0.931215, 0.931323, 0.931431, 0.931539, 0.931647, 0.931754, 0.931861,
        0.931968, 0.932074, 0.93218, 0.932286, 0.932391, 0.932496, 0.932601,
        0.932705, 0.93281, 0.932913, 0.933017, 0.93312, 0.933223, 0.933326,
        0.933428, 0.933531, 0.933632, 0.933734, 0.933835, 0.933936, 0.934037,
        0.934137, 0.934237, 0.934337, 0.934437, 0.934536, 0.934635, 0.934734,
        0.934832, 0.934931, 0.935028, 0.935126, 0.935223, 0.935321, 0.935417,
        0.935514, 0.93561, 0.935706, 0.935802, 0.935897, 0.935993, 0.936088,
        0.936182, 0.936277, 0.936371, 0.936465, 0.936559, 0.936652, 0.936745,
        0.936838, 0.936931, 0.937023, 0.937115, 0.937207, 0.937299, 0.93739,
        0.937481, 0.937572, 0.937663, 0.937753, 0.937843, 0.937933, 0.938023,
        0.938112, 0.938202, 0.93829, 0.938379, 0.938468, 0.938556, 0.938644,
        0.938732, 0.938819, 0.938906, 0.938994, 0.93908, 0.939167, 0.939253,
        0.939339, 0.939425, 0.939511, 0.939597, 0.939682, 0.939767, 0.939852,
        0.939936, 0.94002, 0.940105, 0.940188, 0.940272, 0.940356, 0.940439,
        0.940522, 0.940605, 0.940687, 0.94077, 0.940852, 0.940934, 0.941015,
        0.941097, 0.941178, 0.941259, 0.94134, 0.941421, 0.941501, 0.941582,
        0.941662, 0.941741, 0.941821, 0.941901, 0.94198, 0.942059, 0.942138,
        0.942216, 0.942295, 0.942373, 0.942451, 0.942529, 0.942606, 0.942684,
        0.942761, 0.942838, 0.942915, 0.942991, 0.943068, 0.943144, 0.94322,
        0.943296, 0.943372, 0.943447, 0.943523, 0.943598, 0.943673, 0.943747,
        0.943822, 0.943896, 0.94397, 0.944044, 0.944118, 0.944192, 0.944265,
        0.944339, 0.944412, 0.944485, 0.944557, 0.94463, 0.944702, 0.944774,
        0.944846, 0.944918, 0.94499, 0.945061, 0.945132, 0.945204, 0.945275,
        0.945345, 0.945416, 0.945486, 0.945557, 0.945627, 0.945697, 0.945766,
        0.945836, 0.945905, 0.945974, 0.946043, 0.946112, 0.946181],
    "7": [0.874405, 0.874639, 0.874874, 0.875108, 0.875342, 0.875576, 0.87581,
        0.876044, 0.876278, 0.876512, 0.876745, 0.876978, 0.877212, 0.877444,
        0.877677, 0.87791, 0.878142, 0.878374, 0.878606, 0.878838, 0.879069,
        0.8793, 0.879531, 0.879762, 0.879993, 0.880223, 0.880453, 0.880682,
        0.880912, 0.881141, 0.88137, 0.881598, 0.881826, 0.882054, 0.882282,
        0.882509, 0.882736, 0.882962, 0.883189, 0.883415, 0.88364, 0.883865,
        0.88409, 0.884315, 0.884539, 0.884763, 0.884986, 0.885209, 0.885432,
        0.885654, 0.885876, 0.886097, 0.886318, 0.886539, 0.886759, 0.886979,
        0.887199, 0.887418, 0.887637, 0.887855, 0.888073, 0.88829, 0.888507,
        0.888724, 0.88894, 0.889156, 0.889371, 0.889586, 0.8898, 0.890014,
        0.890228, 0.890441, 0.890653, 0.890865, 0.891077, 0.891288, 0.891499,
        0.89171, 0.89192, 0.892129, 0.892338, 0.892547, 0.892755, 0.892962,
        0.893169, 0.893376, 0.893582, 0.893788, 0.893993, 0.894198, 0.894402,
        0.894606, 0.89481, 0.895013, 0.895215, 0.895417, 0.895618, 0.895819,
        0.89602, 0.89622, 0.89642, 0.896619, 0.896817, 0.897015, 0.897213,
        0.89741, 0.897607, 0.897803, 0.897998, 0.898194, 0.898388, 0.898583,
        0.898776, 0.89897, 0.899162, 0.899355, 0.899546, 0.899738, 0.899929,
        0.900119, 0.900309, 0.900498, 0.900687, 0.900875, 0.901063, 0.901251,
        0.901438, 0.901624, 0.90181, 0.901996, 0.90218, 0.902365, 0.902549,
        0.902733, 0.902916, 0.903098, 0.90328, 0.903462, 0.903643, 0.903824,
        0.904004, 0.904184, 0.904363, 0.904541, 0.90472, 0.904898, 0.905075,
        0.905252, 0.905428, 0.905604, 0.905779, 0.905954, 0.906129, 0.906303,
        0.906476, 0.906649, 0.906822, 0.906994, 0.907165, 0.907337, 0.907507,
        0.907677, 0.907847, 0.908017, 0.908185, 0.908354, 0.908522, 0.908689,
        0.908856, 0.909023, 0.909189, 0.909354, 0.909519, 0.909684, 0.909848,
        0.910012, 0.910175, 0.910338, 0.910501, 0.910663, 0.910824, 0.910985,
        0.911146, 0.911306, 0.911466, 0.911625, 0.911784, 0.911942, 0.9121,
        0.912258, 0.912415, 0.912572, 0.912728, 0.912884, 0.913039, 0.913194,
        0.913348, 0.913502, 0.913656, 0.913809, 0.913962, 0.914114, 0.914266,
        0.914418, 0.914569, 0.914719, 0.91487, 0.915019, 0.915169, 0.915318,
        0.915466, 0.915614, 0.915762, 0.915909, 0.916056, 0.916203, 0.916349,
        0.916495, 0.91664, 0.916785, 0.916929, 0.917073, 0.917217, 0.91736,
        0.917503, 0.917645, 0.917787, 0.917929, 0.91807, 0.918211, 0.918351,
        0.918491, 0.918631, 0.91877, 0.918909, 0.919048, 0.919186, 0.919323,
        0.919461, 0.919598, 0.919734, 0.91987, 0.920006, 0.920141, 0.920277,
        0.920411, 0.920545, 0.920679, 0.920813, 0.920946, 0.921079, 0.921211,
        0.921343, 0.921475, 0.921606, 0.921737, 0.921868, 0.921998, 0.922128,
        0.922257, 0.922386, 0.922515, 0.922644, 0.922772, 0.922899, 0.923027,
        0.923154, 0.92328, 0.923407, 0.923533, 0.923658, 0.923783, 0.923908,
        0.924033, 0.924157, 0.924281, 0.924404, 0.924528, 0.92465, 0.924773,
        0.924895, 0.925017, 0.925138, 0.925259, 0.92538, 0.925501, 0.925621,
        0.925741, 0.92586, 0.925979, 0.926098, 0.926217, 0.926335, 0.926453,
        0.92657, 0.926687, 0.926804, 0.926921, 0.927037, 0.927153, 0.927269,
        0.927384, 0.927499, 0.927614, 0.927728, 0.927842, 0.927956, 0.928069,
        0.928182, 0.928295, 0.928408, 0.92852, 0.928632, 0.928743, 0.928855,
        0.928966, 0.929076, 0.929187, 0.929297, 0.929407, 0.929516, 0.929625,
        0.929734, 0.929843, 0.929951, 0.930059, 0.930167, 0.930274, 0.930382,
        0.930488, 0.930595, 0.930701, 0.930807, 0.930913, 0.931018, 0.931124,
        0.931229, 0.931333, 0.931437, 0.931541, 0.931645, 0.931749, 0.931852,
        0.931955, 0.932058, 0.93216, 0.932262, 0.932364, 0.932466, 0.932567,
        0.932668, 0.932769, 0.932869, 0.932969, 0.933069, 0.933169, 0.933269,
        0.933368, 0.933467, 0.933565, 0.933664, 0.933762, 0.93386, 0.933957,
        0.934055, 0.934152, 0.934249, 0.934345, 0.934442, 0.934538, 0.934634,
        0.934729, 0.934825, 0.93492, 0.935015, 0.935109, 0.935204, 0.935298,
        0.935392, 0.935486, 0.935579, 0.935672, 0.935765, 0.935858, 0.93595,
        0.936042, 0.936134, 0.936226, 0.936318, 0.936409, 0.9365, 0.936591,
        0.936681, 0.936772, 0.936862, 0.936951, 0.937041, 0.937131, 0.93722,
        0.937309, 0.937397, 0.937486, 0.937574, 0.937662, 0.93775, 0.937838,
        0.937925, 0.938012, 0.938099, 0.938186, 0.938272, 0.938359, 0.938445,
        0.938531, 0.938616, 0.938702, 0.938787, 0.938872, 0.938957, 0.939041,
        0.939125, 0.93921, 0.939294, 0.939377, 0.939461, 0.939544, 0.939627,
        0.93971, 0.939793, 0.939875, 0.939957, 0.940039, 0.940121, 0.940203,
        0.940284, 0.940366, 0.940447, 0.940528, 0.940608, 0.940689, 0.940769,
        0.940849, 0.940929, 0.941009, 0.941088, 0.941167, 0.941246, 0.941325,
        0.941404, 0.941482, 0.941561, 0.941639, 0.941717, 0.941794, 0.941872,
        0.941949, 0.942027, 0.942104, 0.94218, 0.942257, 0.942333, 0.94241,
        0.942486, 0.942561, 0.942637, 0.942713, 0.942788, 0.942863, 0.942938,
        0.943013, 0.943087, 0.943162, 0.943236, 0.94331, 0.943384, 0.943458,
        0.943531, 0.943605, 0.943678, 0.943751, 0.943824, 0.943896, 0.943969,
        0.944041, 0.944113, 0.944185, 0.944257, 0.944329, 0.9444, 0.944471,
        0.944542, 0.944613, 0.944684, 0.944755, 0.944825, 0.944896, 0.944966,
        0.945036, 0.945105, 0.945175, 0.945244, 0.945314, 0.945383, 0.945452,
        0.945521, 0.945589, 0.945658, 0.945726, 0.945794, 0.945862, 0.94593,
        0.945998, 0.946066, 0.946133, 0.9462, 0.946267, 0.946334, 0.946401,
        0.946468, 0.946534, 0.9466, 0.946666, 0.946732, 0.946798, 0.946864,
        0.94693, 0.946995, 0.94706, 0.947125, 0.94719, 0.947255],
    "8": [0.887536, 0.887704, 0.887872, 0.88804, 0.888208, 0.888376, 0.888544,
        0.888713, 0.888881, 0.88905, 0.889218, 0.889387, 0.889556, 0.889725,
        0.889894, 0.890063, 0.890232, 0.890401, 0.890571, 0.89074, 0.890909,
        0.891079, 0.891248, 0.891417, 0.891587, 0.891756, 0.891926, 0.892095,
        0.892264, 0.892434, 0.892603, 0.892772, 0.892942, 0.893111, 0.89328,
        0.893449, 0.893619, 0.893788, 0.893957, 0.894126, 0.894294, 0.894463,
        0.894632, 0.894801, 0.894969, 0.895138, 0.895306, 0.895474, 0.895642,
        0.89581, 0.895978, 0.896146, 0.896314, 0.896481, 0.896649, 0.896816,
        0.896983, 0.89715, 0.897317, 0.897484, 0.897651, 0.897817, 0.897983,
        0.89815, 0.898316, 0.898481, 0.898647, 0.898812, 0.898978, 0.899143,
        0.899308, 0.899473, 0.899637, 0.899802, 0.899966, 0.90013, 0.900294,
        0.900457, 0.900621, 0.900784, 0.900947, 0.90111, 0.901272, 0.901435,
        0.901597, 0.901759, 0.90192, 0.902082, 0.902243, 0.902404, 0.902565,
        0.902726, 0.902886, 0.903046, 0.903206, 0.903366, 0.903525, 0.903684,
        0.903843, 0.904002, 0.90416, 0.904318, 0.904476, 0.904634, 0.904792,
        0.904949, 0.905106, 0.905262, 0.905419, 0.905575, 0.905731, 0.905886,
        0.906042, 0.906197, 0.906352, 0.906506, 0.906661, 0.906815, 0.906968,
        0.907122, 0.907275, 0.907428, 0.907581, 0.907733, 0.907885, 0.908037,
        0.908188, 0.90834, 0.908491, 0.908641, 0.908792, 0.908942, 0.909092,
        0.909241, 0.909391, 0.90954, 0.909689, 0.909837, 0.909985, 0.910133,
        0.910281, 0.910428, 0.910575, 0.910722, 0.910868, 0.911014, 0.91116,
        0.911305, 0.911451, 0.911596, 0.91174, 0.911885, 0.912029, 0.912173,
        0.912316, 0.912459, 0.912602, 0.912745, 0.912887, 0.913029, 0.913171,
        0.913312, 0.913453, 0.913594, 0.913735, 0.913875, 0.914015, 0.914155,
        0.914294, 0.914433, 0.914572, 0.91471, 0.914848, 0.914986, 0.915124,
        0.915261, 0.915398, 0.915535, 0.915671, 0.915807, 0.915943, 0.916078,
        0.916214, 0.916348, 0.916483, 0.916617, 0.916751, 0.916885, 0.917018,
        0.917152, 0.917284, 0.917417, 0.917549, 0.917681, 0.917813, 0.917944,
        0.918075, 0.918206, 0.918336, 0.918466, 0.918596, 0.918726, 0.918855,
        0.918984, 0.919113, 0.919241, 0.919369, 0.919497, 0.919625, 0.919752,
        0.919879, 0.920006, 0.920132, 0.920258, 0.920384, 0.920509, 0.920634,
        0.920759, 0.920884, 0.921008, 0.921132, 0.921256, 0.92138, 0.921503,
        0.921626, 0.921748, 0.921871, 0.921993, 0.922115, 0.922236, 0.922357,
        0.922478, 0.922599, 0.922719, 0.922839, 0.922959, 0.923079, 0.923198,
        0.923317, 0.923435, 0.923554, 0.923672, 0.92379, 0.923907, 0.924025,
        0.924142, 0.924258, 0.924375, 0.924491, 0.924607, 0.924723, 0.924838,
        0.924953, 0.925068, 0.925182, 0.925297, 0.925411, 0.925524, 0.925638,
        0.925751, 0.925864, 0.925977, 0.926089, 0.926201, 0.926313, 0.926425,
        0.926536, 0.926647, 0.926758, 0.926868, 0.926979, 0.927089, 0.927199,
        0.927308, 0.927417, 0.927526, 0.927635, 0.927743, 0.927852, 0.92796,
        0.928067, 0.928175, 0.928282, 0.928389, 0.928495, 0.928602, 0.928708,
        0.928814, 0.92892, 0.929025, 0.92913, 0.929235, 0.92934, 0.929444,
        0.929548, 0.929652, 0.929756, 0.929859, 0.929962, 0.930065, 0.930168,
        0.93027, 0.930373, 0.930475, 0.930576, 0.930678, 0.930779, 0.93088,
        0.930981, 0.931081, 0.931181, 0.931281, 0.931381, 0.931481, 0.93158,
        0.931679, 0.931778, 0.931876, 0.931975, 0.932073, 0.932171, 0.932268,
        0.932366, 0.932463, 0.93256, 0.932657, 0.932753, 0.93285, 0.932946,
        0.933041, 0.933137, 0.933232, 0.933327, 0.933422, 0.933517, 0.933612,
        0.933706, 0.9338, 0.933894, 0.933987, 0.93408, 0.934174, 0.934267,
        0.934359, 0.934452, 0.934544, 0.934636, 0.934728, 0.934819, 0.934911,
        0.935002, 0.935093, 0.935184, 0.935274, 0.935364, 0.935455, 0.935544,
        0.935634, 0.935724, 0.935813, 0.935902, 0.935991, 0.936079, 0.936168,
        0.936256, 0.936344, 0.936432, 0.936519, 0.936607, 0.936694, 0.936781,
        0.936868, 0.936954, 0.93704, 0.937127, 0.937213, 0.937298, 0.937384,
        0.937469, 0.937554, 0.937639, 0.937724, 0.937809, 0.937893, 0.937977,
        0.938061, 0.938145, 0.938229, 0.938312, 0.938395, 0.938478, 0.938561,
        0.938644, 0.938726, 0.938808, 0.93889, 0.938972, 0.939054, 0.939135,
        0.939216, 0.939298, 0.939378, 0.939459, 0.93954, 0.93962, 0.9397,
        0.93978, 0.93986, 0.93994, 0.940019, 0.940098, 0.940177, 0.940256,
        0.940335, 0.940413, 0.940492, 0.94057, 0.940648, 0.940726, 0.940803,
        0.940881, 0.940958, 0.941035, 0.941112, 0.941189, 0.941265, 0.941342,
        0.941418, 0.941494, 0.94157, 0.941646, 0.941721, 0.941797, 0.941872,
        0.941947, 0.942022, 0.942096, 0.942171, 0.942245, 0.942319, 0.942393,
        0.942467, 0.942541, 0.942614, 0.942688, 0.942761, 0.942834, 0.942907,
        0.942979, 0.943052, 0.943124, 0.943196, 0.943268, 0.94334, 0.943412,
        0.943483, 0.943555, 0.943626, 0.943697, 0.943768, 0.943839, 0.943909,
        0.94398, 0.94405, 0.94412, 0.94419, 0.94426, 0.944329, 0.944399,
        0.944468, 0.944538, 0.944607, 0.944675, 0.944744, 0.944813, 0.944881,
        0.944949, 0.945018, 0.945085, 0.945153, 0.945221, 0.945288, 0.945356,
        0.945423, 0.94549, 0.945557, 0.945624, 0.94569, 0.945757, 0.945823,
        0.945889, 0.945956, 0.946021, 0.946087, 0.946153, 0.946218, 0.946284,
        0.946349, 0.946414, 0.946479, 0.946544, 0.946608, 0.946673, 0.946737,
        0.946801, 0.946865, 0.946929, 0.946993, 0.947057, 0.94712, 0.947183,
        0.947247, 0.94731, 0.947373, 0.947436, 0.947498, 0.947561, 0.947623,
        0.947686, 0.947748, 0.94781, 0.947872, 0.947933, 0.947995, 0.948056,
        0.948118, 0.948179, 0.94824, 0.948301, 0.948362, 0.948423],
    "9": [0.898569, 0.898691, 0.898814, 0.898936, 0.899059, 0.899182, 0.899306,
        0.899429, 0.899553, 0.899677, 0.899801, 0.899925, 0.90005, 0.900174,
        0.900299, 0.900424, 0.900549, 0.900674, 0.9008, 0.900926, 0.901051,
        0.901177, 0.901303, 0.901429, 0.901556, 0.901682, 0.901808, 0.901935,
        0.902062, 0.902189, 0.902316, 0.902443, 0.90257, 0.902697, 0.902824,
        0.902952, 0.903079, 0.903207, 0.903334, 0.903462, 0.90359, 0.903717,
        0.903845, 0.903973, 0.904101, 0.904229, 0.904357, 0.904485, 0.904613,
        0.904741, 0.904869, 0.904998, 0.905126, 0.905254, 0.905382, 0.905511,
        0.905639, 0.905767, 0.905895, 0.906023, 0.906152, 0.90628, 0.906408,
        0.906536, 0.906665, 0.906793, 0.906921, 0.907049, 0.907177, 0.907305,
        0.907433, 0.907561, 0.907689, 0.907817, 0.907945, 0.908073, 0.9082,
        0.908328, 0.908456, 0.908583, 0.908711, 0.908838, 0.908966, 0.909093,
        0.90922, 0.909348, 0.909475, 0.909602, 0.909729, 0.909856, 0.909983,
        0.910109, 0.910236, 0.910363, 0.910489, 0.910615, 0.910742, 0.910868,
        0.910994, 0.91112, 0.911246, 0.911372, 0.911497, 0.911623, 0.911748,
        0.911874, 0.911999, 0.912124, 0.912249, 0.912374, 0.912499, 0.912623,
        0.912748, 0.912872, 0.912997, 0.913121, 0.913245, 0.913369, 0.913492,
        0.913616, 0.91374, 0.913863, 0.913986, 0.914109, 0.914232, 0.914355,
        0.914478, 0.9146, 0.914723, 0.914845, 0.914967, 0.915089, 0.915211,
        0.915332, 0.915454, 0.915575, 0.915696, 0.915817, 0.915938, 0.916059,
        0.916179, 0.9163, 0.91642, 0.91654, 0.91666, 0.91678, 0.916899,
        0.917019, 0.917138, 0.917257, 0.917376, 0.917495, 0.917613, 0.917732,
        0.91785, 0.917968, 0.918086, 0.918204, 0.918321, 0.918439, 0.918556,
        0.918673, 0.91879, 0.918906, 0.919023, 0.919139, 0.919255, 0.919371,
        0.919487, 0.919603, 0.919718, 0.919833, 0.919948, 0.920063, 0.920178,
        0.920293, 0.920407, 0.920521, 0.920635, 0.920749, 0.920862, 0.920976,
        0.921089, 0.921202, 0.921315, 0.921427, 0.92154, 0.921652, 0.921764,
        0.921876, 0.921988, 0.922099, 0.922211, 0.922322, 0.922433, 0.922544,
        0.922654, 0.922765, 0.922875, 0.922985, 0.923094, 0.923204, 0.923313,
        0.923423, 0.923532, 0.923641, 0.923749, 0.923858, 0.923966, 0.924074,
        0.924182, 0.924289, 0.924397, 0.924504, 0.924611, 0.924718, 0.924825,
        0.924931, 0.925038, 0.925144, 0.92525, 0.925355, 0.925461, 0.925566,
        0.925671, 0.925776, 0.925881, 0.925986, 0.92609, 0.926194, 0.926298,
        0.926402, 0.926505, 0.926609, 0.926712, 0.926815, 0.926918, 0.92702,
        0.927123, 0.927225, 0.927327, 0.927429, 0.92753, 0.927632, 0.927733,
        0.927834, 0.927935, 0.928036, 0.928136, 0.928236, 0.928336, 0.928436,
        0.928536, 0.928635, 0.928735, 0.928834, 0.928933, 0.929031, 0.92913,
        0.929228, 0.929326, 0.929424, 0.929522, 0.929619, 0.929717, 0.929814,
        0.929911, 0.930008, 0.930104, 0.930201, 0.930297, 0.930393, 0.930489,
        0.930584, 0.93068, 0.930775, 0.93087, 0.930965, 0.931059, 0.931154,
        0.931248, 0.931342, 0.931436, 0.93153, 0.931623, 0.931717, 0.93181,
        0.931903, 0.931996, 0.932088, 0.932181, 0.932273, 0.932365, 0.932457,
        0.932548, 0.93264, 0.932731, 0.932822, 0.932913, 0.933004, 0.933094,
        0.933185, 0.933275, 0.933365, 0.933455, 0.933544, 0.933634, 0.933723,
        0.933812, 0.933901, 0.93399, 0.934078, 0.934167, 0.934255, 0.934343,
        0.934431, 0.934518, 0.934606, 0.934693, 0.93478, 0.934867, 0.934954,
        0.93504, 0.935127, 0.935213, 0.935299, 0.935385, 0.93547, 0.935556,
        0.935641, 0.935726, 0.935811, 0.935896, 0.935981, 0.936065, 0.936149,
        0.936233, 0.936317, 0.936401, 0.936485, 0.936568, 0.936651, 0.936734,
        0.936817, 0.9369, 0.936982, 0.937065, 0.937147, 0.937229, 0.937311,
        0.937393, 0.937474, 0.937555, 0.937637, 0.937718, 0.937798, 0.937879,
        0.93796, 0.93804, 0.93812, 0.9382, 0.93828, 0.93836, 0.938439,
        0.938518, 0.938598, 0.938677, 0.938755, 0.938834, 0.938913, 0.938991,
        0.939069, 0.939147, 0.939225, 0.939303, 0.93938, 0.939458, 0.939535,
        0.939612, 0.939689, 0.939766, 0.939842, 0.939919, 0.939995, 0.940071,
        0.940147, 0.940223, 0.940298, 0.940374, 0.940449, 0.940524, 0.940599,
        0.940674, 0.940749, 0.940824, 0.940898, 0.940972, 0.941046, 0.94112,
        0.941194, 0.941268, 0.941341, 0.941414, 0.941488, 0.941561, 0.941633,
        0.941706, 0.941779, 0.941851, 0.941923, 0.941996, 0.942068, 0.942139,
        0.942211, 0.942283, 0.942354, 0.942425, 0.942496, 0.942567, 0.942638,
        0.942709, 0.942779, 0.942849, 0.94292, 0.94299, 0.94306, 0.943129,
        0.943199, 0.943269, 0.943338, 0.943407, 0.943476, 0.943545, 0.943614,
        0.943682, 0.943751, 0.943819, 0.943887, 0.943956, 0.944023, 0.944091,
        0.944159, 0.944226, 0.944294, 0.944361, 0.944428, 0.944495, 0.944562,
        0.944629, 0.944695, 0.944762, 0.944828, 0.944894, 0.94496, 0.945026,
        0.945092, 0.945157, 0.945223, 0.945288, 0.945353, 0.945418, 0.945483,
        0.945548, 0.945613, 0.945677, 0.945742, 0.945806, 0.94587, 0.945934,
        0.945998, 0.946062, 0.946125, 0.946189, 0.946252, 0.946316, 0.946379,
        0.946442, 0.946505, 0.946567, 0.94663, 0.946692, 0.946755, 0.946817,
        0.946879, 0.946941, 0.947003, 0.947065, 0.947126, 0.947188, 0.947249,
        0.94731, 0.947371, 0.947432, 0.947493, 0.947554, 0.947615, 0.947675,
        0.947735, 0.947796, 0.947856, 0.947916, 0.947976, 0.948036, 0.948095,
        0.948155, 0.948214, 0.948273, 0.948333, 0.948392, 0.948451, 0.948509,
        0.948568, 0.948627, 0.948685, 0.948744, 0.948802, 0.94886, 0.948918,
        0.948976, 0.949034, 0.949091, 0.949149, 0.949206, 0.949264, 0.949321,
        0.949378, 0.949435, 0.949492, 0.949548, 0.949605, 0.949662],
    "10": [0.907861, 0.907952, 0.908044, 0.908135, 0.908227, 0.908319,
        0.908411, 0.908504, 0.908596, 0.908689, 0.908782, 0.908875, 0.908969,
        0.909062, 0.909156, 0.90925, 0.909345, 0.909439, 0.909534, 0.909629,
        0.909724, 0.909819, 0.909914, 0.910009, 0.910105, 0.910201, 0.910297,
        0.910393, 0.910489, 0.910586, 0.910682, 0.910779, 0.910876, 0.910973,
        0.91107, 0.911167, 0.911265, 0.911362, 0.91146, 0.911558, 0.911656,
        0.911754, 0.911852, 0.91195, 0.912048, 0.912147, 0.912245, 0.912344,
        0.912443, 0.912541, 0.91264, 0.912739, 0.912838, 0.912938, 0.913037,
        0.913136, 0.913236, 0.913335, 0.913435, 0.913534, 0.913634, 0.913734,
        0.913833, 0.913933, 0.914033, 0.914133, 0.914233, 0.914333, 0.914433,
        0.914534, 0.914634, 0.914734, 0.914834, 0.914935, 0.915035, 0.915135,
        0.915236, 0.915336, 0.915437, 0.915537, 0.915637, 0.915738, 0.915839,
        0.915939, 0.91604, 0.91614, 0.916241, 0.916341, 0.916442, 0.916542,
        0.916643, 0.916744, 0.916844, 0.916945, 0.917045, 0.917146, 0.917246,
        0.917347, 0.917447, 0.917548, 0.917648, 0.917749, 0.917849, 0.91795,
        0.91805, 0.918151, 0.918251, 0.918351, 0.918452, 0.918552, 0.918652,
        0.918752, 0.918852, 0.918952, 0.919052, 0.919153, 0.919253, 0.919352,
        0.919452, 0.919552, 0.919652, 0.919752, 0.919851, 0.919951, 0.920051,
        0.92015, 0.92025, 0.920349, 0.920448, 0.920548, 0.920647, 0.920746,
        0.920845, 0.920944, 0.921043, 0.921142, 0.921241, 0.92134, 0.921439,
        0.921537, 0.921636, 0.921734, 0.921833, 0.921931, 0.922029, 0.922128,
        0.922226, 0.922324, 0.922422, 0.922519, 0.922617, 0.922715, 0.922812,
        0.92291, 0.923007, 0.923105, 0.923202, 0.923299, 0.923396, 0.923493,
        0.92359, 0.923687, 0.923784, 0.92388, 0.923977, 0.924073, 0.924169,
        0.924266, 0.924362, 0.924458, 0.924554, 0.92465, 0.924745, 0.924841,
        0.924936, 0.925032, 0.925127, 0.925222, 0.925317, 0.925412, 0.925507,
        0.925602, 0.925697, 0.925791, 0.925886, 0.92598, 0.926074, 0.926168,
        0.926262, 0.926356, 0.92645, 0.926543, 0.926637, 0.92673, 0.926824,
        0.926917, 0.92701, 0.927103, 0.927196, 0.927288, 0.927381, 0.927473,
        0.927566, 0.927658, 0.92775, 0.927842, 0.927934, 0.928026, 0.928117,
        0.928209, 0.9283, 0.928392, 0.928483, 0.928574, 0.928665, 0.928755,
        0.928846, 0.928937, 0.929027, 0.929117, 0.929207, 0.929297, 0.929387,
        0.929477, 0.929567, 0.929656, 0.929745, 0.929835, 0.929924, 0.930013,
        0.930102, 0.93019, 0.930279, 0.930367, 0.930456, 0.930544, 0.930632,
        0.93072, 0.930808, 0.930896, 0.930983, 0.93107, 0.931158, 0.931245,
        0.931332, 0.931419, 0.931506, 0.931592, 0.931679, 0.931765, 0.931851,
        0.931937, 0.932023, 0.932109, 0.932195, 0.93228, 0.932366, 0.932451,
        0.932536, 0.932621, 0.932706, 0.932791, 0.932875, 0.93296, 0.933044,
        0.933129, 0.933213, 0.933297, 0.93338, 0.933464, 0.933548, 0.933631,
        0.933714, 0.933797, 0.93388, 0.933963, 0.934046, 0.934128, 0.934211,
        0.934293, 0.934375, 0.934457, 0.934539, 0.934621, 0.934703, 0.934784,
        0.934866, 0.934947, 0.935028, 0.935109, 0.93519, 0.93527, 0.935351,
        0.935431, 0.935512, 0.935592, 0.935672, 0.935752, 0.935831, 0.935911,
        0.93599, 0.93607, 0.936149, 0.936228, 0.936307, 0.936386, 0.936464,
        0.936543, 0.936621, 0.9367, 0.936778, 0.936856, 0.936934, 0.937011,
        0.937089, 0.937166, 0.937244, 0.937321, 0.937398, 0.937475, 0.937551,
        0.937628, 0.937705, 0.937781, 0.937857, 0.937933, 0.938009, 0.938085,
        0.938161, 0.938236, 0.938312, 0.938387, 0.938462, 0.938537, 0.938612,
        0.938687, 0.938762, 0.938836, 0.93891, 0.938985, 0.939059, 0.939133,
        0.939207, 0.93928, 0.939354, 0.939427, 0.939501, 0.939574, 0.939647,
        0.93972, 0.939793, 0.939865, 0.939938, 0.94001, 0.940083, 0.940155,
        0.940227, 0.940299, 0.94037, 0.940442, 0.940514, 0.940585, 0.940656,
        0.940727, 0.940798, 0.940869, 0.94094, 0.941011, 0.941081, 0.941151,
        0.941222, 0.941292, 0.941362, 0.941431, 0.941501, 0.941571, 0.94164,
        0.94171, 0.941779, 0.941848, 0.941917, 0.941986, 0.942054, 0.942123,
        0.942191, 0.94226, 0.942328, 0.942396, 0.942464, 0.942532, 0.9426,
        0.942667, 0.942735, 0.942802, 0.942869, 0.942936, 0.943003, 0.94307,
        0.943137, 0.943203, 0.94327, 0.943336, 0.943403, 0.943469, 0.943535,
        0.943601, 0.943666, 0.943732, 0.943797, 0.943863, 0.943928, 0.943993,
        0.944058, 0.944123, 0.944188, 0.944253, 0.944317, 0.944382, 0.944446,
        0.94451, 0.944574, 0.944638, 0.944702, 0.944766, 0.94483, 0.944893,
        0.944956, 0.94502, 0.945083, 0.945146, 0.945209, 0.945272, 0.945334,
        0.945397, 0.945459, 0.945522, 0.945584, 0.945646, 0.945708, 0.94577,
        0.945832, 0.945893, 0.945955, 0.946016, 0.946078, 0.946139, 0.9462,
        0.946261, 0.946322, 0.946383, 0.946443, 0.946504, 0.946564, 0.946625,
        0.946685, 0.946745, 0.946805, 0.946865, 0.946925, 0.946984, 0.947044,
        0.947103, 0.947162, 0.947222, 0.947281, 0.94734, 0.947399, 0.947457,
        0.947516, 0.947575, 0.947633, 0.947691, 0.94775, 0.947808, 0.947866,
        0.947924, 0.947982, 0.948039, 0.948097, 0.948154, 0.948212, 0.948269,
        0.948326, 0.948383, 0.94844, 0.948497, 0.948554, 0.948611, 0.948667,
        0.948724, 0.94878, 0.948836, 0.948892, 0.948948, 0.949004, 0.94906,
        0.949116, 0.949172, 0.949227, 0.949283, 0.949338, 0.949393, 0.949448,
        0.949503, 0.949558, 0.949613, 0.949668, 0.949722, 0.949777, 0.949831,
        0.949886, 0.94994, 0.949994, 0.950048, 0.950102, 0.950156, 0.950209,
        0.950263, 0.950317, 0.95037, 0.950423, 0.950477, 0.95053, 0.950583,
        0.950636, 0.950689, 0.950741, 0.950794, 0.950847, 0.950899, 0.950952]
}

export default arrowJson