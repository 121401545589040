<template>
    <div>
        <particles-bg type="cobweb" :bg="true"/>
        <h2>{{ $t("Обучающая система по проведению финансовых расследований") }}</h2>
        <Login/>
        <div id="footer">
            &copy; {{ $t("МУМЦФМ") }}
        </div>
    </div>
</template>

<script>
    import Login from "./Login";

    export default {
        name: "FirstPage",
        components: {
            Login
        },
        props: {
            component: {
                type: String,
                default: function () {
                    return ''
                }
            }
        },
    }
</script>

<style scoped>

    #footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: rgba(255, 255, 255, 0.95);
    }

    h1 {
        padding: 10px;
        background: rgba(255, 255, 255, 0.95);
    }

    h2 {
        padding: 10px;
        background: rgba(255, 255, 255, 0.95);
    }

    p {
        text-align: left;
        /*display: flex;*/
        /*justify-content: space-between;*/
        padding-left: 25%;
        padding-right: 20%;
    }


</style>
